import React, {Component} from "react";
import {connect} from "react-redux";
import {Link, Redirect} from "react-router-dom";
import {Form, Button, Container, Row, Col } from 'react-bootstrap'
import {auth} from "../actions";
import Validation from './Validation';

import './Login.css';

class Login extends Component {
  state = {
    info: {
      email: '',
      password: ''
    },
    message: {
      email: '',
      password: ''
    },
    loading: false
  };

  submit = (e) => {
    this.setState({ loading: true });
    e.preventDefault();
    this.props.login(this.state.info.email, this.state.info.password);
    this.setState({ loading: false });
  }

  canSubmit = (): boolean => {
    const { info, message, loading } = this.state;
    const validInfo =
      Object.values(info).filter(value => {
        return value === '';
      }).length === 0;
    const validMessage =
      Object.values(message).filter(value => {
        return value !== '';
      }).length === 0;

    return validInfo && validMessage && !loading;
  };


  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const key = event.target.id;
    const value = event.target.value;
    const { info, message } = this.state;

    this.setState({
      info: { ...info, [key]: value }
    });

    // Realtime check
    /*this.setState({
      message: {
        ...message,
        [key]: Validation.formValidate(key, value)
      }
    });*/
  };

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/" />
    }

    const { info, message } = this.state;

    return (
        <Container>
          <Row>
            <Col>
              <div className="page-wrapper">
                <Form id="login-form" >

                  {this.props.errors.length > 0 && (
                    <ul>
                      {this.props.errors.map(error => (
                        <li key={error.field}>{error.message}</li>
                      ))}
                    </ul>
                  )}

                  <Form.Group controlId="email">
                    <Form.Label>メールアドレス</Form.Label>
                    <Form.Control type="email" placeholder="メールアドレス" value={info.email} onChange={event => this.handleChange(event)} />
                    {message.email && (<p style={{ color: 'red', fontSize: 8 }}>{message.email}</p>)}
                  </Form.Group>

                  <Form.Group controlId="password">
                    <Form.Label>パスワード</Form.Label>
                    <Form.Control type="password" placeholder="パスワード" value={info.password} onChange={event => this.handleChange(event)} />
                    {message.password && (<p style={{ color: 'red', fontSize: 8 }}>{message.password}</p>)}
                    <Link to="/register">
                      <Form.Text id="forgot" className="text-muted">
                      パスワードをお忘れの方
                      </Form.Text>
                    </Link>
                  </Form.Group>

                  <Button id="submit" type="submit" disabled={!this.canSubmit()} onClick={event => this.submit(event)} >
                    ログイン
                  </Button>
                  <p>
                    アカウントをお持ちでない方 <Link to="/register">アカウント作成</Link>
                  </p>
                </Form>
              </div>
            </Col>

            <Col>
              <div className="page-wrapper">
                <div id="top-display">
                </div>
              </div>
            </Col>
          </Row>
        </Container>
    )
  }
}


const mapStateToProps = state => {
  let errors = [];
  if (state.auth.errors) {
    errors = Object.keys(state.auth.errors).map(field => {
      return {field, message: state.auth.errors[field]};
    });
  }
  return {
    errors,
    isAuthenticated: state.auth.isAuthenticated
  };
}

const mapDispatchToProps = dispatch => {
  return {
    login: (username, password) => {
      return dispatch(auth.login(username, password));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
