import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import ConfigForm from './Form';

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

export default class ConfigModal extends React.Component {
  static propTypes = {
    open: PropTypes.bool,
    user: PropTypes.object,
    practice: PropTypes.object,
    handleCloseExternalDataModal: PropTypes.func,
  }

  constructor() {
    super();

    this.state = {
      modalIsOpen: false
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({modalIsOpen: true});
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  render() {
    return (
      <Modal
        isOpen={this.props.open}
        onRequestClose={this.closeModal}
        contentLabel="Example Modal"
      >

        <ConfigForm
          //user={this.props.user}
          practice={this.props.practice}
          handleCloseExternalDataModal={this.props.handleCloseExternalDataModal}
        />
      </Modal>
    );
  }
}
