import React, {Component} from "react";
import {connect} from "react-redux";
import {Link, Redirect} from "react-router-dom";
import ReactAsyncTable from 'react-async-table';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { withRouter } from 'react-router';
import 'bootstrap/scss/bootstrap.scss';
import 'font-awesome/css/font-awesome.min.css';
import './PagiList.css';


const ExampleLoader = () => (
  <p>Loading...</p>
);


class PagiList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      search: '',
      page: 1,
      items: [],
      currentPage: 1,
      itemsPerPage: this.props.itemsPerPage,
      totalItems: 0,
    };

    this.onChangePage = this.onChangePage.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onInsert = this.onInsert.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onMultipleDelete = this.onMultipleDelete.bind(this);

  }


  fakeAsyncAction() {
    setTimeout(() => {
      const { search, page, itemsPerPage } = this.state;
      const currentIndex = (page - 1) * itemsPerPage;
      const filteredItems = this.props.data.filter(item => item.searchstr.toLowerCase().indexOf(search.toLowerCase()) !== -1);
      this.setState(() => ({
        isLoading: false,
        items: filteredItems.slice(currentIndex, currentIndex + itemsPerPage),
        totalItems: filteredItems.length,
      }));
    }, 100);
  } 

  onChangePage(page) {
    this.setState({ page, isLoading: true });
    this.fakeAsyncAction();
  }

  onSearch(search) {
    this.setState({ search, page: 1, isLoading: true });
    this.fakeAsyncAction();
  }

  onSort(sortField, sortOrder) {
    console.log('onSort handler');
    console.log('sortField:', sortField);
    console.log('sortOrder:', sortOrder);
  }

  onInsert() {
    console.log('onInsert handler');
    console.log(this.props.insertFormUrl);
    this.props.history.push(this.props.insertFormUrl);
  }

  onEdit(rowID) {
    this.props.onEdit(rowID);
  }

  onDelete(rowID, page) {
    console.log('onDelete handler');
    console.log('id:', rowID);
    console.log('page:', page);
  }

  onHeaderAction(type) {
    console.log('onHeaderAction handler');
    console.log('type:', type);
  }

  onAction(type, row) {
    console.log('onAction handler');
    console.log('type:', type);
    console.log('row:', row);
  }

  onMultipleDelete(values, page) {
    console.log('onMultipleDelete handler');
    console.log('ids:', values);
    console.log('page:', page);
  }

  onColumnClick(columnKey, row) {
    console.log('onColumnClick handler');
    console.log('column:', columnKey);
    console.log('row data:', row);
  }


  componentDidMount() {
    this.fakeAsyncAction();
  }


  render() {
    const { isLoading, items, page, search, itemsPerPage, totalItems  } = this.state;

    const customizeField1 = this.props.customizeField1;
    const customizeField2 = this.props.customizeField2;
    const customizeField3 = this.props.customizeField3;

    return (
      <div className="container">
        <Card>
          <CardHeader>
              <b> {this.props.title} </b>
          </CardHeader>
          <CardBody>
            <ReactAsyncTable
              keyField="id"
              isLoading={isLoading}
              query={search}
              requestFailed={false}
              columns={this.props.columuns }
              items={items}
              tableClass="table-outline"
              tableHeaderClass="htead-light"
              currentPage={page}
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              delay={300}

              loader={ExampleLoader}
              headerActions={this.props.ExampleHeaderActions}
              actionsComponent={this.props.actionsComponent}
              expandableRowComponent={this.props.ExpandableRowComponent}
 
              options={{
                searchBox: true,
                insertButton: this.props.insertBtn,
                //multipleSelect: true,
                //expandable: true,
                actionsColumn: true,
                pagination: true
              }}
              translations={{
                searchPlaceholder: 'Search...',
                addButton: 'Add',
                deleteButton: 'Delete',
                sortTitle: 'Sort',
                actionsColumnTitle: 'Actions',
                editAction: 'Edit',
                deleteAction: 'Delete',
                noDataText: 'No data found',
                requestFailedText: 'API request failed',
                paginationFirst: 'First',
                paginationLast: 'Last'
              }}
              icons={{
                addButtonIcon: 'fa fa-plus',
                deleteButtonIcon: 'fa fa-trash',
                tooltipIcon: 'fa fa-question',
                sortIcon: 'fa fa-sort',
                expandIcon: 'fa fa-expand',
                editActionIcon: 'fa fa-pencil',
                deleteActionIcon: 'fa fa-trash'
              }}
              onChangePage={this.onChangePage}
              onSearch={this.onSearch}
              onSort={this.onSort}
              onColumnClick={this.onColumnClick}
              onInsert={this.onInsert}
              onEdit={this.onEdit}
              //onDelete={this.onDelete}
              onDelete={this.props.onDelete}
              onHeaderAction={this.onHeaderAction}
              onAction={this.props.onAction}
              onMultipleDelete={this.onMultipleDelete}
              //deleteFunc={this.props.deleteFunc}
            />
          </CardBody>
        </Card>
      </div>


    );

  }
}



const mapStateToProps = state => {
  return {
  };
}

const mapDispatchToProps = dispatch => {
  return {
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PagiList));
