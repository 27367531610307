import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomCalendar from './CustomCalendar';
import PracticeTree from './PracticeTree';
import Practices from '../../../models/Practices';
import API from '../../API';
import { Panel } from 'react-bootstrap';
import { connect } from "react-redux";
import PlayerSelector from './PlayerSelector';
import Moment from 'moment';
import { auth, main, teamList, playerSelector } from "../../../actions";

class PracticesCarendar extends Component {

  static propTypes = {
    user: PropTypes.object,
    practices: PropTypes.object,
    target: PropTypes.object,
    carendarDay: PropTypes.object,
    handleChangeCarendar: PropTypes.func,
    handleChangePracticeOfDay: PropTypes.func,
    handleSelectTargetPracticesOrLeg: PropTypes.func,
    handleOpenExternalDataModal: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      user: null,
      isLoading: false,
      isError: false,
      // Carander range
      from: null,
      to: null,
      dammy: false,
    };

    this.getPractices = this.getPractices.bind(this);
    this.handleChangeMonth = this.handleChangeMonth.bind(this);
    // Carendar
    this.handleChangeCarendar = this.handleChangeCarendar.bind(this);
    this.handleChangePracticeOfDay = this.handleChangePracticeOfDay.bind(this);
    this.handleSelectTargetPracticesOrLeg = this.handleSelectTargetPracticesOrLeg.bind(this);

    /*
    if(!this.props.calendarDay){
      const time = Moment();
      this.props.setCalendarDay(time)
      let from = time.format('YYYY-MM-01');
      let to = time.endOf('month').format('YYYY-MM-DD');
      this.props.getPractices({ from: from, to: to, handleChangePractices: this.handleChangePractices, targetUser: this.props.authUser });
      this.props.initDispPlayer(this.props.authUser);
    }
    */
  }

  componentDidMount() {
    const time = Moment();
    this.props.setCalendarDay(time)
    let from = time.format('YYYY-MM-01');
    let to = time.endOf('month').format('YYYY-MM-DD');
    this.props.getPractices({ from: from, to: to, targetUser: this.props.authUser, authUser: this.props.authUser });
    this.props.initDispPlayer(this.props.authUser);
  }

  handleChangeMonth(time) {
    let from = time.format('YYYY-MM-01');
    let to = time.endOf('month').format('YYYY-MM-DD');
    this.setState({ from: from, to: to });
    this.getPractices({ from, to });
  }

  //カレンダー月変更時に呼び出される
  getPractices({ from, to }) {
    this.props.getPractices({ from, to, targetUser: this.props.targetUser, authUser: this.props.authUser });
  }

  //====================================================================
  handleChangeCarendar(newCarendarDay) {
    this.props.setPracticesOfDay({ practicesOfDay: null });
    this.props.setSelectedDate(null);
  }

  handleChangePracticeOfDay(day) {
    this.props.setSelectedDate(null);
    const practicesOfDay = this.props.practices.filterByDay(day);
    this.props.setPracticesOfDay({ practicesOfDay: practicesOfDay });
    const target = practicesOfDay.getFirst(); //.getLatest()
    this.loadTarget({ target: target, user: this.props.targetUser });
  }

  handleSelectTargetPracticesOrLeg(target) {
    this.loadTarget({ target: target, user: this.props.targetUser });
  }

  handlePolarUpdated(target) {
    this.props.updatePolar({ polarPlactice: null });
    this.loadTarget({ target: target, user: this.props.targetUser });
  }

  loadTarget({ target }) {
    this.props.loading();

    if (target != undefined) {
      target.themeColor1 = '#ff5100';
      target.themeColor2 = '#3a5a98';
      this.setState({
        isLoaded: false,
      });
      target.load({ onSuccess: this.finalizeLoadTarget, props: this.props });
    } else {
      //targetの更新
      this.props.updateTarget({ target: undefined });
      this.setState({
        isLoaded: true,
      });
    }
  }

  //コールバック関数
  finalizeLoadTarget(target, props) {

    props.getCalcPattern({ target: target });//Leg分割パターンの取得
    props.getEvents({ target: target });//Leg分割パターンの取得

    if (props.compare) {
      props.updateCompare({ target: target });
      return;
    }
    else {
      props.updatePolar({ target: target });
    }

    if (!props.members) {
      //update Graph data
      props.updateDispData({ target: target, user: props.targetUser });
    } else {
      //or If already selected member
      var flg = false;
      props.members.forEach((member) => {
        if ((member.isActive) && (target)) {
          props.getMembersPractices({ target: target, from: target.started_at, to: target.ended_at, member: member, user: props.targetUser });
          flg = true;
        }
      });

      //Activeが１つもないまたは、targetがないとき、普通にロードする。
      if (!flg)
        props.updateDispData({ target: target, user: props.targetUser });
    }
    props.loaded();
  }

  //====================================================================

  render() {
    if (this.state.isLoading) {
      return <span>Loading</span>;
    }
    if (!this.props.calendarDay) {
      return <span>Loading</span>;
    }

    return (
      <div className="left-side-menu">
        <PlayerSelector />
        <CustomCalendar
          handleChangeCarendar={this.handleChangeCarendar}
          carendarDay={this.props.calendarDay}
          handleSelectDay={this.handleChangePracticeOfDay}
          practicesOfDay={this.props.practicesOfDay}
          handleChangeMonth={this.handleChangeMonth}
          practices={this.props.practices}
        />
        <div id="practice-tree">
          <PracticeTree
            user={this.props.targetUser}
            practice={this.props.practicesOfDay}
            practices={this.props.practices}
            target={this.props.target}
            handleSelectTargetPracticesOrLeg={this.handleSelectTargetPracticesOrLeg}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authUser: state.auth.user,
    targetUser: state.PlayerSelector.user,
    players: state.PlayerSelector.players,
    isLoading: state.Main.loading,
    practices: state.Main.practices,
    members_practices: state.TeamList.practices,
    members: state.TeamList.members,
    target: state.Main.target,
    practicesOfDay: state.PlayerSelector.practicesOfDay,
    lastday: state.Main.practicesOfDay,
    calendarDay: state.Main.calendarDay,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getPractices: ({ from, to, targetUser, authUser }) => dispatch(main.getPractices({ from, to, targetUser, authUser })),
    drawGraph: ({ target }) => dispatch(main.drawGraph({ target })),
    updateDispData: ({ target, members_practices, user }) => dispatch(main.updateDispData({ target, members_practices, user })),
    getMembersPractices: ({ target, from, to, member, user }) => dispatch(teamList.getMembersPractices({ target, from, to, member, user })),
    initDispPlayer: (player) => dispatch(playerSelector.initDispPlayer(player)),
    updateTarget: ({ target, user }) => dispatch(main.updateTarget({ target, user })),
    updateCompare: ({ target }) => dispatch(main.updateCompare({ target })),
    updatePolar: ({ target }) => dispatch(main.updatePolar({ target })),
    setPracticesOfDay: ({ practicesOfDay }) => dispatch(main.setPracticesOfDay({ practicesOfDay: practicesOfDay })),
    setCalendarDay: (calendarDay) => dispatch(main.setCalendarDay(calendarDay)),
    loading: () => dispatch(main.loading()),
    loaded: () => dispatch(main.loaded()),
    getCalcPattern: ({ target }) => dispatch(main.getCalcPattern({ target })),
    getEvents: ({ target }) => dispatch(main.getEvents({ target })),
    setSelectedDate: (date) => dispatch(main.setSelectedDate(date)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PracticesCarendar);
