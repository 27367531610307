import React, { Component } from 'react'
import { Container, Row, Col, Button, ButtonGroup, ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap'
import { connect } from "react-redux";
import MapBrowser from './map';
import Table from 'react-bootstrap/Table'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip } from 'recharts'
import Dropdown from 'react-dropdown';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { playerSelector } from "../../actions";
import { wsHOST } from '../../Config';
import './main.css';
const VERSION = 'v2';
const wsUrl = `${wsHOST}/${VERSION}/`;

const dispItems = [
  { "id": 0, "name": "SPEED", "value": 0, "dataKeyStb": "spd_s", "dataKeyPt": "spd_p", "unit": "km/h" },
  { "id": 1, "name": "VMG", "value": 0, "dataKeyStb": "vmg_s", "dataKeyPt": "vmg_p", "unit": "km/h" },
  { "id": 2, "name": "ANGLE", "value": 0, "dataKeyStb": "ang_s", "dataKeyPt": "ang_p", "unit": "deg" },
  { "id": 3, "name": "HEAD", "value": 0, "dataKeyStb": "hed_s", "dataKeyPt": "hed_p", "unit": "deg" },
  { "id": 4, "name": "WIND", "value": 0, "dataKeyStb": "wds_s", "dataKeyPt": "wds_p", "unit": "m/s" },
  { "id": 5, "name": "WIND", "value": 0, "dataKeyStb": "wdd_s", "dataKeyPt": "wdd_p", "unit": "deg" },
  { "id": 6, "name": "PITCH", "value": 0, "dataKeyStb": "pit_s", "dataKeyPt": "pit_p", "unit": "deg" },
  { "id": 7, "name": "ROLL", "value": 0, "dataKeyStb": "rol_s", "dataKeyPt": "rol_p", "unit": "deg" },
];

//const wsUrl  = 'wss://gullcast-data.herokuapp.com/api/ws/training/v1/';
const dataLog = [
];

const gpsLog = [];
const motionLog = [];
const windLog = [];
var gpsCounter = 0;
var motionCounter = 0;
var windCounter = 0;


//Map$BI=<(MQ%G%P%$%9%/%i%9E*$J(B
function Device(id, spd, vmg, ang, pos, rotate, lines, main, wind, wds, wdd, name) {
  this.id = id;
  this.spd = spd;
  this.vmg = vmg;
  this.ang = ang;
  this.pos = pos;
  this.rotate = rotate;
  this.lines = lines;
  this.main = main;
  this.wind = wind;
  this.windspd = wds;
  this.winddir = wdd;
  this.isStarboard = true;
  this.name = name;
}


class RealtimeSailing extends Component {

  //Variable
  ws = null;
  firstGps = true;

  static propTypes = {
    user: PropTypes.object,
    targetPlayer: PropTypes.any,
    handleSelectPlayer: PropTypes.func
  };


  //Constractor
  constructor(props) {
    super(props);

    let latlng = this.props.user.organization.location.split(',')
    this.state = {
      list: dispItems,
      device: null,
      selected_device: null,
      graph: dataLog,
      limit: 600,
      isToggleOn: false,
      dataKeyId: 0,
      dataKeyStb: "spd_s",
      dataKeyPt: "spd_p",
      center: { lat: latlng[0], lng:latlng[1] },
      windspeed: 0,
      winddir: 0,
      windpos: { lat: 0, lng: 0 },
      maxLimit: 50,
      deviceMap: {
        7777:
        {
          id: '7777',
          spd: 0,
          vmg: 0,
          ang: 0,
          pos: [0, 0],
          rotate: 0,
          lines: [],
        }
      },
      isStarboard: true,
      isAuto: true,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChangeMaxLimit = this.handleChangeMaxLimit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleWindModeChange = this.handleWindModeChange.bind(this);
    this.handleUserSelect = this.handleUserSelect.bind(this);
  }

  componentDidMount() {
    let newDevMap = this.state.deviceMap;
    delete newDevMap[7777];//delete

    this.props.getDevices();
  }


  handleUserSelect(option) {
    const device = _.find(this.props.devices, (device) => device.id == option.value);
    this.setState({
      device: device.id,
      selected_device: device
    });

  }
  value() {
    if (this.state.selected_device) {
      return { label: this.state.selected_device.user.last_name + ":" + this.state.device, value: this.state.device };
    } else {
      return { label: 'NoUser', value: 'NoUser' };
    }
  }

  options() {
    if (this.props.devices) {
      let options = this.props.devices.map((device) => { return { label: device.user.last_name + ":" + device.id, value: device.id }; });
      return options;
    }
  }


  //Text
  handleInputChange(event) {
    const target = event.target;
    const name = target.name;

    if (name === "winddir") {
      if ((0 <= Number(target.value)) && (360 > Number(target.value))) {
        let graph = this.state.list;
        graph[5].value = target.value;
        this.setState({
          list: graph.slice(),
        });
      }
    }

    this.setState({
      [name]: target.value,
    });
  }

  //Text
  handleChangeMaxLimit(event) {
    const target = event.target;
    const name = target.name;

    if (name === "maxlimit") {
			this.setState({
				maxLimit: Number(target.value),
			});
    }
  }



  //WindRadioButton
  handleWindModeChange(isAuto) {

    if (isAuto) {
      this.state.isAuto = true;
    } else {
      this.state.isAuto = false;
    }
  }


  //Button
  handleSubmit(event) {
    event.preventDefault();

    if (this.ws != null)
      this.ws.close();
    console.log(wsUrl + this.props.user.organization.id)
    this.ws = new WebSocket(wsUrl + this.props.user.organization.id)

    //////////////////////////////////////////
    this.ws.onopen = () => {
      // on connecting, do nothing but log it to the console
      console.log('connected')
      this.setState(state => ({
        isToggleOn: true
      }));
    }


    //////////////////////////////////////////
    this.ws.onmessage = evt => {
      // listen to data sent from the websocket server
      let result = JSON.parse(evt.data);
      let graph = this.state.list;
      console.log(result);

      let to_unit = 1.0;
      if( this.props.user.unit == "knot")
        to_unit = 1.852;



      //Motion
      if ((result.type === "motion") && (result.id == String(this.state.device))) {
        graph[6].value = result.pitch.toFixed(1);
        graph[7].value = result.roll.toFixed(1);

        //Graph
        let m;
        if (this.state.isStarboard) {
          m = {
            time: new Date(),
            pit_s: graph[6].value,
            rol_s: graph[7].value,

            pit_p: null,
            rol_p: null,
          };
        } else {
          m = {
            time: new Date(),
            pit_s: null,
            rol_s: null,

            pit_p: graph[6].value,
            rol_p: graph[7].value,
          };
        }

        motionCounter = motionCounter + 1;
        updateGraphData(motionLog, m, motionCounter, this.state.limit);

      }
      else if (result.type === "wind") {
        graph[4].value = (result.spd / to_unit).toFixed(2);

        //Manual$B$N;~$O99?7$7$J$$(B
        if (this.state.isAuto) {
          graph[5].value = result.dir;
        }


        let newDevMap = this.state.deviceMap;
        if (newDevMap.hasOwnProperty(result.id)) {
          let old = newDevMap[result.id];
          let dev = new Device(result.id, old.spd, old.vmg, old.ang, old.pos,
            old.rotate, old.lines, old.main, old.wind, graph[4].value, graph[5].value, "test");
          delete newDevMap[result.id];//delete
          newDevMap[result.id] = dev;//update
        } else {
          let wind = false;
          let main = false;
          if (result.id === "1000")
            wind = true;
          else if (result.id === String(this.state.device))
            main = true;
          let dev = new Device(result.id, 0, 0, 0,
            { lat: 0, lng: 0 }, 0, [], main, wind, "wind");
          newDevMap[result.id] = dev;//new
        }

        //Graph
        let w;
        if (this.state.isStarboard) {
          w = {
            time: new Date(),
            wds_s: graph[4].value,
            wdd_s: graph[5].value,

            wds_p: null,
            wdd_p: null,
          };
        } else {
          w = {
            time: new Date(),
            wds_s: null,
            wdd_s: null,

            wds_p: graph[4].value,
            wdd_p: graph[5].value,
          };
        }

        windCounter = windCounter + 1;
        updateGraphData(windLog, w, windCounter, this.state.limit);

        this.setState({
          windspeed: graph[4].value,
          winddir: graph[5].value,
        });
      }

      // GPS   /////////////////////////////////
      if ((result.type === "gps")) {

        let work = [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }];
        //CalData /////////////////
        work[0].value = (result.spd / to_unit).toFixed(2);//Spd
        work[3].value = result.dir.toFixed(0);//Head
        let sita = (graph[5].value) - work[3].value;
        work[1].value = (Math.cos(sita * 2 * Math.PI / 360) * result.spd / to_unit).toFixed(2);//VMG
        if (Math.abs(sita) > 180)
          work[2].value = 360 - Math.abs(sita); //ANGLE
        else
          work[2].value = Math.abs(sita)
        //End CalData /////////////////

				let name = "";
				if (this.props.devices) {
					this.props.devices.map((device) => {
						if(device.id == result.id){
							name = device.user.last_name;
						}
					});
				}

        //Update Map /////////////////
        //All Device position update or create
        let newDevMap = this.state.deviceMap;
        if (newDevMap.hasOwnProperty(result.id)) {
          //$B%f!<%6$N9R@W(Badd
          let lines = newDevMap[result.id].lines;
          lines.push([result.lat, result.long]);
          let dev = new Device(result.id, work[0].value, work[1].value, work[2].value,
            { lat: result.lat, lng: result.long }, work[3].value, lines,
            newDevMap[result.id].main, newDevMap[result.id].wind,
            newDevMap[result.id].wds, newDevMap[result.id].wdd, name);
          delete newDevMap[result.id];//delete
          newDevMap[result.id] = dev;//update
        } else {
          let wind = false;
          let main = false;
          if (result.id === "1000")
            wind = true;
          else if (result.id === String(this.state.device))
            main = true;
          let dev = new Device(result.id, work[0].value, work[1].value, work[2].value,
            { lat: result.lat, lng: result.long }, work[3].value, [], main, wind, 0, 0, "wind");
          newDevMap[result.id] = dev;//new
        }

        //State$B$N99?7(B
        this.setState({ deviceMap: newDevMap });
        //Update Map /////////////////


        //#######################################
        //Following logic is proceeded only user
        if (result.id === String(this.state.device)) {
          //Slide map only first time
          if ((this.firstGps) && (Math.abs(result.lat) > 0)) {
            this.setState({
              center: { lat: result.lat, lng: result.long },
            });
            this.firstGps = false;
          }


          //Port and Starboard
          let offset = graph[5].value - graph[3].value;//  Wind - Head
          let isStb = true;
          if (offset > 180) {
            offset = offset - 360;
          } else if (offset < -180) {
            offset = offset + 360;
          }

          if (offset < 0) {
            newDevMap[result.id].isStarboard = false;
            isStb = false;
          } else {
            newDevMap[result.id].isStarboard = true;
            isStb = true;
          }

          this.setState({
            isStarboard: isStb
          });


          //For GridDisp update
          graph[0].value = work[0].value;
          graph[1].value = work[1].value;
          graph[2].value = work[2].value;
          graph[3].value = work[3].value;


          //Graph update
          let g;
          if (isStb) {
            g = {
              time: new Date(),
              spd_s: work[0].value,
              vmg_s: work[1].value,
              ang_s: work[2].value,
              hed_s: work[3].value,

              spd_p: null,
              vmg_p: null,
              ang_p: null,
              hed_p: null,
            }
          } else {
            g = {
              spd_s: null,
              vmg_s: null,
              ang_s: null,
              hed_s: null,

              spd_p: work[0].value,
              vmg_p: work[1].value,
              ang_p: work[2].value,
              hed_p: work[3].value,
            }
          }

          gpsCounter = gpsCounter + 1;
          updateGraphData(gpsLog, g, gpsCounter, this.state.limit);

        }//#######################################
      }//GPS end

      //WindPosition
      if ((result.type === "gps") && (result.id === "1000")) {
        this.setState({
          windpos: { lat: result.lat, lng: result.long },
        });
      }

      if (this.state.dataKeyId <= 3) {
        this.ary = gpsLog;
      } else if (this.state.dataKeyId <= 5) {
        this.ary = windLog;
      } else {
        this.ary = motionLog;
      }

      //Refresh!
      this.setState({
        list: graph.slice(),
        graph: this.ary.slice()
      });
    }

    //////////////////////////////////////////
    this.ws.onclose = () => {
      console.log('disconnected')
      this.setState(state => ({
        isToggleOn: false
      }));
      // automatically try to reconnect on connection loss
    }
  }



  //$B%0%i%UI=<(%G!<%?$N@Z$jBX$((B
  changeGraph(id) {
    let disp = this.state.list;
    this.setState(state => ({
      dataKeyId: id,
      dataKeyStb: disp[id].dataKeyStb,
      dataKeyPt: disp[id].dataKeyPt
    }));
  }



  render() {
    const Port = (
      <ButtonGroup aria-label="Basic example" >
        <Button variant={'danger'}>Port</Button>
        <Button variant={'outline-success'}>Starboard</Button>
      </ButtonGroup>
    );

    const Starboard = (
      <ButtonGroup aria-label="Basic example" >
        <Button variant={'outline-danger'}>Port</Button>
        <Button variant={'success'}>Starboard</Button>
      </ButtonGroup>
    )


console.log(this.state.deviceMap)
    return (
      <Container>
        <Row>
          <Col xs={6}>
            <Dropdown
              options={this.options()}
              value={this.value()}
              onChange={this.handleUserSelect}
            />
          </Col>
          <Col xs={6}>
            <Button variant="outline-primary" onClick={this.handleSubmit}> {this.state.isToggleOn ? 'Connected!' : 'Connect'}</Button>
          Limit:<input type="text" name="limit" value={this.state.limit} onChange={this.handleInputChange} />
          </Col>
        </Row>
        <Row>
          <Col><MapBrowser
            deviceMap={this.state.deviceMap}
            deviceId={String(this.state.device)}
            center={this.state.center}
            windSpeed={this.state.windspeed} windDirection={this.state.winddir}
            windpos={this.state.windpos}
            user={this.props.user}
          />
          </Col>
          <Col xs={7}>
            <Table striped bordered hover variant="dark">
              <tbody className="GridData">

                <tr>
                  <td className={this.state.dataKeyId === 0 ? 'Active' : 'NotActive'} onClick={this.changeGraph.bind(this, 0)}>SPEED {this.state.list[0].value} <span className="Unit">{this.props.user.unit}</span></td>
                  <td className={this.state.dataKeyId === 1 ? 'Active' : 'NotActive'} onClick={this.changeGraph.bind(this, 1)}>VMG  {this.state.list[1].value}  <span className="Unit">{this.props.user.unit}</span></td>
                </tr>
                <tr>
                  <td className={this.state.dataKeyId === 2 ? 'Active' : 'NotActive'} onClick={this.changeGraph.bind(this, 2)}>ANGLE {this.state.list[2].value} <span className="Unit">{this.state.list[2].unit}</span></td>
                  <td className={this.state.dataKeyId === 3 ? 'Active' : 'NotActive'} onClick={this.changeGraph.bind(this, 3)}>HEAD {this.state.list[3].value} <span className="Unit">{this.state.list[3].unit}</span></td>
                </tr>
                <tr>
                  <td className={this.state.dataKeyId === 4 ? 'Active' : 'NotActive'} onClick={this.changeGraph.bind(this, 4)}>WIND {this.state.list[4].value} <span className="Unit">{this.props.user.wind_unit}</span></td>
                  <td className={this.state.dataKeyId === 5 ? 'Active' : 'NotActive'} onClick={this.changeGraph.bind(this, 5)}>WIND {this.state.list[5].value} <span className="Unit">{this.state.list[5].unit}</span></td>
                </tr>
                <tr>
                  <td className={this.state.dataKeyId === 6 ? 'Active' : 'NotActive'} onClick={this.changeGraph.bind(this, 6)}>PITCH {this.state.list[6].value} <span className="Unit">{this.state.list[6].unit}</span></td>
                  <td className={this.state.dataKeyId === 7 ? 'Active' : 'NotActive'} onClick={this.changeGraph.bind(this, 7)}>HEEL  {this.state.list[7].value} <span className="Unit">{this.state.list[7].unit}</span></td>
                </tr>

              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col xs={5}>{this.state.isStarboard ? Starboard : Port}</Col>
          <Col >
            <ButtonToolbar>
              <ToggleButtonGroup name="isAuto" type="radio" defaultValue={this.state.isAuto} onChange={this.handleWindModeChange} >
                <ToggleButton name="autoWind" variant="info" value={true}>Auto</ToggleButton>
                <ToggleButton name="manualWind" variant="info" value={false}>Manual</ToggleButton>
              </ToggleButtonGroup>
            </ButtonToolbar>
          </Col>
          <Col xs={4}>
            <span className={"ManualWind"}>Manual WindDir: <input className={"WindValue"} type="number" maxlength={3} size={3} pattern="[0-9]*" title="Input only number" disabled={this.state.isAuto} name="winddir" value={this.state.winddir} onChange={this.handleInputChange} /></span>
          </Col>
          <Col xs={2}>
            <span className={"ManualWind"}>Max Y: <input name={"maxlimit"} className={"WindValue"} type="number" maxlength={3} size={3} pattern="[0-9]*" value={this.state.maxLimit} onChange={this.handleChangeMaxLimit} /></span>
          </Col>
        </Row>
        <Row>
          <Col>
            <ResponsiveContainer width="100%" height="80%" minWidth={400} minHeight={300}>
              <LineChart data={this.state.graph} >
                <XAxis dataKey="time" />
                <YAxis domain={[0, this.state.maxLimit]} />
                <Tooltip />
                <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                <Line type="monotone" dataKey={this.state.dataKeyStb} stroke="#006400" isAnimationActive={false} />
                <Line type="monotone" dataKey={this.state.dataKeyPt} stroke="#dc143c" isAnimationActive={false} />
              </LineChart>
            </ResponsiveContainer>
          </Col>
        </Row>
      </Container>
    );
  }
}


/**
 * $B%0%i%U(Blimit$B9MN8$7$F$=$l$>$l$N%j%9%H$KDI2C$9$k(B
 */
function updateGraphData(list, data, counter, limit) {

  if (counter < limit) {
    list.push(data);
  } else {
    list.shift();
    list.push(data);
  }

}



const mapStateToProps = state => {
  return {
    user: state.auth.user,
    targetUser: state.PlayerSelector.user,
    devices: state.PlayerSelector.devices,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getDevices: () => dispatch(playerSelector.getDevices()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RealtimeSailing);
