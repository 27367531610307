import React, {Component} from "react";
import {connect} from "react-redux";
import {Link, Redirect} from "react-router-dom";
import { device } from "../../actions";
import PagiList from "../list/PagiList";
import {ActionsComponent} from './ActionsComponent';
import {StatusField, GNSSField, BatteryField } from './CustomizeField';

import { Button } from 'react-bootstrap'


const columuns = [
  {dataField:'id',text:'Device ID'},
  {dataField:'category',text:'Device type'},
  {dataField:'user',text:'User'},
  {dataField:'status',text:'Status', formatedField: StatusField },
  {dataField:'gps',text:'GNSS', formatedField: GNSSField },
  {dataField:'battery',text:'Battery', formatedField: BatteryField },
]; 



class Devices extends Component {


  constructor(props) {
    super(props);

    this.state = {
      intervalId: null,
    }
  }

  componentDidMount() {


    //ログイン中ユーザの所属する組織のデバイス一覧を取得
    this.props.getDevices();

    //初回のみ1秒で１回
    let once = setInterval(() => {
            this.props.getDevicesInfo(this.props.devices);
            clearInterval(once);
    } ,1000);



    //デバイスステータスを取得
    let intervalId =  setInterval(() => {
            this.props.getDevicesInfo(this.props.devices);
        } ,5000);
    this.setState({ intervalId: intervalId });
  }

  componentWillUnmount(){
    clearInterval(this.state.intervalId)
  }


  render() {
    if(!this.props.devices){
      return <p>Loading...</p>;
    }

    return (
      <span>
        <PagiList
          title={ "Device一覧" }
          columuns={ columuns } data={ this.props.devices }
          itemsPerPage={20}
          actionsComponent={ ActionsComponent }
          customizeField1={ StatusField }
          customizeField2={ GNSSField }
          customizeField3={ BatteryField }
        />
    </span>

    );
  }
}

const mapStateToProps = state => {
  return {
    devices: state.Device.devices,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getDevices: () => dispatch(device.getDevices()),
    getDevicesInfo: (devices) => dispatch(device.getDevicesInfo(devices)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Devices);
