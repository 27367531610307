const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  loading: false,
  user: null,
  errors: {},
  openLeftPanel: true,
  practices: null,
  practicesOfDay: null,
  calendarDay: null,
  dispPractice: null,
  target: null,
  calc_pattern: null,
  prevButtonState: true,
  nextButtonState: true,
  grapfArea: null,
  compare_target: null,
  isMapReload: false,
  rankingDay: null,
  setting_list: [],
  track_mode: false,
};


export default function Main(state = initialState, action) {

  switch (action.type) {

    case 'PRACTICE_LOADING':
      return { ...state, loading: true, msgType: '', message: '', };

    case 'PRACTICE_LOADED':
      return { ...state, loading: false, msgType: '', message: '', };

    case 'GRAPH_AREA':
      return { ...state, graphArea: action.graphArea };

    case 'GET_PRACTICES':
      return { ...state, loading: false, practices: action.practices, practicesOfDay: action.practicesOfDay, msgType: '', message: '', rankingDay: action.rankingDay };

    //case 'DRAW_GRAPH':
    //  return { ...state, loading: false, target: action.target, msgType: '', message: '', };

    case 'GET_CALC_PATTERN':
      return { ...state, loading: false, calc_patterns: action.calc_pattern, msgType: '', message: '', };

    case 'GET_EVENTS':
      return { ...state, loading: false, events: action.events, msgType: '', message: '', };

    case 'SET_SELECT_BUTTON_VISIBLE':
      return { ...state, prevButtonState: action.prevButtonState, nextButtonState: action.nextButtonState };

    case 'SET_PLACTICE_TIMES':
      return { ...state, practice_times: action.practice_times };

    case 'SET_CALENDAR_DAY':
      return { ...state, calendarDay: action.calendarDay };

    case 'UPDATE_POLAR':
      return { ...state, target: action.target };

    case 'UPDATE_COMPARE':
      return { ...state, compare_target: action.target };

    case 'GET_RANKINGS':
      return { ...state, up_vmgs: action.up_vmgs, down_vmgs: action.down_vmgs, max_speeds: action.max_speeds };

    case 'REROAD_MAP':
      return { ...state, isMapReload: action.isMapReload };

    case 'SET_SELECTED_DATE':
      return { ...state, selectedDate: action.selectedDate };

    case 'GET_SETTING':
      return { ...state, loading: false, setting_list: action.setting_list, msgType: '', message: '', };

    case 'TRACK_MODE':
      return { ...state, loading: false, track_mode: action.track_mode };

    default:
      return state;
  }
}
