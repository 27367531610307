import React, {Component} from "react";
import {connect} from "react-redux";
import Button from '@mui/material/Button';
import {Link, Redirect} from "react-router-dom";
import {auth, main, device} from "../actions";
import { HOST, ENV } from '../Config';

const VERSION = 'v1';
const ENDPOINT = `${HOST}/${VERSION}`;
const TKN_HEAD= 'Bearer';//'token';


class Practices extends Component {

  constructor(props) {
    super(props);

    this.state = {
      device_id:     '',
      file: null, // アップロードするファイルを保持
    };

   //Radio イベント用メソッド
    this.handleChangeDeviceId = this.handleChangeDeviceId.bind(this);
    this.handleChangeUrl = this.handleChangeUrl.bind(this);
  }

  componentDidMount() {
    //ログイン中ユーザの所属する組織のデバイス一覧を取得
    this.props.getDevices();
  }



  handleChangeDeviceId(event) {
    this.setState( {device_id: event.target.value} );
  }
  handleChangeUrl(event) {
    this.setState( {url: event.target.value} );
  }

  handleFileChange = (event) => {
    const file = event.target.files[0]; // 選択されたファイル
    this.setState({ file });
  };

	onSubmit = e => {
		e.preventDefault();

		if (!this.state.file) {
			alert('ファイルを選択してください');
			return;
		}
		if (!this.state.device_id) {
			alert("Device ID が設定されていません。");
			return;
		}

		// 必要なデータを FormData に追加
		const formData = new FormData();
		formData.append('device_id', this.state.device_id); // device_id のキー名を確認
		formData.append('file', this.state.file);

		const token = this.props.authUser.token;

		// Authorization ヘッダーのみを設定
		const headers = {
			Authorization: `${TKN_HEAD} ${token}`, // TKN_HEAD と token は事前に定義されている必要があります
		};

		// fetch を使用してバックエンドにデータを送信
		fetch(`${ENDPOINT}/upload`, {
			method: 'POST',
			body: formData, // FormData を直接 body に設定
			headers, // Content-Type はブラウザが自動で設定する
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error(`HTTPエラー: ${response.status}`);
				}
				return response.json();
			})
			.then((data) => {
				console.log('アップロード成功:', data);
				alert('ファイルがアップロードされました');
			})
			.catch((error) => {
				console.error('アップロードエラー:', error);
				alert('ファイルのアップロード中にエラーが発生しました');
			});
	};

  render() {
    if(!this.props.devices){
      return <p>Loading...</p>;
    }
   return (
    <div>
      データアップロードをしてください
     <br />
     <br />

      <span className='settings-lbl'>
        <span className='settings-lbl-txt'>登録する選手（デバイスID）を指定してください</span>
      </span>

      <select
        id="device-selector"
        onChange={this.handleChangeDeviceId}
        value={this.state.device_id}
      >
				{/* 初期表示用のオプション */}
				<option value="" disabled>
					--選択してください--
				</option>
        {this.props.devices.map((device) => (
          <option key={device.id} value={device.id}>
            {`${device.id}:${device.user}`}
          </option>
        ))}
      </select>
     <br />
     <br />

      <span className='settings-lbl'>
        <span className='settings-lbl-txt'>アップロードするデータを選択（.gpx Or .tcx Or .nmea[GxRMC])</span>
      </span>
      <input type="file" accept=".txt,.csv,.gpx,.tcx,.nmea" onChange={this.handleFileChange} />
     <br />
      <form onSubmit={this.onSubmit}>
          <p>
            <Button 
              type="submit" 
              variant="contained" 
              color="primary" 
              size="large"
            >
              Upload
            </Button>
          </p>
      </form>

    </div>

    )
  }
}

const mapStateToProps = state => {
  return {
    devices: state.Device.devices,
    isAuthenticated: state.auth.isAuthenticated,
    authUser: state.auth.user,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getDevices: () => dispatch(device.getDevices()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Practices);
