const initialState = {
  errors: {},
};


export default function Device(state=initialState, action) {

  switch (action.type) {

    case 'GET_DEVICE':
      return {...state, device: action.device };


    case 'GET_DEVICES_INFO':
      return {...state, devices: action.devices.slice() };


    case 'SET_DEVICES':
      return {...state, devices: action.devices };



    case 'GET_CATEGORY':
      return {...state, category: action.category };


   case 'GET_DEVICES_FOR_LIST':
      return {...state, devices: action.devices};


    default:
      return state;
  }
}
