import React, {Component} from "react";
import {connect} from "react-redux";
import { main } from "../../actions";
import {Accordion, Card, Button} from "react-bootstrap";
import Form from "./SearchForm";

class Search extends Component {

  constructor(props) {
    super(props);

    this.state = {
    }
  }

  componentDidMount() {
  }

  render() {
    return (
        <Accordion>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="0">
               検索 
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
    );
  }
}

const mapStateToProps = state => {
  return {
    targetUser: state.PlayerSelector.user,
    authUser: state.auth.user,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    updateCompare: ({ target }) => dispatch(main.updateCompare({ target })),
    updatePolar: ({ target }) => dispatch(main.updatePolar({ target })),
    loading: () => dispatch(main.loading()),
    loaded: () => dispatch(main.loaded()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);
