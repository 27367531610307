import Moment from 'moment';
import _ from 'lodash';
import Practice from './Practice';

/**
 * 複数の API `Practice/Practice Collection/List Practice` の返却結果（response）と
 * 対応する複数の API `Practice/PRACTICE URL/PRACTICE URL` で返却される legs から
 * 取得するデータを格納する
 */
export default class Practices {
  constructor(practices) {
    this._practices = practices;
  }

  static fromJSON(json, unit, wind_unit) {
    let _practices = json.map((p) => Practice.fromJSON(p, unit, wind_unit));
    return new Practices(_practices);
  }

  get gpses() {
    return this._practices.map((practice) => practice.gpses).flat();
  }

  get dates() {
    if (!this._dates) {
      this._dates = this._practices.map((practice) => Moment(new Date(practice.started_at)));
    }
    return this._dates;
  }

  get uuids() {
    return this.map((practice) => practice.uuid);
  }

  // FIXME Need proper error handling
  load({ onSuccess }) {

    let tasks = this.map((practice) => new Promise((resolve, reject) => practice.load({ onSuccess: resolve, onError: reject })));
    Promise.all(tasks).then(() => {
      if (onSuccess !== undefined) {
        onSuccess();
      }
    });
  }

  isEmpty() {
    if (this._practices.length == 0) {
      return true;
    } else {
      return this._practices[0].isEmpty();
    }
  }

  getSeries(dataType) {
    return this._practices.map((practice) => practice.getSeries(dataType)).flat();
  }

  getMapCenter() {
    return this.first().getMapCenter();
  }

  findByUUID(uuid) {
    return _.find(this._practices, (p) => p.uuid == uuid);
  }

  findLegByUUID(uuid) {
    let legs = this.map((practice) => practice.legs.findByUUID(uuid));
    legs = _.compact(legs);
    if (legs.length == 1) {
      return legs[0];
    } else if (legs.length > 2) {
      throw new Error('Duplicate leg uuid');
    } else {
      return undefined;
    }
  }

  getLatest() {
    return _.maxBy(this._practices, 'started_at');
  }

  getFirst() {
    return _.minBy(this._practices, 'started_at');
  }


  // retuns Moment of latest practice day of given practices
  getLatestDay() {
    let available = this.availablePracticeDates();
    return Moment.max(available);
  }

  // list dates of available practices to restrict carender selection
  availablePracticeDates() {
    return this._practices.map((p) => Moment(p.started_at));
  }

  findLegById(id) {
    let leg;
    for (let i = 0; i < this._practices.length; i++) {
      leg = this._practices[i].legs.findLegById(id);
      if (leg !== undefined) return leg;
    }
    return undefined;
  }

  filterByDay(day) {
    return this.filter((practice) => practice.started_at.isSame(day, 'day'));
  }

  get(index) {
    return this._practices[index];
  }

  first() {
    return this._practices[0];
  }

  last() {
    return this._practices[this.toArray().length - 1];
  }

  forEach(func) {
    this._practices.forEach(func);
  }

  filter(func) {
    return new Practices(this._practices.filter(func));
  }

  map(func) {
    return this._practices.map(func);
  }

  toArray() {
    return this._practices;
  }

  setSmoothingRound(dataType) {
    this.forEach((practice) => practice.setSmoothingRound(dataType));
  }

  setSmoothingAverage(dataType, step) {
    this.forEach((practice) => practice.setSmoothingAverage(dataType, step));
  }

  setVisibility(dataType, min, max) {
    this.forEach((practice) => practice.setVisibility(dataType, min, max));
  }

  setVisibilityTimeSeries(min, max) {
    this.forEach((practice) => practice.setVisibilityTimeSeries(min, max));
  }

  resetVisibility() {
    this.forEach((practice) => practice.resetVisibility());
  }

  len() {
    return this._practices.length;
  }

  static practicesLoaded(practices) {
    // let practicesOfDay = practices.filterByDay(this.state.rankingDay);
    // console.log(this.state.rankingDay)
    // console.log(practices)
    // this.props.setPracticesOfDay({ practicesOfDay: practicesOfDay });
  }

}
