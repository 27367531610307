import React, { Component } from 'react';
import { connect } from "react-redux";
import './Main.css';
import Table from 'react-bootstrap/Table';


export default class Summary extends Component {

  render() {

    if(!this.props.target)
      return ""

    return (
      <span>
        <Table striped bordered hover variant="dark">
          <tbody>
            <tr>
              <td className="GridData2" ><div className="Unit2">max SPD</div><span className="summary"> {this.props.target.max_speed}</span><span className="Unit2">{this.props.authUser.unit}</span></td>
              <td className="GridData2" ><div className="Unit2">ave SPD</div><span className="summary">  {this.props.target.ave_speed}</span><span className="Unit2">{this.props.authUser.unit}</span></td>
            </tr>
            <tr>

              <td className="GridData2" ><div className="Unit2">max 2s SPD</div><span className="summary">  {this.props.target.max_2s_spd}</span><span className="Unit2">{this.props.authUser.unit}</span></td>
              <td className="GridData2" ><div className="Unit2">max 10s SPD</div><span className="summary">  {this.props.target.max_10s_spd}</span><span className="Unit2">{this.props.authUser.unit}</span></td>
            </tr>
          </tbody>
        </Table>
        <Table striped bordered hover variant="dark" className="GridData2">
          <tbody className="GridData2">
            <tr>
              <td className="GridData2" ><div className="Unit2">max up VMG</div><span className="summary">  {this.props.target.max_vmg_up}</span><span className="Unit2">{this.props.authUser.unit}</span></td>
              <td className="GridData2" ><div className="Unit2">ave up VMG</div><span className="summary">  {this.props.target.ave_vmg_up}</span><span className="Unit2">{this.props.authUser.unit}</span></td>
            </tr>
            <tr>
              <td className="GridData2" ><div className="Unit2">max down VMG</div><span className="summary">  {this.props.target.max_vmg_down}</span><span className="Unit2">{this.props.authUser.unit}</span></td>
              <td className="GridData2" ><div className="Unit2">ave down VMG</div><span className="summary">  {this.props.target.ave_vmg_down}</span><span className="Unit2">{this.props.authUser.unit}</span></td>
            </tr>
          </tbody>
        </Table>
      </span>
    );
  }
}
