import React, { Component } from "react";
import { connect } from "react-redux";
import { main } from "../../actions";
import { Accordion, Card, Button } from "react-bootstrap";
import CardContent from "./CardContent";

class RankingList extends Component {

  constructor(props) {
    super(props);

    this.state = {
    }
  }

  componentDidMount() {
  }


  render() {
    const { list } = this.props;
    if (!list) {
      return <p>List loading...</p>;
    }
    let top3 = <CardContent>No data.</CardContent>;
    if (list.len() > 0)
      top3 = (list.toArray().slice(0, 3).map((practice) => (
        <CardContent key={practice.id} practice={practice} param={this.props.param} unit={this.props.authUser.unit} />
      )));

    return (
      <Accordion>
        <Card>
          <Card.Header>
            {this.props.title}
          </Card.Header>
          <div>
            {top3}
          </div>
          <Accordion.Collapse eventKey="0">
            <div>
              {list.toArray().slice(3).map((practice) => (
                <CardContent key={practice.id} practice={practice} param={this.props.param} unit={this.props.authUser.unit} />
              ))}
            </div>
          </Accordion.Collapse>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="0">
              上位 {list.len()} 件を表示
              </Accordion.Toggle>
          </Card.Header>
        </Card>
      </Accordion>
    );
  }
}

const mapStateToProps = state => {
  return {
    targetUser: state.PlayerSelector.user,
    authUser: state.auth.user,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    updateCompare: ({ target }) => dispatch(main.updateCompare({ target })),
    updatePolar: ({ target }) => dispatch(main.updatePolar({ target })),
    loading: () => dispatch(main.loading()),
    loaded: () => dispatch(main.loaded()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RankingList);
