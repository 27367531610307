import React, { Component } from 'react';
import BasicMap from './map/BasicMap';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import ErasableTrack from './map/ErasableTrack';
import WindDirectionArrow from '../../../track/wind-direction-arrow.svg';

export default class Map extends Component {

  static propTypes = {
    target: PropTypes.object,
    windDirection: PropTypes.number,
  }

  constructor(props) {
    super(props);
    this.uuid = uuidv4();
    this.state = {
      map: null,
      track2: null
    };
  }

  componentDidMount() {
    if (this.props.target.legs.isEmpty()) {
      // We can't draw anything if we don't have data
      return;
    }
    this.state.map = new BasicMap({
      bindTo: this.uuid,
      mapCenter: this.props.target.getMapCenter(),
      handleClickOnMap: this.props.handleClickOnMap,
      buoy_coordinate: this.props.target.buoy_coordinate,
    });
    let track = new ErasableTrack(
      this.uuid,
      this.props.target.gpses,
      this.props.target.themeColor2,
    );
    this.state.map.addFeatures(track.getFeatures());

  }

  render() {

    if (this.state.map) {
      if (this.state.track2) {
        this.state.map.removeFeatures(this.state.track2.getFeatures());
      }
      this.state.track2 = new ErasableTrack(
        this.uuid,
        this.props.selectedGpses,
        this.props.target.themeColor1,
      );
      this.state.map.addFeatures(this.state.track2.getFeatures());
    }

    return (
      <div id={this.uuid} className='ol-tracking-map'>
        <div className='wind-direction-icon'>
          <span className='top-label'>WIND</span>
          <span className='arrow-label'>
            <img className='arrow-img' src={WindDirectionArrow} style={{ transform: `rotateZ(${this.props.windDirection}deg)` }} />
          </span>
        </div>
      </div>
    );
  }
}
