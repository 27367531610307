import GPS from './GPS';
import * as proj from 'ol/proj';
import _ from 'lodash';
import Moment from 'moment';

/**
 * API `List Session`で返却される legs[] １件分用データクラス
 * API `Session URL` で返却される legs[].data[] １件分用クラス
 */
export default class Leg {

  constructor(
    practice, id, distance, max_speed, max_vmg_up, max_vmg_down,
    started_at, ended_at, duration, polar, max_vmg,
    stroke, ave_storke_per_minutes, pace100m_seconds, stroke100m,
    pace500m_seconds, stroke500m, pace1000m_seconds, stroke1000m, duration_seconds,
  ) {
    this.practice = practice;
    this.id = id;
    this.distance = distance;

    this.started_at = started_at;
    this.ended_at = ended_at;
    this.duration = duration;
    //this.polar = polar;  ポーラーはLegのPolarからPracticePolarを参照へ SmartSailing#350
    //this.max_vmg = max_vmg; 最大VMGはLegのmax_vmgからPractice.max_vmgを参照へ SmartSailing#350
    this.stroke = stroke;
    this.ave_storke_per_minutes = ave_storke_per_minutes;
    this.pace100m_seconds = pace100m_seconds;
    this.stroke100m = stroke100m;
    this.pace500m_seconds = pace500m_seconds;
    this.stroke500m = stroke500m;
    this.pace1000m_seconds = pace1000m_seconds;
    this.stroke1000m = stroke1000m;
    this.duration_seconds = duration_seconds;
  }

  static fromJSON(practice, legJSON) {
    return new Leg(
      practice,
      legJSON.id,
      legJSON.distance,
      legJSON.max_speed,
      legJSON.max_vmg_up,
      legJSON.max_vmg_down,
      Moment(legJSON.started_at),
      Moment(legJSON.ended_at),
      legJSON.duration,
      legJSON.polar,
      legJSON.max_vmg,
      // legJSON.unit
      legJSON.stroke,
      legJSON.ave_storke_per_minutes,
      legJSON.pace100m_seconds,
      legJSON.stroke100m,
      legJSON.pace500m_seconds,
      legJSON.stroke500m,
      legJSON.pace1000m_seconds,
      legJSON.stroke1000m,
      legJSON.duration_seconds,
    );
  }

  get uuid() {
    return `practice${this.practice.id}-leg${this.id}`;
  }

  get uuids() {
    return [this.uuid];
  }

  treeTitle(index) {
    return `Leg${index}   ${this.distance}m    ${this.started_at.format('HH:mm')}`;
  }
  // 練習記録チャートコンポーネント用設定関連
  set themeColor1(color) {
    this._themeColor1 = color;
  }

  get themeColor1() {
    return this._themeColor1;
  }

  set themeColor2(color) {
    this._themeColor2 = color;
  }

  get themeColor2() {
    return this._themeColor2;
  }

  // Although we have started_at/ended_at, we need startedAt/endedAt to get exact gps time of start/end
  // Because we need min/max value for graph
  get startedAt() {
    return _.first(this.gpses).time;
  }

  get endedAt() {
    return _.last(this.gpses).time;
  }

  get gpsLog() {
    return this.practice.gpsLog;
  }
  get motionLog() {
    return this.practice.motionLog;
  }
  get windLog() {
    return this.practice.windLog;
  }

  get max_up_distance() {
    return this.practice.max_up_distance;
  }

  get buoy_distance() {
    return this.practice.buoy_distance;
  }
  get user() {
    return this.practice.user;
  }
  get gpsLine() {
    return this.practice.gpsLine;
  }
  get gpsAllLine() {
    return this.practice.gpsAllLine;
  }

  //--- 統計はPracticeで計算しているのでそちらを参照する ---------
  get wind_direction() {
    return this.practice.wind_direction;
  }
  get max_speed() {
    return this.practice.max_speed;
  }
  get ave_speed() {
    return this.practice.ave_speed;
  }
  get max_2s_speed() {
    return this.practice.max_2s_speed;
  }
  get max_10s_speed() {
    return this.practice.max_10s_speed;
  }
  get max_vmg_up() {
    return this.practice.max_vmg_up;
  }
  get max_vmg_down() {
    return this.practice.max_vmg_down;
  }
  get ave_vmg_up() {
    return this.practice.ave_vmg_up;
  }
  get ave_vmg_down() {
    return this.practice.ave_vmg_down;
  }
  get max_2s_spd() {
    return this.practice.max_2s_spd;
  }
  get max_10s_spd() {
    return this.practice.max_10s_spd;
  }
  get buoy_coordinate() {
    return this.practice.buoy_coordinate;
  }
  get polar() {//#350
    return this.practice.polar;
  }
  get max_vmg() {//#350
    return this.practice.max_vmg;
  }
  get ave_roll() {
    return this.practice.ave_roll;
  }
  get ave_pitch() {
    return this.practice.ave_pitch;
  }
  //--- setter -------------------
  //区間選択で再計算される項目
  set_max_speed(v) {
    this.practice.max_speed = v;
  }
  set_ave_speed(v) {
    this.practice.ave_speed = v;
  }
  set_max_2s_speed(v) {
    this.practice.max_2s_speed = v;
  }
  set_max_10s_speed(v) {
    this.practice.max_10s_speed = v;
  }
  set_max_vmg_up(v) {
    this.practice.max_vmg_up = v;
  }
  set_max_vmg_down(v) {
    this.practice.max_vmg_down = v;
  }
  set_ave_vmg_up(v) {
    this.practice.ave_vmg_up = v;
  }
  set_ave_vmg_down(v) {
    this.practice.ave_vmg_down = v;
  }
  set_max_2s_spd(v) {
    this.practice.max_2s_spd = v;
  }
  set_max_10s_spd(v) {
    this.practice.max_10s_spd = v;
  }
  set_ave_roll(v) {
    this.practice.ave_roll = v;
  }
  set_ave_pitch(v) {
    this.practice.ave_pitch = v;
  }
  set_st(v) {//計測開始時刻
    this.st = v;
  }
  set_et(v) {//計測終了時刻
    this.et = v;
  }





  //--- 統計はPracticeで計算しているのでそちらを参照する ---------


  isEmpty() {
    if (this.gpses == undefined) {
      return true;
    }
    return this.gpses.length == 0;
  }

  load({ onSuccess, onError, props }) {

    //currentLehは、グラフ表示中のレグIDを示す
    this.practice.currentLeg = this.uuid;

    //Practiceが一度でも読み込まれていれば、Legに従属するGPSを表示
    if (this.practice.isLoaded) {
      this.practice.createGraphData(this.gpses);
      onSuccess(this, props);
    }
    else {//プラクティスデータを読み込んでから、Leg表示ルーチンを実行
      this.practice.load({ onSuccess: this.loadLeg, props: props, onError, fromLeg: onSuccess, leg: this });
    }
  }

  mergeMemberData(p) {
    this.practice.mergeMemberData(p);
  }


  loadLeg({ onSuccess, props, leg }) {
    leg.practice.createGraphData(leg.gpses);
    onSuccess(leg, props);
  }

  loadGPS(json) {
    this.gpses = json.map((j) => GPS.fromJSON(j));
  }

  getSeries(dataType) {
    let dataArray = this.gpses.map((m) => m[dataType]);
    return dataArray;
  }

  /**
   * このLegを地図表示する際の中心座標を取得する
   */
  getMapCenter() {
    var lat = 0;
    var lon = 0;
    this.gpses.forEach((gps) => {
      lat += gps.lat;
      lon += gps.lon;
    });
    lat = lat / this.gpses.length;
    lon = lon / this.gpses.length;
    return proj.fromLonLat([lon, lat]);
  }

  setSmoothingRound(dataType) {
    this.gpses = this.gpses.map((g) => {
      g[`_${dataType}`] = Math.round(g[`_${dataType}`]);
      return g;
    });
  }

  setSmoothingAverage(dataType, step) {
    this.gpses = this.gpses.map((g, index) => {
      let val = _.slice(this.gpses, index, index + step).map((g) => g[`_${dataType}`]);
      val = _.sum(val) / step;
      g[`_${dataType}`] = val;
      return g;
    });
  }

  /**
   * 指定された種類のデータが選択範囲内にあるかを判定し、
   * このLegのGPSデータに選択範囲の有効状態を設定します。
   * @param {*} dataType データ種別
   * @param {*} min 選択範囲最小値
   * @param {*} max 選択範囲最大値
   */
  setVisibility(dataType, min, max) {
    this.gpses = this.gpses.map((g) => {
      g.display = (g[dataType] >= min && g[dataType] < max);
      return g;
    });
  }

  /**
  * 経過時間が選択範囲内にあるかを判定し、
  * このLegのGPSデータに選択範囲の有効状態を設定します。
  * @param {*} dataType データ種別
  * @param {*} min 選択範囲最小値
  * @param {*} max 選択範囲最大値
  */
  setVisibilityTimeSeries(min, max) {
    this.gpses = this.gpses.map((g, index) => {
      g.display = (index > min && index < max);
      return g;
    });
  }

  resetVisibility() {
    this.gpses = this.gpses.map((g) => {
      g.display = true;
      return g;
    });
  }

}
