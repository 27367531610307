import React, {Component} from "react";
import {connect} from "react-redux";

import {Link, Redirect} from "react-router-dom";
import {auth, main} from "../actions";

class Races extends Component {

  render() {
   return (
    <div>
      レース練習一覧・・・ただいま工事中です。
    </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated
  };
}

const mapDispatchToProps = dispatch => {
  return {
    test: () => dispatch(main.test()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Races);
