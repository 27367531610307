import './map.css';
import React, { Component } from 'react'
import { Map } from 'react-leaflet'
import {BingLayer} from 'react-leaflet-bing'
import Control from 'react-leaflet-control'
import WindDirectionArrow from '../track/wind-direction-arrow.svg';
import MainPlayer from './mainPlayer'
import FullscreenControl from 'react-leaflet-fullscreen'; 
//import { DriftMarker } from "leaflet-drift-marker"


const bing_key = "AlXkcsmK9XFWxo7VIH9QD1-aXpw4mGNILvxacbsvdfM-z8VvhNLBThCiEVbwdh3p"


export default class MapBrowser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pos:this.props.pos,
      zoom: 15,
    }
  }


  render() {

    const {user} = this.props;
    const unit = user ? user.unit : "knot"

    return (

      <Map ref='map' center={this.props.center} zoom={this.state.zoom} doubleClickZoom={true} attributionControl={false} style={{ height: '40vh' }} >
       <BingLayer  bingkey={bing_key} />

        {Object.keys(this.props.deviceMap).map(key => (
          <MainPlayer unit={unit}  device={ this.props.deviceMap[key] } />
        ))}


        <Control position="topright" >
          <div className='wind-direction-icon'>
            <span className='top-label'>WIND</span>
            <span className='arrow-label'>
              <img alt={'Wind'} className='arrow-img' src={ WindDirectionArrow } style={{ transform: `rotateZ(${this.props.windDirection}deg)` }} />
            </span>
            <span className='bottom-label'>{ this.props.windSpeed }</span>
          </div>
        </Control>
        <FullscreenControl position="topleft" forceSeparateButton={true} />        

      </Map>
    )
  }
}
