import API from '../components/API';
import Practices from '../models/Practices';

export const initDispPlayer = (user) => {

  return (dispatch, getState) => {

    const token = getState().auth.token;

    let onSuccess = function (res) {
      if (res.status === 200) {
        res.data.forEach((m) => {
          if (m.id == user.id) {
            return dispatch({ type: 'SET_TARGET_USER', user: m });
          }
        });

      } else {
        dispatch({ type: "GET_PLAYERS_ERROR", data: res.data });
        throw res.data;
      }
    }

    let onError = function (res) {
      if (res.status >= 500) {
        console.log("Server Error!");
        throw res;
      } else if (res.status >= 400 && res.status < 500) {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      } else {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      }
    }

    API.getMembers({ token: token, onSuccess: onSuccess, onError: onError });
  }
}


export const getPlayers = () => {

  return (dispatch, getState) => {

    const token = getState().auth.token;

    let onSuccess = function (res) {
      if (res.status === 200) {

        return dispatch({ type: 'GET_PLAYERS', players: res.data });

      } else {
        dispatch({ type: "GET_PLAYERS_ERROR", data: res.data });
        throw res.data;
      }
    }

    let onError = function (res) {
      if (res.status >= 500) {
        console.log("Server Error!");
        throw res;
      } else if (res.status >= 400 && res.status < 500) {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      } else {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      }
    }

    API.getMembers({ token: token, onSuccess: onSuccess, onError: onError });
  }
}


//ユーザセレクタで、ユーザを選択した場合
export const handleSelectPlayer = (user) => {

  return (dispatch, getState) => {

    const token = getState().auth.token;

    //比較メンバーをリセット
    dispatch({ type: 'SET_MEMBERS_PRACTICES', practices: undefined, members: undefined });
    dispatch({ type: 'SET_TARGET_USER', user: user, target: undefined });

    let onSuccess = function (res) {
      if (res.status === 200) {

        //Practicesモデルのインスタンスを生成
        let practices = Practices.fromJSON(res.data);

        //初期に表示するPracticeを選別
        let latestPractice = practices.filterByDay(practices.getLatestDay());
        dispatch({ type: 'GET_PRACTICES', practices: practices, practicesOfDay: latestPractice, target: null })
        dispatch({ type: 'SET_PRACTICES_OF_DAY', practicesOfDay: null });

      } else {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      }
    }

    let onError = function (res) {
      if (res.status >= 500) {
        console.log("Server Error!");
        throw res;
      } else if (res.status >= 400 && res.status < 500) {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      } else {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      }
    }

    API.getPractices({ token: token, onSuccess: onSuccess, onError: onError, targetUser: user });
  }
}


export const getDevices = () => {

  return (dispatch, getState) => {

    const token = getState().auth.token;

    let onSuccess = function (res) {
      if (res.status === 200) {

        //ユーザが割り当たっていない場合
        let nouser = {
          last_name: "no user",
          first_name: "no user",
        };

        for (let i in res.data) {
          if (!res.data[i].user) {
            res.data[i].user = nouser;
          }
        }

        //通常のデバイス一覧 PlayserSelector
        return dispatch({ type: 'GET_DEVICES', devices: res.data });

      } else {
        dispatch({ type: "GET_DEVICES_ERROR", data: res.data });
        throw res.data;
      }
    }

    let onError = function (res) {
      if (res.status >= 500) {
        console.log("Server Error!");
        throw res;
      } else if (res.status >= 400 && res.status < 500) {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      } else {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      }
    }

    API.getDevices({ token: token, onSuccess: onSuccess, onError: onError });
  }
}


//関心区間の統計を再計算
export const reCalculationSummary = ({ target, from_idx, to_idx }) => {
  return (dispatch, getState) => {

		//統計値の再計算
		let count = 0;
    var max_spd = 0.0;
    var ave_spd = 0.0;
    var max_2s_spd = 0.0;
    var max_10s_spd = 0.0;
    var max_up_vmg = 0.0;
    var max_down_vmg = 0.0;
    var ave_up_vmg = 0.0;
    var ave_down_vmg = 0.0;

    var ave_pitch = 0.0;
    var ave_roll = 0.0;

    var count_up_vmg = 0;
    var count_down_vmg = 0;
    var count_2s = 0;
    var count_10s = 0;


    let m2s = [];
    let m10s = [];
    let gpses = [];
		for(let i=from_idx; i <= to_idx; i++, count++){

			let gps = target.gpses[i];
			gpses.push(gps);

      if (target.unit == "knot") {
        gps._speed = Math.round((gps._speed / 1.852) * 10) / 10
        gps._vmg = Math.round((gps._vmg / 1.852) * 10) / 10
      }

      if (target.wind_unit == "knot") {
        gps._wind_speed = Math.round((gps._wind_speed / 1.943) * 10) / 10
      }


      //統計算出
      ave_spd = ave_spd + gps._speed;//平均速度
      ave_pitch = ave_pitch + gps.pitch;//平均ピッチ
      ave_roll =  ave_roll  + gps.roll;//平均ロール
      if (max_spd < gps._speed)//最高速度
        max_spd = gps._speed;
      if (max_up_vmg < gps._vmg)
        max_up_vmg = gps._vmg;//最高アップVMG
      if (max_down_vmg > gps._vmg)
        max_down_vmg = gps._vmg;//最高ダウンVMG

      if (gps._vmg > 0.0) {
        ave_up_vmg = ave_up_vmg + gps._vmg;//平均アップVMG
        count_up_vmg = count_up_vmg + 1;
      } else {
        ave_down_vmg = ave_down_vmg + gps._vmg;//平均ダウンVMG
        count_down_vmg = count_down_vmg + 1;
      }
      //max2s max10s
      if (m2s.length < 2)
        m2s.push(gps._speed);
      else {
        let tmp_2s = 0.0;
        m2s.forEach((g) => {
          tmp_2s = tmp_2s + g;
        });
        tmp_2s = tmp_2s / 2;
        if (max_2s_spd < tmp_2s)
          max_2s_spd = tmp_2s       //2秒平均最高速度
        m2s.shift();//古いものを捨てる
        m2s.push(gps._speed);//refleth
      }
      if (m10s.length < 10)
        m10s.push(gps._speed);
      else {
        let tmp_10s = 0.0;
        m10s.forEach((g) => {
          tmp_10s = tmp_10s + g;
        });
        tmp_10s = tmp_10s / 10;
        if (max_10s_spd < tmp_10s)
          max_10s_spd = tmp_10s    //10秒平均最高速度
        m10s.shift();//古いものを捨てる
        m10s.push(gps._speed);//refleth
      }
		}

		//表示値の最新化
		target.set_max_speed(max_spd);
		target.set_max_vmg_up(max_up_vmg);
		target.set_max_vmg_down(max_down_vmg);
		target.set_ave_speed(Math.round(ave_spd / count * 10) / 10);
		target.set_max_2s_spd(Math.round(max_2s_spd * 10) / 10);
		target.set_max_10s_spd(Math.round(max_10s_spd * 10) / 10);
		target.set_ave_vmg_up(Math.round(ave_up_vmg / count_up_vmg * 10) / 10);
		target.set_ave_vmg_down(Math.round(ave_down_vmg / count_down_vmg * 10) / 10);
		target.set_ave_pitch(Math.round(ave_pitch / count * 10) / 10);
		target.set_ave_roll(Math.round(ave_roll / count * 10) / 10);
		target.set_st(target.gpses[from_idx].time);
		target.set_et(target.gpses[to_idx].time);
    dispatch({ type: 'RE_CALC_SUMMARY', target: target });
  }
}


