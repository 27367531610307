import React, { Component } from 'react';
import { connect } from "react-redux";
import { Container, Row, Col } from 'react-bootstrap';
import LeftPanel from '../sidePanel/LeftPanel';
import Loading from '../Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Polar2 from '../polar2/Polar';
import SummarySailing from '../summary/Summary2';
import SummaryRowing from '../summary/SummaryRowing';
import Rankings from '../ranking/Rankings';
import { Tab, Tabs } from 'react-bootstrap';
import Track from '../track/Track';
import { main } from "../../actions";

class Home extends Component {

  static propTypes = {
    // Common
    target: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      openLeftPanel: true,
      memberPolar: null,
      polarSize: 400,
      selectedSpd: 0,
      selectedDir: 0,
      dammy: false,
    };
    this.handleOpenLeftPanel = this.handleOpenLeftPanel.bind(this);
    this.handleCloseLeftPanel = this.handleCloseLeftPanel.bind(this);
    this.handleSelectTabs = this.handleSelectTabs.bind(this);
  }


  componentDidMount() {
  }


  //====================================================================
  //Tabsのイベント
  handleSelectTabs(eventKey) {
    if (eventKey == "track") {
      this.props.reloadMap({ _isMapReload: true })
      this.props.trackMode(true);
    }else{
      this.props.trackMode(false);
    }
    this.setState({ dammy: true });
  }

  handleOpenLeftPanel() {
    this.setState({ openLeftPanel: true });
  }
  handleCloseLeftPanel() {
    this.setState({ openLeftPanel: false });
  }

  render() {

    var leftPanel;
    if (this.state.openLeftPanel) {
      leftPanel = (
        <Col className="no-gutters" lg={2} xs={3} >
          <LeftPanel
            handleCloseLeftPanel={this.handleCloseLeftPanel}
          />
        </Col>
      );
    } else {
      leftPanel = (
        <div align="left">
          <FontAwesomeIcon icon="forward" onClick={this.handleOpenLeftPanel} />
        </div>
      );
    }

    let r2, max_vmg2 = null;
    if (this.props.compare_target) {
      r2 = this.props.compare_target.polar;
      max_vmg2 = this.props.compare_target.max_vmg;
    }

    var polar2 = "Select data.";
    var msg = "Select data.";
    if (this.props.target) {
      msg = ""
    }

    if (this.props.target) {
      polar2 = (
        <Polar2
          r={this.props.target.polar}
          r2={r2}
          max_vmg={this.props.target.max_vmg}
          max_vmg2={max_vmg2}
          unit={this.props.authUser.unit}
        />
      );
    }

    var polar_tab = (
        <Tab eventKey="summary" title="Polar">
          <Container>
            <Row>
              <Col xs={6} >
                {polar2}
                <SummarySailing target={this.props.target}  authUser={this.props.authUser} />
              </Col>
              <Col xs={6} >
                <Container>
                  <Row>
                    <Col xs={12} >
                      <Rankings />
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </Tab>
    )

    var rowing_tab = (
        <Tab eventKey="summary" title="Summary">
          <Container>
            <Row>
              <Col xs={6} >
                {msg}
                <SummaryRowing target={this.props.target}  authUser={this.props.authUser} />
              </Col>
              <Col xs={6} >
                <Container>
                  <Row>
                    <Col xs={12} >
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </Tab>
    )


    return (
      <Row className="no-gutters" >
        {leftPanel}
        <Col className="no-gutters" lg={this.state.openLeftPanel ? 10 : 12} xs={this.state.openLeftPanel ? 9 : 12} >

          <Tabs defaultActiveKey="summary" transition={false} onSelect={this.handleSelectTabs}>
            {  this.props.authUser.organization.event.id == 1 ?  polar_tab : rowing_tab }

            <Tab eventKey="track" title="Track">
              <Track />
            </Tab>
          </Tabs>
        </Col>
      </Row >
    );
  }
}


const mapStateToProps = state => {
  return {
    target: state.Main.target,
    compare_target: state.Main.compare_target,
    authUser: state.auth.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    reloadMap: ({ _isMapReload }) => dispatch(main.reloadMap({ _isMapReload })),
    trackMode: (flg) => dispatch(main.trackMode(flg)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
