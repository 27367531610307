import API from '../components/API';

export const checkAuth = () => {

  return (dispatch, getState) => {

    dispatch({type: "USER_LOADING"});

    const token = getState().auth.token;

    let onSuccess = function(res){

      if (res.status === 200) {
        res.data.token = token;

        dispatch({type: 'USER_LOADED', user: res.data });
        //return res.data;
      }else{
        dispatch({type: "AUTHENTICATION_ERROR", data: res.data});
        throw res.data;
      }
    }

    let onError = function(res){
      if (res.status >= 500) {
        console.log("Server Error!");
        throw res;
      } else if (res.status >= 400 && res.status < 500) {
        dispatch({type: "AUTHENTICATION_ERROR", data: res.data});
        throw res.data;
      }else{
        dispatch({type: "AUTHENTICATION_ERROR", data: res.data});
        throw res.data;
      }
    }

    //ログイン認証
    API.auth(token, onSuccess, onError);
  }
}


export const login = (email, password) => {
  return (dispatch, getState) => {

    let onSuccess = function(res){
        if (res.status === 200) {
					res.data.token = res.data.access;
          dispatch({type: 'LOGIN_SUCCESSFUL', data: res.data });

          //--auto auth---------------------------------------------------
          let onSuccess = function(res2){
            if (res.status === 200) {
              //res2.data.token = res.data.token;
              res2.data.token = res.data.access;
              dispatch({type: 'USER_LOADED', user: res2.data });

            }else{
              dispatch({type: "AUTHENTICATION_ERROR", data: res2.data});
              throw res2.data;
            }
          }
          let onError = function(res2){
            if (res.status >= 500) {
              console.log("Server Error!");
              throw res2;
            } else if (res2.status >= 400 && res2.status < 500) {
              dispatch({type: "AUTHENTICATION_ERROR", data: res2.data});
              throw res2.data;
            }else{
              dispatch({type: "AUTHENTICATION_ERROR", data: res2.data});
              throw res2.data;
            }
          }
          //ログイン認証
          //API.auth(res.data.token, onSuccess, onError);
          API.auth(res.data.access, onSuccess, onError);
          //-----------------------------------------------------

        }
        else {
          console.log("onSuccess:Server Error!");
          throw res;
        }
    };

    let onError = function(res){
        console.log(res);
        if (res.status >= 500) {
          console.log("onError:Server Error!");
          throw res;
        }else if (res.status === 403 || res.status === 401 || res.status === 400) {
          console.log("AUTHENTICATION_ERROR");
          dispatch({ type: "SET_MESSAGE", msgType: 'danger', message: "IDまたはパスワードが違います。" });
          dispatch({type: "AUTHENTICATION_ERROR", data: res.data});
          throw res.data;
        } else {
          console.log("LOGIN_FAILED");
          dispatch({type: "LOGIN_FAILED", data: res.data});
          throw res.data;
        }
   };

    //ログイン認証
    API.login(email, password, onSuccess, onError);
  }
}


export const register = (username, password) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let body = JSON.stringify({username, password});

    return fetch("/api/auth/register/", {headers, body, method: "POST"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          console.log("Server Error!");
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'REGISTRATION_SUCCESSFUL', data: res.data });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: "AUTHENTICATION_ERROR", data: res.data});
          throw res.data;
        } else {
          dispatch({type: "REGISTRATION_FAILED", data: res.data});
          throw res.data;
        }
      })
  }
}


export const logout = () => {
  return (dispatch, getState) => {

    const token = getState().auth.token;

    let onSuccess = function(res){
        if (res.status === 204) {
          dispatch({type: 'LOGOUT_SUCCESSFUL'});
          return res.data;
        }
        else {
          console.log("onSuccess:Server Error!");
          throw res;
        }
    };
    let onError = function(res){
        console.log(res);
        if (res.status >= 500) {
          console.log("onError:Server Error!");
          throw res;
        }else if (res.status === 403 || res.status === 401 || res.status === 400) {
          console.log("AUTHENTICATION_ERROR");
          dispatch({type: "AUTHENTICATION_ERROR", data: res.data});
          throw res.data;
        } else {
          console.log("LOGOUT_FAILED");
          dispatch({type: "LOGOUT_FAILED", data: res.data});
          throw res.data;
        }
    };
    //Logout
    API.logout(token, onSuccess, onError);
 
  }
}
