/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-dropdown';
import API from '../../API';
import _ from 'lodash';
import 'react-dropdown/style.css';
import { connect } from "react-redux";
import { playerSelector, main } from "../../../actions";

class PlayerSelector extends Component {

  static propTypes = {
    user: PropTypes.object,
    targetPlayer: PropTypes.any,
    handleSelectPlayer: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.handleUserSelect = this.handleUserSelect.bind(this);
  }

  handleUserSelect(option) {
    //console.log('You selected ', option.value);
    const player = _.find(this.props.players, (player) => player.id == option.value);
    this.props.handleSelectPlayer(player);
    
    //Ranking update
    this.props.getRankingPractices({ from: null, to: null, targetUser: player, authUser: this.props.user });
  }

  fetchPlayers(){
    this.props.getPlayers();
  }

  value(){
    if (this.props.targetUser){
      return {label: this.props.targetUser.last_name, value: this.props.targetUser.id};
    }else{
      return {label: 'NoUser', value: 'NoUser'};
    }
  }

  options(){
    if(this.props.players){
      let options = this.props.players.map((player) =>{return {label: player.last_name, value: player.id};});
      return options;
    }
  }

  componentDidMount(){
    this.fetchPlayers();
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <Dropdown
        options={this.options()}
        value={this.value()}
        onChange={this.handleUserSelect}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    players: state.PlayerSelector.players,
    user: state.auth.user,
    targetUser: state.PlayerSelector.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getPlayers: () => dispatch(playerSelector.getPlayers()),
    handleSelectPlayer: (player) => dispatch(playerSelector.handleSelectPlayer(player)),
    getRankingPractices: ({ from, to, targetUser, authUser }) => dispatch(main.getRankingPractices({ from, to, targetUser, authUser })),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerSelector);
