import React, { Component } from 'react'
import L from 'leaflet'
import { CircleMarker, Popup, Polyline } from 'react-leaflet'
import RotatedMarker from './rotatedMarker'
import NaviIcon from './navi-arrow.svg'
import WindIcon from './wind-2x.png'


const playerIcon = new L.Icon({
  iconUrl: NaviIcon,
  iconSize: new L.Point(17, 17),
});

const subPlayerIcon = new L.Icon({
  iconUrl: NaviIcon,
  iconSize: new L.Point(15, 15),
});


const windIcon = new L.Icon({
  iconUrl: WindIcon,
  iconSize: new L.Point(20, 20),
});

const mainColor = '#ff5100';
const subColor = '#a9ceec';


export default class MainPlayer extends Component {

  render() {
    const { device } = this.props;
    const CircleM = <CircleMarker center={device.pos} radius={18} />

    const GpsPop = (
      <Popup direction={'top'} offset={[0, -20]} >
        <table>
          <tbody>
            <tr>
              <th>User</th><td>{device.name}</td>
            </tr>
            <tr>
              <th>SPEED</th><td>{device.spd} {this.props.authUser.unit}</td>
            </tr>
            <tr>
              <th>VMG</th><td>{device.vmg} {this.props.authUser.unit}</td>
            </tr>
            <tr>
              <th>ANGLE</th><td>{device.ang} deg</td>
            </tr>
          </tbody>
        </table>
      </Popup>);

    const WindPop = (
      <Popup direction={'top'} offset={[0, -20]} >
        <table>
          <tbody>
            <tr>
              <th>Device</th><td>{device.id}</td>
            </tr>
            <tr>
              <th>SPEED</th><td>{device.spd} {this.props.authUser.unit}</td>
            </tr>
            <tr>
              <th>WIND</th><td>{device.windspd} {this.props.authUser.wind_unit}</td>
            </tr>
            <tr>
              <th>WIND</th><td>{device.winddir} deg</td>
            </tr>
          </tbody>
        </table>
      </Popup>);

    var color;
    device.main ? color = mainColor : color = subColor

    var icon;
    device.main ? icon = playerIcon : icon = subPlayerIcon

    return (
      <span>
        <RotatedMarker
          // if position changes, marker will drift its way to new position
          //position={ deviceMap[deviceId].pos }
          position={device.pos}
          // time in ms that marker will take to reach its destination
          //duration={1000}
          icon={device.wind ? windIcon : icon}
          rotationAngle={device.rotate}
          rotationOrigin={'center'}
        >
          {device.wind ? WindPop : GpsPop}
        </RotatedMarker>
        {device.main && CircleM}
        <Polyline
          positions={device.lines.slice()}
          opacity={0.9}
          color={color}
        />
        <Polyline
          positions={device.all_lines.slice()}
          opacity={0.4}
          color={color}
        />
      </span>
    );
  }

}
