/* eslint-disable no-console */
import * as proj from 'ol/proj';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import {Stroke, Style} from 'ol/style.js';

export default class ErasableTrack {
  constructor(groupId, gpses, color){
    this.groupId = groupId;
    this.color = color;
    let displayStyle = new Style({
      stroke: new Stroke({
        color: color,
        width: 2,
        fill: 'none',
      })
    });
    let hiddenStyle = new Style({
      stroke: new Stroke({
        color: 'grey',
        width: 2,
        fill: 'grey',
      })
    });
    this.tracks = [];
    for (var i = 0; i < gpses.length - 1; i++){
      let gps = gpses[i];
      let nextGPS = gpses[i+1];
      let coordinates = [gps, nextGPS].map((gps) => proj.fromLonLat([gps.lon, gps.lat]));
      let track = new Feature({ geometry: new Polygon([coordinates])});
      if (gps.display){
        track.setStyle(displayStyle);
      }else{
        track.setStyle(hiddenStyle);
      }
      track.groupId = groupId;
      this.tracks.push(track);
    }
  }

  getFeatures(){
    return this.tracks;
  }
}
