import './map.css';
import "leaflet/dist/leaflet.css";
import React, { Component } from 'react'
import { Map, ScaleControl } from 'react-leaflet'
import { BingLayer } from 'react-leaflet-bing'
import Control from 'react-leaflet-control'
import WindDirectionArrow from './wind-direction-arrow.svg';
import MainPlayer from './mainPlayer'
import CalcLine from './calcLine'
import TurnMark from './TurnMark'
import WindLine from './WindLine'
import { connect } from "react-redux";
//import { DriftMarker } from "leaflet-drift-marker"
import { main } from "../../actions";

const bing_key = "AlXkcsmK9XFWxo7VIH9QD1-aXpw4mGNILvxacbsvdfM-z8VvhNLBThCiEVbwdh3p"
class MapBrowser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pos: this.props.pos,
      zoom: this.props.zoom,
      windLine: false,
    }

    this.handleClickWindLine = this.handleClickWindLine.bind(this);
  }


  //グラフ上のマウスオーバーを検知
  handleClickWindLine() {
    this.setState({ windLine: !this.state.windLine });
  }

  //Mapをリロード
  reLoadMap() {
    if (this.refs.map) {
      this.refs.map.leafletElement.invalidateSize()
      this.props.reloadMap({ _isMapReload: false })
    }
  }

  render() {
    if (this.props.isMapReload) {
      this.reLoadMap()
    }
    this.reLoadMap()
    var WindLines = (
      Object.keys(this.props.deviceMap).map((key, index) => (
        <WindLine key={index} lineLength={1000} windDir={this.props.windDirection} pos={this.props.deviceMap[key].pos} />
      )));
    return (
      <div className='map-view'>
        <Map ref='map' center={this.props.center} zoom={this.state.zoom} doubleClickZoom={true} attributionControl={false} style={{ height: '35vh' }}>
          <BingLayer bingkey={bing_key} />
          <ScaleControl className="" position="bottomleft" imperial={false} />

          {Object.keys(this.props.deviceMap).map((key, index) => (
            <MainPlayer key={index} device={this.props.deviceMap[key]} keyNum={key} authUser={this.props.authUser} />
          ))}

          {this.state.windLine ? WindLines : ''}

					<CalcLine
						calcLine={this.props.calcLine}
					/>

          <TurnMark coordinate={this.props.buoy_coordinate} />

          <Control position="topright" >
            <div className='wind-direction-icon'>
              <span className='top-label'>WIND</span>
              <span className='arrow-label'>
                <img alt={'Wind'} className='arrow-img' src={WindDirectionArrow} style={{ transform: `rotateZ(${this.props.windDirection}deg)` }} />
              </span>
              <span className='bottom-label wind-unit'>{this.props.windSpeed} {this.props.authUser.wind_unit}</span>
            </div>
          </Control>


          <Control position="topleft" >
            <input type="checkbox" default={this.state.windLine} onChange={this.handleClickWindLine} />
          </Control>

        </Map>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isMapReload: state.Main.isMapReload,
    authUser: state.auth.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    reloadMap: ({ _isMapReload }) => dispatch(main.reloadMap({ _isMapReload })),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MapBrowser);
