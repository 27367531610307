import _ from 'lodash';
import Leg from './Leg';

/**
 * API `Practice/Practice Collection/List Practice`で返却される legs と
 * API `Practice/PRACTICE URL/PRACTICE URL` で返却される legs から
 * 取得するデータを格納する
 */
export default class Legs {
  constructor(practice, legs) {
    this.practice = practice;
    this._legs = legs;
  }

  static fromJSON(json, practice){
    let legs = json.map((legJSON) => Leg.fromJSON(practice, legJSON));
    legs = _.sortBy(legs, (leg) => leg.started_at);
    return new Legs(practice, legs);
  }

  /**
   * このLegsを地図表示する際の中心座標を取得する
   */
  getMapCenter() {
    let coordinates = this._legs.map((leg) => leg.getMapCenter());
    let averageCoordinates = coordinates.reduce((acc, x) => [acc[0] + x[0], acc[1] + x[1]]).map((coord) => coord / coordinates.length);
    return averageCoordinates;
  }

  getSeries(dataType) {
    return this._legs.map((leg) => leg.getSeries(dataType)).flat();
  }

  first() {
    return this._legs[0];
  }

  last() {
    return this._legs[this.toArray().length - 1];
  }

  findLegById(id) {
    return _.find(this._legs, (leg) => leg.id == id);
  }

  findByUUID(uuid){
    return _.find(this._legs, (l) => l.uuid == uuid);
  }

  concat(other) {
    return this._legs.concat(other.list);
  }

  get(index) {
    return this._legs[index];
  }

  forEach(func) {
    this._legs.forEach(func);
  }

  map(func) {
    return this._legs.map(func);
  }

  isEmpty(){
    return this._legs.length == 0;
  }

  // HACK: Legs 自身に user_name を持ってもよいのではないか。
  get user_name() {
    return this._legs[0].user_name;
  }

  get gpses() {
    return this._legs.map((leg) => leg.gpses).flat();
  }

  set themeColor1(color) {
    this.forEach((leg) => {
      leg.themeColor1 = color;
    });
  }

  get themeColor1() {
    return this._legs[0]._themeColor1;
  }

  set themeColor2(color) {
    this.forEach((leg) => {
      leg.themeColor2 = color;
    });
  }

  get themeColor2() {
    return this._legs[0]._themeColor2;
  }

  get xAxisId() {
    return `x-axis-${this.uuid}`;
  }

  get yAxisId() {
    return `y-axis-${this.uuid}`;
  }

  get startedAt() {
    return _.first(this._legs).startedAt;
  }

  get endedAt() {
    return _.last(this._legs).endedAt;
  }

  setSmoothingRound(dataType) {
    this._legs.forEach((leg) => leg.setSmoothingRound(dataType));
  }

  setSmoothingAverage(dataType, step) {
    this._legs.forEach((leg) => leg.setSmoothingAverage(dataType, step));
  }

  toArray() {
    return this._legs;
  }

  setVisibility(dataType, min, max) {
    this._legs.forEach((leg) => leg.setVisibility(dataType, min, max));
  }

  setVisibilityTimeSeries(min, max) {
    var gpsCount = 0;
    this.forEach((leg) => {
      leg.setVisibilityTimeSeries(min - gpsCount, max - gpsCount);
      gpsCount += leg.gpses.length;
    });
  }

  resetVisibility(){
    this.forEach((leg) => leg.resetVisibility());
  }

}
