import React, { Component } from 'react';
import { connect } from "react-redux";
import './Main.css';
import Table from 'react-bootstrap/Table';
import {to_d} from '../helpers';


export default class SummaryRowing extends Component {


  render() {

    if(!this.props.target)
      return ""

    return (
      <span>
        <Table striped bordered hover variant="dark">
          <tbody>
            <tr>
              <td className="GridData2" ><div className="Unit2">Time</div><span className="summary">  {to_d(this.props.target.duration_seconds)}</span></td>
              <td className="GridData2" ><div className="Unit2">Split/500m</div><span className="summary">  {to_d(this.props.target.pace500m_seconds)}</span></td>
              <td className="GridData2" ><div className="Unit2">Split/100m</div><span className="summary">  {to_d(this.props.target.pace100m_seconds)}</span></td>
              <td className="GridData2" ><div className="Unit2">Split/1000m</div><span className="summary">  {to_d(this.props.target.pace1000m_seconds)}</span></td>
            </tr>
            <tr>
              <td className="GridData2" ><div className="Unit2">Stroke/min</div><span className="summary"> {this.props.target.ave_storke_per_minutes}</span></td>
              <td className="GridData2" ><div className="Unit2">Stroke/500m</div><span className="summary"> {this.props.target.stroke500m}</span></td>
              <td className="GridData2" ><div className="Unit2">Stroke/100m</div><span className="summary"> {this.props.target.stroke100m}</span></td>
              <td className="GridData2" ><div className="Unit2">Stroke/1000m</div><span className="summary"> {this.props.target.stroke1000m}</span></td>
            </tr>
            <tr>
              <td className="GridData2" ><div className="Unit2">All Stroke</div><span className="summary"> {this.props.target.stroke}</span></td>
              <td className="GridData2" ><div className="Unit2">Distance</div><span className="summary"> {this.props.target.distance}</span><span className="Unit2">m</span></td>
              <td className="GridData2" ><div className="Unit2">ave SPD</div><span className="summary">  {this.props.target.ave_speed}</span><span className="Unit2">{this.props.authUser.unit}</span></td>
              <td className="GridData2" ><div className="Unit2">max 10s SPD</div><span className="summary">  {this.props.target.max_10s_spd}</span><span className="Unit2">{this.props.authUser.unit}</span></td>
            </tr>
          </tbody>
        </Table>
      </span>
    );
  };
}
