//Edit
import API from '../components/API';

export const getUsers = () => {

  return (dispatch, getState) => {

    const token = getState().auth.token;

    let onSuccess = function (res) {
      if (res.status === 200) {

        //PagiList$BMQ%G%P%$%90lMw$N@8@.(B
        let users = [];
        res.data.forEach((user) => {
          let row = {
            id        : user.id,
            first_name: user.first_name,
            last_name : user.last_name,
            email     : user.email,
            searchstr: user.first_name + user.last_name + user.email,
          }
          users.push(row);
        });

        dispatch({ type: 'GET_USERS', users: users });

      } else {
        dispatch({ type: "GET_USERS_ERROR", data: res.data });
        throw res.data;
      }
    }

    let onError = function (res) {
      if (res.status >= 500) {
        console.log("Server Error!");
        throw res;
      } else if (res.status >= 400 && res.status < 500) {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      } else {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      }
    }

    API.getMembers({ token: token, onSuccess: onSuccess, onError: onError });
  }
}



export const getUser = (id) => {

  return (dispatch, getState) => {

    const token = getState().auth.token;

    let onSuccess = function (res) {
      if (res.status === 200) {
        dispatch({ type: 'GET_USER', user: res.data });

      } else {
        dispatch({ type: "GET_USERS_ERROR", data: res.data });
        throw res.data;
      }
    }

    let onError = function (res) {
      dispatch({ type: "SET_MESSAGE", msgType: 'danger', message: "Server access error." });

      if (res.status >= 500) {
        console.log("Server Error!");
        throw res;
      } else if (res.status >= 400 && res.status < 500) {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res;
      } else {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res;
      }
    }

    API.getUser({ token: token, user_id: id, onSuccess: onSuccess, onError: onError });
  }
}


export const onChangeValue = (user, id, value) => {

  return (dispatch, getState) => {
    let new_user = {
          id           :user.id, 
          organization :user.organization, 
          email        :user.email, 
          first_name   :user.first_name, 
          last_name    :user.last_name, 
        };
    new_user[id] = value;
    dispatch({ type: 'SET_USER', user:new_user});
  }
}


export const updateUser = (user, history) => {

  return (dispatch, getState) => {

    dispatch({type: "USER_UPDATING", loading:true });

    const token = getState().auth.token;

    let onSuccess = function ({res, history}) {
      dispatch({type: "USER_UPDATING", loading:false });
      if (res.status === 200) {
        dispatch({ type: "SET_MESSAGE", msgType: 'info', message: "Updated." });
        dispatch({ type: "SET_USERS", users: undefined });
        history.push('/users');
      } else {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      }
    }

    let onError = function (res) {
      dispatch({type: "USER_UPDATING", loading:false });
      dispatch({ type: "SET_MESSAGE", msgType: 'danger', message: "Server access error." });
      if (res.status >= 500) {
        console.log("Server Error!");
        dispatch({ type: "SET_MESSAGE", msgType: 'error', message: res.data });
        throw res;
      } else if (res.status >= 400 && res.status < 500) {
        dispatch({ type: "SET_MESSAGE", msgType: 'error', message: res.data });
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      } else {
        dispatch({ type: "SET_MESSAGE", msgType: 'error', message: res.data });
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      }
    }

    API.putUser({ token: token, user: user, onSuccess: onSuccess, onError: onError, history: history });
  }
}


export const addUser = (user, history) => {

  return (dispatch, getState) => {

    dispatch({type: "USER_UPDATING", loading:true });

    const token = getState().auth.token;

    let onSuccess = function ({res, history}) {
      dispatch({type: "USER_UPDATING", loading:false });
      if (res.status === 200) {
        dispatch({ type: "SET_MESSAGE", msgType: 'info', message: "User created.." });
        dispatch({ type: "SET_USERS", users: undefined });
        history.push('/users');
      } else {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      }
    }

    let onError = function (res) {
      dispatch({type: "USER_UPDATING", loading:false });
      dispatch({ type: "SET_MESSAGE", msgType: 'danger', message: "Server access error." });
      if (res.status >= 500) {
        console.log("Server Error!");
        dispatch({ type: "SET_MESSAGE", msgType: 'error', message: res.data });
        throw res;
      } else if (res.status >= 400 && res.status < 500) {
        dispatch({ type: "SET_MESSAGE", msgType: 'error', message: res.data });
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      } else {
        dispatch({ type: "SET_MESSAGE", msgType: 'error', message: res.data });
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      }
    }

    API.addUser({ token: token, new_user: user, onSuccess: onSuccess, onError: onError, history: history });
  }
}


export const deleteUser = (id, history) => {

  return (dispatch, getState) => {

    dispatch({ type: "SET_USERS", users: undefined });

    const token = getState().auth.token;

    let onSuccess = function ({res, history}) {
      if (res.status === 200) {
        //PagiList$BMQ%G%P%$%90lMw$N@8@.(B
        let users = [];
        res.data.forEach((user) => {
          let row = {
            id        : user.id,
            first_name: user.first_name,
            last_name : user.last_name,
            email     : user.email,
            searchstr: user.first_name + user.last_name + user.email,
          }
          users.push(row);
        });

        dispatch({ type: 'GET_USERS', users: users });
        //history.push('/users');
      } else {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      }
    }

    let onError = function (res) {
      dispatch({ type: "SET_MESSAGE", msgType: 'danger', message: "Server access error." });
      if (res.status >= 500) {
        console.log("Server Error!");
        dispatch({ type: "SET_MESSAGE", msgType: 'error', message: res.data });
        throw res;
      } else if (res.status >= 400 && res.status < 500) {
        dispatch({ type: "SET_MESSAGE", msgType: 'error', message: res.data });
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      } else {
        dispatch({ type: "SET_MESSAGE", msgType: 'error', message: res.data });
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      }
    }

    API.deleteUser({ token: token, user_id: id, onSuccess: onSuccess, onError: onError, history:history });
  }
}
