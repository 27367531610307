import React, {Component} from "react";
import { withRouter } from 'react-router';
import {Link} from 'react-router-dom';
import 'bootstrap/scss/bootstrap.scss';
import 'font-awesome/css/font-awesome.min.css';


export function onEdit(rowID, isOpen) {
    //console.log('onEdit handler');
    //console.log('id:', rowID);
    //EditModal$B$NI=<((B
}



export const ActionsComponent = ({ row, onAction }) => {
  return (
    <span className="action-icon">
      <Link to={'/device_edit/'+row.id}>
      <button
        type="button"
        className="btn btn-link"
        //onClick={e => onAction(e, 'EDIT_ITEM')}
        data-html="true"
        data-toggle="tooltip"
        title="Edit Item"
      >
        <i className="fa fa-pencil" />
      </button>
      </Link>
    </span>
  );
}

export default withRouter(onEdit)
