import React, { Component } from 'react';
import { connect } from "react-redux";

class Loading extends Component {
  render() {
    var loading;
    if(this.props.loading)
      loading=(<em>Loading...</em>);
    else
      loading= (<div>No practice data...</div>);

    return (
      loading
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.Main.loading,
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Loading);
