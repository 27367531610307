const initialState = {
   users: null,
   user: null,
   errors: [],
   loading: false,
};


export default function User(state = initialState, action) {

   switch (action.type) {

      case 'USER_UPDATING':
        return { ...state, loading:action.loading, };

      case 'GET_USERS':
        return { ...state, users: action.users, msgType: '', message: '', };

      case 'GET_USER':
        return { ...state, user: action.user, msgType: '', message: '', };

      case 'SET_USER':
        return { ...state, user: action.user, msgType: '', message: '', };

      case 'SET_USERS':
        console.log(action.users);
        return { ...state, users: action.users, msgType: '', message: '', };

      case 'GET_USERS_ERROR':
         return { ...state, users: undefined, msgType: 'ERROR', message: 'ユーザロードに失敗しました。', };

      default:
         return state;
   }
}
