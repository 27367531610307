const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  email: "",
  isLoading: true,
  user: null,
  errors: {},
};


export default function auth(state=initialState, action) {

  switch (action.type) {

    case 'USER_LOADING':
      return {...state, isLoading: true, msgType: '', message: '', };

    case 'USER_LOADED':
      return {...state, isAuthenticated: true, isLoading: false, user: action.user, email: action.user.email,  msgType: '', message: '', };

    case 'LOGIN_SUCCESSFUL':
      localStorage.setItem("token", action.data.token);
      return {...state, ...action.data, isLoading: false, errors: null, msgType: '', message: '', };

    case 'REGISTRATION_SUCCESSFUL':
      localStorage.setItem("token", action.data.token);
      return {...state, ...action.data, isAuthenticated: true, isLoading: false, errors: null};

    case 'AUTHENTICATION_ERROR':
      return {...state, isAuthenticated: false, isLoading: false,  };

    case 'LOGIN_FAILED':
    case 'LOGOUT_SUCCESSFUL':
      localStorage.removeItem("token");
      return {...state, errors: action.data, token: null, user: null,
              isAuthenticated: false, isLoading: false, msgType:'info', message:'ログアウトしました',};


    case 'SET_MESSAGE':
      return {...state, msgType: action.msgType, message:action.message};


    default:
      return state;
  }
}
