import React, { Component } from 'react';
import PolarBase from './polar_base';
import Table from 'react-bootstrap/Table';
import SpdIcon from './icon-data-list-1.svg';
export default class Polar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      initialized: false,
    };
  }

  componentDidMount() {
    let args = {
      elem: '#practice_polar',
      //title: 'YOUR TOTAL PRACTICE',
      labels: ['Windward', 'Port', 'Leeward', 'Starboad'],
      circlSize: 300, // default: 228
      ticksSuffix: this.props.unit
    };
    PolarBase.init(args);
    this.setState({ initialized: true })
  }

  //====================================================================
  //====================================================================

  render() {

    const { max_vmg, max_vmg2 } = this.props;

    if (this.state.initialized) {
      let max_vmgs = [];
      let max_spds = [];
      let polar_data = [];
      for (let i = 0; i < 360; i++) {
        let val = 0
        let spd = 0
        if (i == max_vmg.upper_port.direction)
          val = max_vmg.upper_port.spd
        if (i == max_vmg.down_port.direction)
          val = max_vmg.down_port.spd
        if (i == max_vmg.upper_starboad.direction)
          val = max_vmg.upper_starboad.spd
        if (i == max_vmg.down_starboad.direction)
          val = max_vmg.down_starboad.spd
        if (i == max_vmg.max_port.direction)
          spd = max_vmg.max_port.spd
        if (i == max_vmg.max_starboad.direction)
          spd = max_vmg.max_starboad.spd
        max_vmgs.push(val);
        max_spds.push(spd);
      }

      polar_data.push(
        {
          r: this.props.r, // Required
          color: '#ff5c33', // Required
          label: 'Main data',
          highlights: stats_to_highlight(max_vmg)
        }
      );
      if (this.props.r2) {//compare data
        polar_data.push(
          {
            r: this.props.r2, // Required
            color: '#726d6d',
            label: 'Compare data',
            highlights: stats_to_highlight(max_vmg2)
          }
        );
      }

      PolarBase.draw({
        data: polar_data,
        max_vmgs: {
          r: max_vmgs,
          color: '#33ffbb',
          label: 'Max VMGs',
        },
        max_spds: {
          r: max_spds,
          color: '#5533ff',
          label: 'Max SPDs',
        }
      });
    }

    //const { max_vmg } = this.props;

    const space = (<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>);

    return (
      <div>
        <div id="practice_polar" />
        <Table striped bordered hover variant="dark">
          <tbody>
            <tr>
              <td className="GridData2" >
                <div className="Unit2">STARBOAD UP VMG</div>
                <span className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14.28" height="14.45" viewBox="0 0 14.28 14.45">
                    <path d="M18.12,573.51A14.28,14.28,0,0,1,32.4,559.24v14.45Z" transform="translate(-18.12 -559.24)" />
                  </svg>
                </span>
                <span className="summary">  {max_vmg.upper_starboad.spd}</span><span className="Unit2">{this.props.unit}</span>
                {space}
                <span className="summary"> {max_vmg.upper_starboad.direction}</span><span className="Unit2">{"deg"}</span>
              </td>
              <td className="GridData2" >
                <div className="Unit2">PORT UP VMG</div>
                <span className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14.45" height="14.28" viewBox="0 0 14.45 14.28">
                    <path d="M18.29,681.31a14.28,14.28,0,0,1,14.28,14.28H18.12Z" transform="translate(-18.12 -681.31)" />
                  </svg>
                </span>
                <span className="summary"> {max_vmg.upper_port.spd}</span><span className="Unit2">{this.props.unit}</span>
                {space}
                <span className="summary"> {max_vmg.upper_port.direction}</span><span className="Unit2">{"deg"}</span>
              </td>
            </tr>
            <tr>
              <td className="GridData2" >
                <div className="Unit2">STARBOAD DOWN VMG</div>
                <span className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14.45" height="14.28" viewBox="0 0 14.45 14.28">
                    <path d="M32.78,634.05A14.28,14.28,0,0,1,18.5,619.78H33Z" transform="translate(-18.5 -619.78)" />
                  </svg>
                </span>
                <span className="summary">  {max_vmg.down_starboad.spd}</span><span className="Unit2">{this.props.unit}</span>
                {space}
                <span className="summary"> {max_vmg.down_starboad.direction}</span><span className="Unit2">{"deg"}</span>
              </td>
              <td className="GridData2" >
                <div className="Unit2">PORT DOWN VMG</div>
                <span className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14.28" height="14.45" viewBox="0 0 14.28 14.45">
                    <path d="M32.4,742A14.28,14.28,0,0,1,18.12,756.3V741.85Z" transform="translate(-18.12 -741.85)" />
                  </svg>
                </span>
                <span className="summary">  {max_vmg.down_port.spd}</span><span className="Unit2">{this.props.unit}</span>
                {space}
                <span className="summary"> {max_vmg.down_port.direction}</span><span className="Unit2">{"deg"}</span>
              </td>
            </tr>
            <tr>
              <td className="GridData2" >
                <div className="Unit2">STARBOAD MAX SPD</div>
                <img src={SpdIcon} style={{ transform: 'rotate(270deg)' }} />
                <span className="summary">  {max_vmg.max_starboad.spd}</span><span className="Unit2">{this.props.unit}</span>
                {space}
                <span className="summary">  {max_vmg.max_starboad.direction}</span><span className="Unit2">{"deg"}</span>
              </td>
              <td className="GridData2" >
                <div className="Unit2">PORT MAX SPD</div>
                <img src={SpdIcon} style={{ transform: 'rotate(90deg)' }} />
                <span className="summary">  {max_vmg.max_port.spd}</span><span className="Unit2">{this.props.unit}</span>
                {space}
                <span className="summary">  {max_vmg.max_port.direction}</span><span className="Unit2">{"deg"}</span>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>

    );
  }
}

var stats_to_highlight = (stats) => {
  return [
    stats.upper_port.direction,
    stats.down_port.direction,
    stats.upper_starboad.direction,
    stats.down_starboad.direction,
  ]
}
