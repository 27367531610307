import React, {Component} from "react";
import { user } from "../../actions";
import { connect } from "react-redux";
import { ListGroup, Button, Form } from 'react-bootstrap'
import { Card, CardHeader, CardBody } from 'reactstrap';
import Validation from '../Validation';

class UserEdit extends Component {

  constructor(props) {
    super(props);
    const {params} = this.props.match
    const id = parseInt(params.id, 10)
    this.state = {
      user_id: id,
      first_name: "",
      last_name: "",
      email: "",
      loading: false,
      message: {},
      info: "",
    }
  }


  componentDidMount() {

    //ユーザ情報の再読み込み
    this.props.getUser(this.state.user_id);

    //関数バインド
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit = e => {
    e.preventDefault();
    //Action実行
    this.props.updateUser(this.props.user, this.props.history);
  }


  canSubmit = (): boolean => {

    const { message } = this.state;
    const { loading } = this.props;

    let info = {
      email:      this.props.user.email,
      first_name: this.props.user.first_name,
      last_name:  this.props.user.last_name,
    };

    const validInfo =
      Object.values(info).filter(value => {
        return value === '';
      }).length === 0;

    const validMessage =
      Object.values(message).filter(value => {
        return value !== '';
      }).length === 0;

    return validInfo && validMessage && !loading;
  }


  onChange =(e) =>{
    const key = e.target.id;
    const value = e.target.value;
    const { message } = this.state;

    // Realtime check
    this.setState({
      message: {
        ...message,
        [key]: Validation.formValidate(key, value)
      }
    });

    this.props.onChangeValue(this.props.user, e.target.id, e.target.value);
  }

  render(){
    const { user, } = this.props;
    const { message, } = this.state;

    if( !this.props.user )
      return <p>Loading...</p>;

    return (
      <div className="container">
          <Card>
            <CardHeader>
                <b> {"Edit User"} </b>
            </CardHeader>
            <CardBody>
              <Form onSubmit={this.onSubmit}>
                <fieldset>
                  {this.props.errors.length > 0 && (
                    <ul>
                      {this.props.errors.map(error => (
                        <li key={error.field}>{error.message}</li>
                      ))}
                    </ul>
                  )}

                  <Form.Group>
                    <Form.Label>組織情報</Form.Label> 
                    <Form.Control disabled id="organization" type="text" value={user.organization.name}/>
                      <Form.Text className="text-muted">
                        組織情報は変更できません.
                      </Form.Text>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>姓</Form.Label> 
                    <Form.Control id="last_name" type="text" value={ user.last_name } onChange={this.onChange}/>
                    {message.last_name && (<p style={{ color: 'red', fontSize: 8 }}>{message.last_name}</p>)}
                    <Form.Label>名</Form.Label> 
                    <Form.Control id="first_name" type="text" value={ user.first_name } onChange={this.onChange}/>
                    {message.first_name && (<p style={{ color: 'red', fontSize: 8 }}>{message.first_name}</p>)}
                    <Form.Label>メールアドレス</Form.Label> 
                    <Form.Control id="email" type="text" value={ user.email } onChange={this.onChange}/>
                    {message.email && (<p style={{ color: 'red', fontSize: 8 }}>{message.email}</p>)}
                  </Form.Group>

                  <Form.Group>
                    <Button variant="primary" disabled={!this.canSubmit()} size="lg" onClick={this.onSubmit} >　OK　</Button>{'  '}
                    <Button variant="secondary" size="lg" href="/users"  >Cancel </Button>
                  </Form.Group>

               </fieldset>
              </Form>
          </CardBody>
        </Card>
      </div>



    );
  }
}

const mapStateToProps = state => {
  return {
    user   : state.User.user,
    errors : state.User.errors,
    loading: state.User.loading,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUser: (id) => dispatch(user.getUser(id)),
    onChangeValue: (u, id, value) => dispatch(user.onChangeValue(u, id, value)),
    updateUser: (d, history) => dispatch(user.updateUser(d, history)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserEdit);
