import React, { Component } from 'react';
import {Route, Switch, BrowserRouter, Redirect} from 'react-router-dom';
import {Alert} from 'react-bootstrap';
import "./App.css";

import { Provider, connect } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import {auth} from "./actions";
import StsApp from "./reducers";

import Login from "./components/Login";
import Register from "./components/Register";
import Home from "./components/home/Home";
import Races from "./components/Races";
import Devices from "./components/devices/Devices";
import DeviceEdit from "./components/devices/Edit";
import Users from "./components/users/Users";
import UserEdit from "./components/users/Edit";
import UserAdd from "./components/users/Add";
import Agreements from "./components/agreements/Agreements";
import Passchange from "./components/Passchange";
import RealtimeSailing from "./components/realtime_training/main";
import RealtimeRowing from "./components/realtime_training/rowing";
import NotFound from "./NotFound";
import Header from "./Header";
import Footer from "./Footer";
import Practices from "./components/Practices";
import Organization from "./components/organization/Organization";
import UploadData from "./components/UploadData";


import { library } from '@fortawesome/fontawesome-svg-core'; //fontawesomeのコアファイル
import { fab } from '@fortawesome/free-brands-svg-icons'; //fontawesomeのbrandアイコンのインポート
import { fas } from '@fortawesome/free-solid-svg-icons'; //fontawesomeのsolidアイコンのインポート
import { far } from '@fortawesome/free-regular-svg-icons'; //fontawesomeのregularアイコンのインポート
library.add(fab, fas, far); //他のコンポーネントから簡単に呼び出せるようにするための登録処理？
//library.add(fas); //他のコンポーネントから簡単に呼び出せるようにするための登録処理？


let store = createStore(StsApp, applyMiddleware(thunk));


class RootContainerComponent extends Component {


  componentDidMount() {
    this.props.checkAuth();
  }


  PrivateRoute = ({component: ChildComponent, ...rest}) => {
    return <Route {...rest} render={props => {
      //loading
      if (this.props.auth.isLoading) {
        return <em>Loading...</em>;
      } else if (!this.props.auth.isAuthenticated) {
      //token not has
      //} else if (!this.props.auth.token) {
        console.log("You need login");
        return <Redirect to="/login" />;
      }
      //token OK
      else {
        //console.log("You are autholized.");
        return <ChildComponent {...props} />
      }
    }} />
  }

  render() {
    let {PrivateRoute} = this;
    let realtime;
    if(this.props.auth.user){
      realtime = this.props.auth.user.organization.event.id == 1 ? RealtimeSailing : RealtimeRowing
    }
    return (
        <BrowserRouter>
          <Header />
          <Alert id={ 'alertMsg' } variant={this.props.auth.msgType} >
            {this.props.auth.message}
          </Alert>
          <Switch>
            <Route exact path="/register" component={Register} />
            <Route exact path="/login" component={Login} />
            <PrivateRoute exact path="/" component={Home} />
            <PrivateRoute exact path="/realtime_monitor" component={realtime} />
            <PrivateRoute exact path="/races" component={Races} />
            <PrivateRoute exact path="/devices" component={Devices} />
            <PrivateRoute exact path="/device_edit/:id" component={DeviceEdit} />
            <PrivateRoute exact path="/users" component={Users} />
            <PrivateRoute exact path="/user_edit/:id" component={UserEdit} />
            <PrivateRoute exact path="/users/new" component={UserAdd} />
            <PrivateRoute exact path="/passchange" component={Passchange} />
            <PrivateRoute exact path="/practices" component={Practices} />
            <PrivateRoute exact path="/agreements" component={Agreements} />
            <PrivateRoute exact path="/location_update" component={Organization} />
            <PrivateRoute exact path="/upload_data" component={UploadData} />
            <Route component={NotFound} />
          </Switch>

          <Footer />
        </BrowserRouter>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    checkAuth: () => {
      return dispatch(auth.checkAuth());
    }
  }
}

let RootContainer = connect(mapStateToProps, mapDispatchToProps)(RootContainerComponent);



export default class App extends Component {
  render() {
    return (
          <Provider store={store}>
            <RootContainer />
          </Provider>
    );
  }
}
