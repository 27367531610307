/* eslint-disable no-console */
import {Map as OLM} from 'ol';
import {transform} from 'ol/proj';
import Point from 'ol/geom/Point';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector.js';
import Vector from 'ol/source/Vector.js';
import LVector from 'ol/layer/Vector.js';
import {defaults as defaultControls, ScaleLine} from 'ol/control.js';
import 'ol/ol.css';
import {
  Fill,
  Stroke,
  Style,
  Icon,
} from 'ol/style.js';
import TurnMarkIcon from './turnmark.png';
import BingMaps from 'ol/source/BingMaps.js';

const Config = {
  // mapCenter: proj.fromLonLat([130.4087407038, 33.0917227147]),
  lon: 130.4087407038,
  lat: 33.0917227147,
  // rotation: -0.3644834396924,
  zoom: 15.0,
  // minZoom:16.0,
  maxZoom:20.9,
  styles: {
    poolSquare: new Style({
      stroke: new Stroke({
        color: 'blue',
        width: 3
      }),
      fill: new Fill({
        color: 'rgba(0, 247, 255, 0.6)'
      }),
      zIndex: 0
    }),
    mark: new Style({image: new Icon({src: TurnMarkIcon,scale: 0.03,})}),
    point: (icon) => {
      return new Style({image: new Icon({src: icon,scale: 0.05,})});
    },
    outPoolSquare: new Style({
      stroke: new Stroke({
        color: 'blue',
        width: 3
      }),
      fill: new Fill({
        color: 'rgba(0, 0, 0, 0.3)'
      }),
      zIndex:-1
    }),
  },
  grid: {
    geometryLabel: 'grid',
    distanceFromCenterTo: {
      North:133,
      South:74,
      East:239,
      West:288
    },
    poolsize: {
      Vertical:207,
      Horizontal:527
    },
    alpha: {
      North:21,
      South:201,
      East:111,
      West:291
    },
    gridScale: {
      16:50,
      17:25,
      18:15,
      19:10,
      20:5
    },
    poolsideMargin: 100,
    styles: {
      dot_true:  new Style({
        stroke: new Stroke({
          color: 'rgba(0, 0, 0, 1)',
          width: 0.5,
          lineDash: [0,3]
        }),
        zIndex: 2
      }),
      dot_false: new Style({
        stroke: new Stroke({
          color: 'rgba(0, 0, 0, 0)',
          width: 0.5,
          lineDash: [0,3]
        })
      }),
    }
  }
};

class Grid {
  constructor(vectorSource) {
    this.vectorSource = vectorSource;
  }

  setZoomLevel(zoom){
    var features = this.vectorSource.getFeatures();
    features.forEach((feature) => {
      if (feature.values_.name == Config.grid.geometryLabel){
        if(feature.values_.zoomLevel == Math.floor(zoom)){
          feature.setStyle(Config.grid.styles.dot_true);
        }else{
          feature.setStyle(Config.grid.styles.dot_false);
        }
      }
    });
  }

}
export default class BasicMap {
  constructor(options) {
    var buoy_coordinate = options.buoy_coordinate;
    this.view = new View({
      projection: 'EPSG:3857',
      center: options.mapCenter,
      rotation: Config.rotation,
      zoom: Config.zoom,
      minZoom: Config.minZoom,
      maxZoom: Config.maxZoom,
    });
    // Grid change callback
    this.view.on('propertychange', (e) => {
      switch (e.key) {
      case 'resolution':
        this.adjustGrid(e.target.getZoom());
        if (options.onZoomLevelChanged){
          options.onZoomLevelChanged(this.getScale());
        }
        break;
      }
    });
    this.tileLayer =  new TileLayer({
      source: new BingMaps({
        key: 'AlXkcsmK9XFWxo7VIH9QD1-aXpw4mGNILvxacbsvdfM-z8VvhNLBThCiEVbwdh3p',
        imagerySet: 'Aerial'
      })
    });
    this.vectorSource = new Vector({feature: []});
    this.vectorLayer = new VectorLayer({source: this.vectorSource});

    //--- marker setting ------------------------
    //--- marker setting ------------------------
    // デフォルトのスタイル
    var markerStyleDefault = new Style({
      image: new Icon(/** @type {olx.style.IconOptions} */ {
        anchor: [0.5, 1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        opacity: 0.75,
        src: 'marker-red.png'
      })
    });
    // FeatureA に適用するスタイル
    var markerStyleA = new Style({
      image: new Icon(/** @type {olx.style.IconOptions} */ {
        opacity: 0.75,
        scale: 0.03,
        src: TurnMarkIcon
      })
    });
    var marker = new Feature({
      geometry: new Point( transform([buoy_coordinate[0],buoy_coordinate[1]], 'EPSG:4326', 'EPSG:3857') )
    });
    marker.setStyle(markerStyleA);

   // Feature 一覧 を Source にセット
    var markerSource = new Vector({
      features: [marker]
    });
    // Source を Layer にセット
    this.markerLayer = new LVector({
      source: markerSource,
    });

    this.map = new OLM({
      target: options.bindTo,
      controls: defaultControls({
        attribution: false,
        attributionOptions: { collapsible: false },
        zoom: false,
        rotate: false,
      }).extend([new ScaleLine()]),
      layers: [this.tileLayer, this.vectorLayer, this.markerLayer],
      view: this.view,
    });
    this.grid = new Grid(this.vectorSource);
    // this.grid.draw()
    this.grid.setZoomLevel(this.view.getZoom());

    this.map.on('click', function(evt) {
      var coordinate = evt.coordinate;
      var outstr = transform(coordinate, 'EPSG:3857', 'EPSG:4326');
      //上位メソッドを呼んでテキストボックスを修正
      options.handleClickOnMap(outstr)

      //マークを打ち
      marker.setGeometry(new Point(coordinate));

    });
  }

  addFeature(feature){
    this.vectorSource.addFeature(feature);
  }

  addFeatures(features){
    if (features.constructor.name != 'Array'){
      throw new Error('Invalid argument addFeatures');
    }
    this.vectorSource.addFeatures(features);
  }

  getScale(){
    let zoom = Math.floor(this.getZoomLevel());
    return Config.grid.gridScale[zoom];
  }

  getZoomLevel(){
    return this.view.getZoom();
  }

  removeFeatureByIDs(ids){
    ids.forEach((id) => {
      this.removeFeatureByID(id);
    });
  }

  removeFeatureByID(id){
    let feature = this.vectorSource.getFeatureById(id);
    if (feature != null){
      this.vectorSource.removeFeature(feature);
    }
  }

  removeFeatures(features){
    features.forEach((feature) => {
      this.vectorSource.removeFeature(feature);
    });
  }

  removeFeatureByGroupID(groupId){
    var targets = [];
    this.vectorSource.forEachFeature(function (feature) {
      if (feature.groupId == groupId){
        targets.push(feature);
      }
    });
    this.removeFeatures(targets);
  }

  adjustGrid(zoom){
    this.grid.setZoomLevel(zoom);
  }

  update(mapCenter){
    this.view.setCenter(mapCenter);
  }
}
