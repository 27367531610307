import Moment from 'moment';
import CONFIG from '../Setting';
/**
 * API `Practice URL` で返却される legs[].data[] １件分用クラス
 */
export default class GPS {
  constructor(time, direction, lat, lon, wind_direction, wind_speed, speed, distance, rudder_force, vmg, pitch, roll, yaw) {
    this.time = time;
    this.direction = direction;
    this.lat = lat;
    this.lon = lon;
    this._wind_direction = wind_direction;
    this._wind_speed = wind_speed;
    this._speed = speed;
    this._distance = distance;
    this._rudder_force = rudder_force;
    this._vmg = vmg;                     // オリジナル VMG
    this._pitch = pitch;
    this._roll = roll;
    this._yaw = yaw;
    // グラフ、航跡マップ描画時に有効データ（カラーリングした）として扱うかのフラグ
    this.display = true;
  }

  static fromJSON(json) {
    return new GPS(
      Moment(json.time),
      json.direction,
      json.lat,
      json.lon,
      json.wind_direction,
      json.wind_speed,
      json.speed,
      json.distance,
      json.rudder_force,
      json.vmg,
      json.pitch,
      json.roll,
      json.yaw,
    );
  }

  get wind_direction() {
    return this._wind_direction;
  }

  get rudder_force() {
    return this._rudder_force;
  }

  get pitch() {
    return this._pitch;
  }

  get roll() {
    return this._roll;
  }

  get yaw() {
    return this._yaw;
  }

  get speed() {
    if (CONFIG.speed_unit == 'knot') {
      return Math.round((this._speed / 1.852) * 10) / 10;
    } else {
      return this._speed;
    }
  }

  get distance() {
    return this._distance;
  }

  get wind_speed() {
    return this._wind_speed;
  }

  get vmg() {
    if (CONFIG.speed_unit == 'knot') {
      return Math.round((this._vmg / 1.852) * 10) / 10;
    } else {
      return this._vmg;
    }
  }

  get windward_vmg() {
    return this.vmg;
  }

  get downwind_vmg() {
    return -this.vmg;
  }
}
