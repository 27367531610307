import React, {Component} from "react";
import {connect} from "react-redux";
import PagiList from "../list/PagiList";
import {Link, Redirect} from "react-router-dom";
import { user } from "../../actions";
import {ActionsComponent} from './ActionsComponent';
import API from '../API';

const columuns = [
  {dataField:'last_name',text:'姓'},
  {dataField:'first_name',text:'名'},
  {dataField:'email',text:'登録メールアドレス'},
]; 


var props;

class Users extends Component {

  componentDidMount() {

    //ログイン中ユーザの所属する組織のユーザ一覧を取得
    this.props.getUsers();
    props = this.props;

  }



  deleteUser(id, page){
    let result = window.confirm("削除してよろしいですか？");
    if(result){
      props.deleteUser(id, props.history);
    }
  }

  render() {
    if(!this.props.users){
      return <p>Loading...</p>;
    }

    return (
      <span>
        <PagiList
          title={ "User 一覧" }
          columuns={ columuns } data={ this.props.users }
          itemsPerPage={20}
          actionsComponent={ ActionsComponent }
          insertBtn={true}
          insertFormUrl={'/users/new'}
          onDelete={this.deleteUser}
        />
      </span>

    );
  }
}

const mapStateToProps = state => {
  return {
    loginUser: state.auth.user,
    users: state.User.users,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getUsers: () => dispatch(user.getUsers()),
    deleteUser: (id, h) => dispatch(user.deleteUser(id, h)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
