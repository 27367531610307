const initialState = {
   user: null,
   players: null,
   devices: null,
   devices_pagi: null,
   target: null,
   practicesOfDay: null,
};


export default function Main(state = initialState, action) {

   switch (action.type) {

      case 'GET_PLAYERS':
         return { ...state, players: action.players, msgType: '', message: '', };

      case 'SET_TARGET_USER':
         return { ...state, user: action.user, msgType: '', message: '', target: action.target };

      case 'SET_PRACTICES_OF_DAY':
         return { ...state, practicesOfDay: action.practicesOfDay, msgType: '', message: '', };

      case 'GET_PLAYERS_ERROR':
         return { ...state, data: action.data, msgType: '', message: '', };

      case 'GET_DEVICES':
         return { ...state, devices: action.devices, msgType: '', message: '', };

    case 'RE_CALC_SUMMARY':
      return { ...state, target: action.target };

      default:
         return state;
   }
}
