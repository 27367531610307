import React, { Component } from 'react'
import { Container, Row, Col, Button, ButtonGroup, ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap'
import { connect } from "react-redux";
import MapBrowser from './map';
import Table from 'react-bootstrap/Table'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip } from 'recharts'
import Dropdown from 'react-dropdown';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { playerSelector } from "../../actions";
import { wsHOST } from '../../Config';
import './main.css';
import {calc_distance, convertGpsTime, to_d} from '../helpers';
const VERSION = 'v2';
const wsUrl = `${wsHOST}/${VERSION}/`;

const dispItems = [
  { "id": 0, "name": "Split /500M",  "value": 0, "dataKey": "split500m",  "unit": "" },
  { "id": 1, "name": "Split /1000M", "value": 0, "dataKey": "split1000m", "unit": "" },
  { "id": 2, "name": "SPM",          "value": 0, "dataKey": "spm",        "unit": "" },
  { "id": 3, "name": "Stroke",       "value": 0, "dataKey": "stroke",     "unit": "" },
  { "id": 4, "name": "Distance",     "value": 0, "dataKey": "distance",   "unit": "m" },
  { "id": 5, "name": "Time",         "value": 0, "dataKey": "duration",       "unit": "" },
  { "id": 6, "name": "PITCH",        "value": 0, "dataKey": "pitch",      "unit": "deg" },
  { "id": 7, "name": "ROLL",         "value": 0, "dataKey": "roll",       "unit": "deg" },
];

const dataLog = [
];

const gpsLog = [];
const motionLog = [];
var gpsCounter = 0;
var motionCounter = 0;


function Device(id, spd, vmg, ang, pos, rotate, lines, main, wind, wds, wdd, time, name) {
  this.id = id;
  this.spd = spd;
  this.vmg = vmg;
  this.ang = ang;
  this.pos = pos;
  this.rotate = rotate;
  this.lines = lines;
  this.main = main;
  this.wind = wind;
  this.windspd = wds;
  this.winddir = wdd;
  this.time = time;
  this.name = name;
  this.isStarboard = true;
}


class RealtimeRowing extends Component {

  //Variable
  ws = null;
  firstGps = true;

  static propTypes = {
    user: PropTypes.object,
    targetPlayer: PropTypes.any,
    handleSelectPlayer: PropTypes.func
  };


  //Constractor
  constructor(props) {
    super(props);

    let latlng = this.props.user.organization.location.split(',')
    this.state = {
      list: dispItems,
      device: null,
      selected_device: null,
      chart: dataLog,
      limit: 600,
      dataKeyId: 0,
      dataKey: dispItems[0].dataKey,
      center: { lat: latlng[0], lng:latlng[1] },
      windpos: { lat: 0, lng: 0 },
      deviceMap: {
        7777:
        {
          id: '7777',
          spd: 0,
          vmg: 0,
          ang: 0,
          pos: [0, 0],
          rotate: 0,
          lines: [],
        }
      },
      first_gps: {},
      maxLimit: 100,
      split500m: 0,
      spm: 0,
      distance: 0,
      time: 0,
      stroke: 0,
      split1000m: 0,
      to_unit: (this.props.user.unit == "knot")? 1.852 : 1.0,  //convert unit km/h or knot
      first_motion: true,
      motion: {},
    };


    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChangeMaxLimit = this.handleChangeMaxLimit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUserSelect = this.handleUserSelect.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  componentDidMount() {
    let newDevMap = this.state.deviceMap;
    delete newDevMap[7777];//delete

    this.props.getDevices();
  }


  handleUserSelect(option) {
    const device = _.find(this.props.devices, (device) => device.id == option.value);
    this.setState({
      device: device.id,
      selected_device: device
    });

  }
  value() {
    if (this.state.selected_device) {
      return { label: this.state.selected_device.user.last_name + ":" + this.state.device, value: this.state.device };
    } else {
      return { label: 'NoUser', value: 'NoUser' };
    }
  }

  options() {
    if (this.props.devices) {
      let options = this.props.devices.map((device) => { return { label: device.user.last_name + ":" + device.id, value: device.id }; });
      return options;
    }
  }


  //Reset Button
  handleReset(event) {
    //clear
    this.setState({
      first_gps: [],
      distance: 0,
      stroke: 0,
    });
    this.firstGps = true;
  }

  //Text
  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    this.setState({
      [name]: target.value,
    });
  }

  //Text
  handleChangeMaxLimit(event) {
    const target = event.target;
    const name = target.name;

    if (name === "maxlimit") {
			this.setState({
				maxLimit: Number(target.value),
			});
    }
  }


  //Button
  handleSubmit(event) {
    event.preventDefault();

    if (this.ws != null)
      this.ws.close();
    //this.ws = new WebSocket(wsUrl+this.state.device)
    this.ws = new WebSocket(wsUrl + this.props.user.organization.id)

    //////////////////////////////////////////
    this.ws.onopen = () => {
      // on connecting, do nothing but log it to the console
      console.log('connected')
      this.setState(state => ({
        isToggleOn: true
      }));
    }


    //////////////////////////////////////////
    this.ws.onmessage = evt => {
      // listen to data sent from the websocket server
      let result = JSON.parse(evt.data);
      let chart = this.state.list;
      console.log(result);

      //Motion
      if ((result.type === "motion") && (result.id == String(this.state.device))) {

        //init
        if(this.state.first_motion){
          this.setState({
            motion: result,
            first_motion: false,
          });
          return
        }


        //prev value
        const p = this.state.motion;
        let stroke = this.state.stroke;
        //Filter
        const k = 0.9
        result.d1 = (result.d1 * k) + p.d1 * (1-k)
        result.d2 = (result.d2 * k) + p.d2 * (1-k)
        result.d3 = (result.d3 * k) + p.d3 * (1-k)
        //前回値との比較
        const v = Math.sqrt(Math.pow(result.d1,2) + Math.pow(result.d2,2) + Math.pow(result.d3, 2))
        if(v > 1150){//count
          stroke = stroke + 1;
        }

        //pitch and roll
        let pitch = Math.round((-1) * Math.atan2(result.d2, -result.d3) * (180/Math.PI));
        let roll = Math.round((-1) * Math.atan2(result.d1, -result.d3) * (180/Math.PI));


        //update state
        this.setState({
          stroke: stroke,
          pitch: pitch,
          roll: roll,
        });

        let m = {
          stroke: stroke,
          pitch: pitch,
          roll: roll,
        }

        motionCounter = motionCounter + 1;
        updateChartData(motionLog, m, motionCounter, this.state.limit);
      }
      // GPS   ///////////////////////////////// ***
      if ((result.type === "gps")) {

        const current_device_id = String(this.state.device)

        //基準GPS
        let newDevMap = this.state.deviceMap;
        //前回のGPS
        let last_gps;

        //選択ユーザの場合
        let main = false;
        if (result.id === current_device_id)
          main = true;

        //Update Disp on Map device
        const unit = this.state.to_unit;

        //表示値の設定
        let work = [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }];

				let name = "";
				if (this.props.devices) {
					this.props.devices.map((device) => {
						if(device.id == result.id){
							name = device.user.last_name;
						}
					});
				}

        //存在確認
        if (newDevMap.hasOwnProperty(result.id)) {
          last_gps = newDevMap[result.id]
          let lines = newDevMap[result.id].lines;
          lines.push([result.lat, result.long]);
          let dev = new Device(result.id, (result.spd / unit).toFixed(2), 0, 0,
            { lat: result.lat, lng: result.long }, result.dir.toFixed(0), lines,
            newDevMap[result.id].main, newDevMap[result.id].wind,
            newDevMap[result.id].wds, newDevMap[result.id].wdd,
            convertGpsTime(result.time), name);
          delete newDevMap[result.id];//delete
          newDevMap[result.id] = dev;//update

          //選択ユーザの場合
          if (main) {
            //Slide map only first time
            if ((this.firstGps) && (Math.abs(result.lat) > 0)) {
              let utc = convertGpsTime(result.time)
              result.time = utc;
              //DeviceMap
              let dev = new Device(result.id, 0, 0, 0,
                { lat: result.lat, lng: result.long }, 0, [], main, false, 0, 0, utc);
              newDevMap[result.id] = dev;//new
              this.firstGps = false;
              this.setState({
                first_gps: result,
                deviceMap: newDevMap,
                center: { lat: result.lat, lng: result.long },
              });
              return;
            }
            //距離の計算
            let distance = calc_distance(result.lat, result.long, last_gps.pos.lat, last_gps.pos.lng);
            let total_distance = this.state.distance + distance;

            //経過時間（秒）
            let t0 = this.state.first_gps.time;
            let duration = (convertGpsTime(result.time) - t0) / 1000


            if(total_distance > 0){
              //Split500m
              work[0].value = Math.round(duration / (total_distance / 500))
              //Split1000m
              work[1].value = Math.round(duration / (total_distance / 1000))
            }
            if( duration > 0 )
              work[2].value = Math.round(this.state.stroke / (duration / 60))
            //update state
            this.setState({
              split500m: work[0].value,
              spm: work[2].value,
              distance: total_distance,
              time: duration,
              split1000m: work[1].value,
            });
            //Chart update
            let jst = convertGpsTime(result.time)
            jst.setTime(jst.getTime() + 1000*60*60*9);// JSTに変換
            const t = jst.getHours()+":"+jst.getMinutes()+":"+jst.getSeconds();
            let g = {
              time: t,
              split500m: work[0].value,
              distance: total_distance,
              split1000m: work[1].value,
              spm: work[2].value,
            }
            gpsCounter = gpsCounter + 1;
            updateChartData(gpsLog, g, gpsCounter, this.state.limit);
          }

          //end of 選択ユーザ

        }else{//リストになければ追加
          let dev = new Device(result.id, (result.spd / unit).toFixed(2), 0, 0,
            { lat: result.lat, lng: result.long }, result.dir.toFixed(0), [],
            main, 0, 0, 0, convertGpsTime(result.time));
            newDevMap[result.id] = dev;//update
        }

        //update state
        this.setState({
          deviceMap: newDevMap,
        });

      }//GPS end

      //Update Chart
      switch(this.state.dataKeyId){
        case 0://500m
          this.ary = gpsLog;
          break;
        case 1://1000m
          this.ary = gpsLog;
          break;
        case 2://spm
          this.ary = gpsLog;
          break;
        case 3://stroke
          this.ary = motionLog;
          break;
        case 4://distance
          this.ary = gpsLog;
          break;
        case 5://time
          this.ary = gpsLog;
          break;
        case 6://pitch
          this.ary = motionLog;
          break;
        case 7://roll
          this.ary = motionLog;
          break;
      }
      //Refresh!
      this.setState({
        chart: this.ary.slice()
      });

    }//on websocket

    //////////////////////////////////////////
    this.ws.onclose = () => {
      console.log('disconnected')
      this.firstGps = true;
      this.setState(state => ({
        isToggleOn: false
      }));
      // automatically try to reconnect on connection loss
    }
  }

  //Grid clicked
  changeGraph(id) {
    let disp = this.state.list;
    this.setState(state => ({
      dataKeyId: id,
      dataKey: disp[id].dataKey
    }));
  }

  render() {
    return (
      <Container>
        <Row>
          <Col xs={6}>
            <Dropdown
              options={this.options()}
              value={this.value()}
              onChange={this.handleUserSelect}
            />
          </Col>
          <Col xs={6}>
            <Button variant="outline-primary" onClick={this.handleSubmit}> {this.state.isToggleOn ? 'Connected!' : 'Connect'}</Button>
          Limit:<input type="text" name="limit" value={this.state.limit} onChange={this.handleInputChange} />
          </Col>
        </Row>
        <Row>
          <Col><MapBrowser
            deviceMap={this.state.deviceMap}
            deviceId={String(this.state.device)}
            center={this.state.center}
            windSpeed={this.state.windspeed} windDirection={this.state.winddir}
            windpos={this.state.windpos}
            user={this.props.user}
          />
          </Col>
          <Col xs={7}>
            <Table striped bordered hover variant="dark">
              <tbody className="GridData2">

                <tr>
                  <td className={this.state.dataKeyId === 0 ? 'Active' : 'NotActive'} onClick={this.changeGraph.bind(this, 0)}>
                      <span className="Unit2">{this.state.list[0].name}</span>
                      <span className="RealtimeData">{to_d(this.state.split500m)}</span>
                  </td>
                  <td className={this.state.dataKeyId === 1 ? 'Active' : 'NotActive'} onClick={this.changeGraph.bind(this, 1)}>
                      <span className="Unit2">{this.state.list[1].name}</span>
                      <span className="RealtimeData">{to_d(this.state.split1000m)}</span>
                  </td>
                </tr>
                <tr>
                  <td className={this.state.dataKeyId === 2 ? 'Active' : 'NotActive'} onClick={this.changeGraph.bind(this, 2)}>
                      <span className="Unit2">{this.state.list[2].name}</span>
                      <span className="RealtimeData">{this.state.spm}</span>
                  </td>
                  <td className={this.state.dataKeyId === 3 ? 'Active' : 'NotActive'} onClick={this.changeGraph.bind(this, 3)}>
                      <span className="Unit2">{this.state.list[3].name}</span>
                      <span className="RealtimeData">{this.state.stroke}</span>
                  </td>
                </tr>
                <tr>
                  <td className={this.state.dataKeyId === 4 ? 'Active' : 'NotActive'} onClick={this.changeGraph.bind(this, 4)}>
                      <span className="Unit2">{this.state.list[4].name}</span>
                      <span className="RealtimeData">{this.state.distance}</span>
                      <span className="Unit">{this.state.list[4].unit}</span>
                  </td>
                  <td className={this.state.dataKeyId === 5 ? 'Active' : 'NotActive'} onClick={this.changeGraph.bind(this, 5)}>
                      <span className="Unit2">{this.state.list[5].name}</span>
                      <span className="RealtimeData">{to_d(this.state.time)}</span>
                  </td>
                </tr>
                <tr>
                  <td className={this.state.dataKeyId === 6 ? 'Active' : 'NotActive'} onClick={this.changeGraph.bind(this, 6)}>
                      <span className="Unit2">{this.state.list[6].name}</span>
                      <span className="RealtimeData">{this.state.pitch}</span>
                  </td>
                  <td className={this.state.dataKeyId === 7 ? 'Active' : 'NotActive'} onClick={this.changeGraph.bind(this, 7)}>
                      <span className="Unit2">{this.state.list[7].name}</span>
                      <span className="RealtimeData">{this.state.roll}</span>
                  </td>
                </tr>



              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col xs={5}></Col>
          <Col >
            <Button variant="secondary" onClick={this.handleReset}>RESET</Button>
          </Col>
          <Col xs={4}>
          </Col>
          <Col xs={3}>
            <span className={"ManualWind"}>Max Y: <input name={"maxlimit"} className={"WindValue"} type="number" maxlength={3} size={3} pattern="[0-9]*" value={this.state.maxLimit} onChange={this.handleChangeMaxLimit} /></span>
          </Col>

        </Row>
        <Row>
          <Col>
            <ResponsiveContainer width="100%" height="80%" minWidth={400} minHeight={300}>
              <LineChart data={this.state.chart} >
                <XAxis dataKey="time" />
                <YAxis domain={[0, this.state.maxLimit]} />
                <Tooltip />
                <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                <Line type="monotone" dataKey={this.state.dataKey} stroke="#dc143c" isAnimationActive={false} />
              </LineChart>
            </ResponsiveContainer>
          </Col>
        </Row>
      </Container>
    );
  }
}


/**
 * update Chart data
 */
function updateChartData(list, data, counter, limit) {

  if (counter < limit) {
    list.push(data);
  } else {
    list.shift();
    list.push(data);
  }

}


const mapStateToProps = state => {
  return {
    user: state.auth.user,
    targetUser: state.PlayerSelector.user,
    devices: state.PlayerSelector.devices,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getDevices: () => dispatch(playerSelector.getDevices()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RealtimeRowing);
