import React, { Component } from "react";
import { connect } from "react-redux";
import { main } from "../../actions";
import RankingList from "./RankingList";
import Search from "./Search";

class Rankings extends Component {

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
    this.props.getRankingPractices({ from: null, to: null, targetUser: this.props.authUser, authUser: this.props.authUser });
  }



  render() {
    if (!this.props.up_vmgs) {
      return <p>Loading...</p>;
    }

    return (
      <span>
        <Search />
        <RankingList title={"UpWind VMG ranking"} list={this.props.up_vmgs} param={'max_vmg_up'} />
        <RankingList title={"MaxSpeed ranking"} list={this.props.max_speeds} param={'max_speed'} />
        <RankingList title={"DwWind VMG ranking"} list={this.props.down_vmgs} param={'max_vmg_down'} />
      </span>
    );
  }
}

const mapStateToProps = state => {
  return {
    targetUser: state.PlayerSelector.user,
    up_vmgs: state.Main.up_vmgs,
    down_vmgs: state.Main.down_vmgs,
    max_speeds: state.Main.max_speeds,
    authUser: state.auth.user,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getRankingPractices: ({ from, to, targetUser, authUser }) => dispatch(main.getRankingPractices({ from, to, targetUser, authUser })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Rankings);
