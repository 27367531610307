import React, {Component} from "react";
import {teamList, device} from "../../actions";
import { connect } from "react-redux";
import { ListGroup, Button, Form } from 'react-bootstrap'
import { Card, CardHeader, CardBody } from 'reactstrap';

class DeviceEdit extends Component {

  constructor(props) {
    super(props);

    const {params} = this.props.match
    const id = parseInt(params.id, 10)

    this.state = {
      device_id: id,
      user_id: -1,
    }

  }


  componentDidMount() {

    //チームメンバーの読み込み（変更可能な対象ユーザ）
    this.props.getMembers();

    //デバイスカテゴリー一覧を取得
    //this.props.getCategory();

    //デバイス情報の再読み込み
    this.props.getDevice(this.state.device_id);

    //関数ばインド
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit = e => {
    e.preventDefault();

    let user_id = this.state.user_id;
    let device = {
      id      : this.state.device_id,
      user_id : user_id,
    };

    //API実行
    this.props.updateDevice(device, this.props.history);
  }


  onChange =(e) =>{
    this.setState({
      user_id:e.target.value,
    });
  }

  render(){

    if(!this.props.device || !this.props.members )
      return <p>Loading...</p>;

    if(!this.props.devices)
      return <p>デバイス一覧から選択してください</p>;

    const p = this.props;
    var device;
    var user_id = -1;

    for(let i = 0; i < p.devices.length; i++){
      if(p.devices[i].id == this.state.device_id){ //URLデバイスIDが一致
        device = p.devices[i];

        if(p.devices[i].user_id)//割り当てユーザがいる場合、selectedにする
          user_id = p.devices[i].user_id;
      }
    }

    //メンバーリストの作成
    var Options = function(member) {
        return  <option key={member.id} value={member.id}>{member.last_name} {member.first_name}</option>;
    };


    return (
      <div className="container">
          <Card>
            <CardHeader>
                <b> {"Edit Device"} </b>
            </CardHeader>
            <CardBody>
              <Form onSubmit={this.onSubmit}>
                <fieldset>
                  {this.props.errors.length > 0 && (
                    <ul>
                      {this.props.errors.map(error => (
                        <li key={error.field}>{error.message}</li>
                      ))}
                    </ul>
                  )}

                  <Form.Group>
                    <Form.Label>Device ID</Form.Label> 
                    <Form.Control disabled id="device_id" type="text" value={device.id}/>
                      <Form.Text className="text-muted">
                        DeviceIDは変更できません.
                      </Form.Text>
                  </Form.Group>

                  <Form.Group>
                    <label htmlFor="category">Category</label>
                    <Form.Control disabled id="category" type="text" value={ device.category }/>
                      <Form.Text className="text-muted">
                        Categoryは変更できません.
                      </Form.Text>
 
                  </Form.Group>

                  <Form.Group>
                    <label htmlFor="user">Select User</label>
                    <Form.Control as="select" onChange={this.onChange} defaultValue={user_id} >
                      {this.props.members.map(Options)}
                      <option value={-1} key={-1}>-</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group>
                    <Button variant="primary" size="lg" onClick={this.onSubmit} >　OK　</Button>{'  '}
                    <Button variant="secondary" size="lg" href="/devices"  >Cancel </Button>
                  </Form.Group>

               </fieldset>
              </Form>
          </CardBody>
        </Card>
      </div>



    );
  }
}

const mapStateToProps = state => {
  return {
    members: state.TeamList.members,
    device : state.Device.device,
    errors : state.Device.errors,
    devices: state.Device.devices,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getMembers: () => dispatch(teamList.getMembers()),
    getDevice: (id) => dispatch(device.getDevice(id)),
    updateDevice: (d, history) => dispatch(device.updateDevice(d, history)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceEdit);
