import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div className="footer">
    Copyright© 2019-2020 N-Sports tracking Lab, LLC.
    </div>
  );
}

export default Footer;
