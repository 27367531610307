import React, {Component} from "react";
import {connect} from "react-redux";
import { Card, CardHeader, CardBody } from 'reactstrap';
import { ListGroup, Button, Form } from 'react-bootstrap'
import {Link, Redirect} from "react-router-dom";
import Validation from '../Validation';
import { user } from "../../actions";

class Add extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user:{
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        passwordConfirm: "",
      },
      message: {},
    }

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }


  validatePasswordConfirm(key, value){

    if(key === 'password'){
      this.compairPassword(value, this.state.user.passwordConfirm);
    }
    else if(key === 'passwordConfirm'){
      this.compairPassword(this.state.user.password, value);
    }
    else{
      return;
    }
  }


  compairPassword(pass, passConf){
    if ( pass === passConf){
      return true;
    }else{
      this.setState({
        message: {
          ...this.state.message,
          passwordConfirm: "パスワードが一致しません", 
        }
      });
      return false;
    }
  }


  onChange =(e) =>{
    const key = e.target.id;
    const value = e.target.value;
    const { message } = this.state;

    this.setState({
      user:{
        ...this.state.user,
        [key]: value,
      }
    });

    // Realtime check
    this.setState({
      message: {
        ...message,
        [key]: Validation.formValidate(key, value)
      }
    });

    //PassCheck
    this.validatePasswordConfirm(key, value);
  }

  canSubmit = (): boolean => {

    const { user, message } = this.state;
    const { loading } = this.props;

    const validInfo =
      Object.values(user).filter(value => {
        return value === '';
      }).length === 0;

    const validMessage =
      Object.values(message).filter(value => {
        return value !== '';
      }).length === 0;

    return validInfo && validMessage && !loading;
  }


  onSubmit = e => {
    e.preventDefault();
    this.props.addUser(this.state.user, this.props.history);
  }

  render() {

    const {user, message} = this.state;

    return (

      <div className="container">
          <Card>
            <CardHeader>
                <b> {"メンバー追加"} </b>
            </CardHeader>
            <CardBody>
              <Form onSubmit={this.onSubmit}>
                <fieldset>
                  {this.props.errors.length > 0 && (
                    <ul>
                      {this.props.errors.map(error => (
                        <li key={error.field}>{error.message}</li>
                      ))}
                    </ul>
                  )}

                  <Form.Group>
                    <Form.Label>組織情報</Form.Label> 
                    <Form.Control disabled id="organization" type="text" value={this.props.loginUser.organization.name}/>
                      <Form.Text className="text-muted">
                        組織情報は変更できません.
                      </Form.Text>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>メールアドレス ※ログインIDになります</Form.Label> 
                    <Form.Control id="email" type="text" value={ user.email } onChange={this.onChange}/>
                    {message.email && (<p style={{ color: 'red', fontSize: 8 }}>{message.email}</p>)}
                    <Form.Label>姓</Form.Label> 
                    <Form.Control id="last_name" type="text" value={ user.last_name } onChange={this.onChange}/>
                    {message.last_name && (<p style={{ color: 'red', fontSize: 8 }}>{message.last_name}</p>)}
                    <Form.Label>名</Form.Label> 
                    <Form.Control id="first_name" type="text" value={ user.first_name } onChange={this.onChange}/>
                    {message.first_name && (<p style={{ color: 'red', fontSize: 8 }}>{message.first_name}</p>)}
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>パスワード</Form.Label> 
                    <Form.Control id="password" type="password" value={user.password} autoComplete="nope" onChange={this.onChange} />
                    <p>パスワードは8文字以上である必要があります。</p>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>確認用パスワード</Form.Label>
                    <Form.Control id="passwordConfirm" type="password" value={user.passwordConfirm} onChange={this.onChange} />
                    {message.passwordConfirm && (<p style={{ color: 'red', fontSize: 8 }}>{message.passwordConfirm}</p>)}
                  </Form.Group>

                  <Form.Group>
                    <Button variant="primary" disabled={!this.canSubmit()} size="lg" onClick={this.onSubmit} >　OK　</Button>{'  '}
                    <Button variant="secondary" size="lg" href="/users"  >Cancel </Button>
                  </Form.Group>

               </fieldset>
              </Form>
            </CardBody>
           </Card>
       </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loginUser: state.auth.user,
    loading  : state.User.loading,
    errors : state.User.errors,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    addUser: (u, h) => dispatch(user.addUser(u, h)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Add);
