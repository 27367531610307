import React, { Component } from 'react';
import { auth } from "./actions";
import { connect } from "react-redux";
//import PropTypes from 'prop-types';
import { Navbar, Nav, NavDropdown, } from 'react-bootstrap';
//import Notification from './components/Notification/Notification';
import './Header.css';

class Header extends Component {

  render() {
    //Private Nav
    let PrivateNav = (
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/">Home</Nav.Link>
          <Nav.Link href="/realtime_monitor">リアルタイムモニタ</Nav.Link>
          <Nav.Link href="/users">利用者一覧</Nav.Link>
          <Nav.Link href="/devices">デバイス一覧</Nav.Link>
          <Nav.Link href="/upload_data">データアップロード</Nav.Link>
        </Nav>
        <Nav className="ml-auto">
          <NavDropdown className="dropdown-menu-right" title={this.props.auth.email} id="basic-nav-dropdown">
            <NavDropdown.Item href="/agreements">契約者情報</NavDropdown.Item>
            <NavDropdown.Item href="/location_update">拠点変更</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">ご利用料金</NavDropdown.Item>
            <NavDropdown.Item href="/passchange">パスワード変更</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={this.props.logout} >ログアウト</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    )

    let title = "Sailing"
    if(this.props.auth.user)
      title = this.props.auth.user.organization.event.name

    return (
      <Navbar bg="dark" variant="dark" expand="lg">

        <Navbar.Brand href="#home" className="nav-title">
          <img
            alt="Smart Saling"
            src="/crewing_mark.svg"
            width="40"
            height="40"
            className="d-inline-block"
          />{' '}
          Smart {title}
        </Navbar.Brand>

        {this.props.auth.isAuthenticated ? PrivateNav : ''}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

      </Navbar>
    );
  }
}



const mapStateToProps = state => {
  return {
    auth: state.auth,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(auth.logout()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
