
export function to_d(seconds){
    let minutes = Math.floor(seconds / 60);
    let sec = seconds % 60;
    return minutes + ":" + toDoubleDigits(sec);
}

function toDoubleDigits(num) {
    num += "";
    if (num.length === 1) {
      num = "0" + num;
    }
    return num;
}

/**
 * calc_distance
 */
//メートル単位で2点間の距離を返却する
export function calc_distance(lat1, lng1, lat2, lng2) {
  lat1 *= Math.PI / 180
  lng1 *= Math.PI / 180
  lat2 *= Math.PI / 180
  lng2 *= Math.PI / 180
  let len = Math.floor(6371.137 * Math.acos(Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1) + Math.sin(lat1) * Math.sin(lat2)) * 1000);
  return isNaN(len)? 0 : len
}


//GPS timeをDateオブジェクトに変換
export function convertGpsTime(time){
    let t = new Date();
    let utc = new Date(
      t.getUTCFullYear(),
      t.getUTCMonth(),
      t.getUTCDate(),
      time.substring(0, 2),
      time.substring(2, 4),
      time.substring(4, 6),
      time.split(".")[1],
    );
    return utc;
}

//2点間距離の移動速度を算出
export function calc_velocity(l2, l1, t2, t1) {
    if (t1 == 0)
      return 0;
    else {
      let dt = (Date.parse('2020/01/01 ' + t2) - Date.parse('2020/01/01 ' + t1)) / 1000;
      return (-1 * (l2 - l1) / parseFloat(dt));//符号反転（近づくとマイナスになるから）
    }
}


// クリップボードへコピー（コピーの処理）
export function copyToClipboard (value) {
	/*
  navigator.clipboard.writeText(value).then(
  () => {
    // コピーに成功したときの処理
    alert('コピーしました。');
  },
  () => {
    // コピーに失敗したときの処理
    alert('コピーに失敗しました。');
  });
	*/
	  // Navigator clipboard api needs a secure context (https)
    //if (navigator.clipboard && window.isSecureContext) {
    //    await navigator.clipboard.writeText(textToCopy);
    //} else {
        // Use the 'out of viewport hidden text area' trick
        const textArea = document.createElement("textarea");
        textArea.value = value;

        // Move textarea out of the viewport so it's not visible
        textArea.style.position = "absolute";
        textArea.style.left = "-999999px";

        document.body.prepend(textArea);
        textArea.select();

        try {
            document.execCommand('copy');
            alert('コピーしました。');
        } catch (error) {
            console.error(error);
            alert('コピーに失敗しました。');
        } finally {
            textArea.remove();
        }
    //});
}
