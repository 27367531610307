import API from '../components/API';
import Practices from '../models/Practices';
import moment from 'moment';

export const getMembers = () => {

  return (dispatch, getState) => {

    dispatch({ type: "PRACTICE_LOADING" });
    const token = getState().auth.token;

    let onSuccess = function (res) {
      if (res.status === 200) {

        dispatch({ type: "PRACTICE_LOADED" });
        return dispatch({ type: 'GET_MEMBERS', members: res.data });

      } else {
        dispatch({ type: "GET_MEMBERS__ERROR", data: res.data });
        throw res.data;
      }
    }

    let onError = function (res) {
      if (res.status >= 500) {
        console.log("Server Error!");
        throw res;
      } else if (res.status >= 400 && res.status < 500) {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      } else {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      }
    }

    API.getMembers({ token: token, onSuccess: onSuccess, onError: onError });
  }
}


export const getMembersPractices = ({ from, to, target, member, user }) => {

  return (dispatch, getState) => {

    dispatch({ type: "SET_MESSAGE", msgType: null, message: null });
    dispatch({ type: "PRACTICE_LOADING" });
    const token = getState().auth.token;

    let onSuccess = function (res, target) {
      if (res.status === 200) {
        //memberを絞り込み
        let out = [];
        let practices = Practices.fromJSON(res.data);
        var selected_practices;
        if (res.data.length == 0) {
          dispatch({ type: "SET_MESSAGE", msgType: "info", message: "Sorry, selected member has no data for the time. Please select another main user's practice." });
        }
        practices.forEach((p) => {
          //今回選択したユーザ
          if (p.user.id == member.id) {
            let onSuccess = (() => {
              target.mergeMemberData(p);
              if (!selected_practices)
                selected_practices = [];
              selected_practices.push(p);
              dispatch({ type: "UPDATE_DISP_DATA", upd_time: moment(), practices: selected_practices, member: target, gpsLog: target.gpsLog, motionLog: target.motionLog, windLog: target.windLog, buoyLog: target.buoy_distance, max_up_distance: target.max_up_distance });
              dispatch({ type: 'SET_TARGET_USER', target: target, user: user });
              dispatch({ type: "PRACTICE_LOADED" });

              if (!target.practice) {
                dispatch({ type: 'SET_SELECT_BUTTON_VISIBLE', prevButtonState: true, nextButtonState: true });
              }
              else {
                var legIndex = target.practice.legs._legs.findIndex((p) => p.id === target.id);
                if (legIndex <= 0) {
                  dispatch({ type: 'SET_SELECT_BUTTON_VISIBLE', prevButtonState: true, nextButtonState: false });
                } else if (legIndex >= target.practice.legs._legs.length - 1) {
                  dispatch({ type: 'SET_SELECT_BUTTON_VISIBLE', prevButtonState: false, nextButtonState: true });
                } else {
                  dispatch({ type: 'SET_SELECT_BUTTON_VISIBLE', prevButtonState: false, nextButtonState: false });
                }
              }

            });
            dispatch({ type: "PRACTICE_LOADING" });
            p.load({ onSuccess: onSuccess, target: target });
          }
        });
      } else {
        dispatch({ type: "GET_MEMBERS__ERROR", data: res.data });
        throw res.data;
      }
    }

    let onError = function (res) {
      if (res.status >= 500) {
        console.log("Server Error!");
        throw res;
      } else if (res.status >= 400 && res.status < 500) {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      } else {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      }
    }

    API.getMembersPractices({ token: token, onSuccess: onSuccess, onError: onError, from: from, to: to, target: target });
  }
}


export const setActive = (members, member) => {

  return (dispatch, getState) => {

    let new_members = [];
    members.forEach((m) => {
      if (m.id == member.id)
        m.isActive = true
      else
        m.isActive = false

      new_members.push(m);
    });
    return dispatch({ type: 'SET_MEMBER_ACTIVE', members: new_members });
  }
}


