import API from '../components/API';
import Practices from '../models/Practices';

export const getPractices = ({ from, to, targetUser, authUser, target }) => {

  return (dispatch, getState) => {

    //dispatch({type: "PRACTICE_LOADING"});
    const token = getState().auth.token;
    let practices = null;

    let onSuccess = function (res, authUser) {
      if (res.status === 200) {
        //Practicesモデルのインスタンスを生成
        practices = Practices.fromJSON(res.data, authUser.unit, authUser.wind_unit);
        //初期に表示するPracticeを選別
        let latestPractice = practices.filterByDay(practices.getLatestDay());
        dispatch({ type: "SET_MESSAGE", msgType: null, message: null });
        dispatch({ type: 'GET_PRACTICES', practices: practices, practicesOfDay: latestPractice });
        //dispatch({ type: 'SET_TARGET_USER', user: targetUser, target: target });
      } else {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      }
    }

    let onError = function (res) {
      if (res.status >= 500) {
        console.log("Server Error!");
        throw res;
      } else if (res.status >= 400 && res.status < 500) {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      } else {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      }
    }

    if (from == null || to == null) {
      API.getPractices({ token: token, onSuccess: onSuccess, onError: onError, targetUser: targetUser, authUser: authUser });
    }
    else {
      API.getPractices({ token: token, from: from, to: to, onSuccess: onSuccess, onError: onError, targetUser: targetUser, authUser: authUser });
    }
  }
}


export const getRankingPractices = ({ from, to, targetUser, windRange, authUser }) => {

  return (dispatch, getState) => {

    //dispatch({type: "PRACTICE_LOADING"});

    const token = getState().auth.token;

    let onSuccess = function (res) {
      if (res.status === 200) {
        // up_vmgs
        let up_vmgs = Practices.fromJSON(res.data.up_vmgs, authUser.unit, authUser.wind_unit);
        let down_vmgs = Practices.fromJSON(res.data.down_vmgs, authUser.unit, authUser.wind_unit);
        let max_speeds = Practices.fromJSON(res.data.max_speeds, authUser.unit, authUser.wind_unit);

        dispatch({ type: "GET_RANKINGS", up_vmgs: up_vmgs, down_vmgs: down_vmgs, max_speeds: max_speeds });
      } else {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      }
    }

    let onError = function (res) {
      if (res.status >= 500) {
        console.log("Server Error!");
        throw res;
      } else if (res.status >= 400 && res.status < 500) {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      } else {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      }
    }

    if (from == null || to == null) {
      API.getRankingPractices({ token: token, onSuccess: onSuccess, onError: onError, targetUser: targetUser, windRange: windRange });
    }
    else {
      API.getRankingPractices({ token: token, from: from, to: to, onSuccess: onSuccess, onError: onError, targetUser: targetUser, windRange: windRange });
    }
  }
}


//JSONロード後にグラフデータをアップデート
export const drawGraph = ({ target }) => {

  return (dispatch, getState) => {
    dispatch({ type: "DRAW_GRAPH", target: target });
  }

}

//mapをリロード
export const reloadMap = ({ _isMapReload }) => {
  return (dispatch, getState) => {
    dispatch({ type: "REROAD_MAP", isMapReload: _isMapReload });
  }

}


export const test = (device_id, url) => {
  return (dispatch, getState) => {

    const token = getState().auth.token;

    let onSuccess = function (res) {
      if (res.status === 200) {
        console.log("test");
        console.log(res);
        return dispatch({ type: 'GET_PRACTICES', practices: res.data });
      } else {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      }
    }

    let onError = function (res) {
      if (res.status >= 500) {
        console.log("Server Error!");
        throw res;
      } else if (res.status >= 400 && res.status < 500) {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      } else {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      }
    }

    //ログイン認証
    API.test({ token: token, device_id: device_id, url: url, onSuccess: onSuccess, onError: onError });
  }
}


export const updateDispData = ({ members, target, user }) => {

  return (dispatch, getState) => {
    dispatch({ type: "SET_MESSAGE", msgType: null, message: null });
    dispatch({ type: 'SET_PLACTICE_TIMES', practice_times: target.practice_times });
    //通常パターン
    if (!members) {
      dispatch({ type: "UPDATE_DISP_DATA", target: target, gpsLog: target.gpsLog, motionLog: target.motionLog, windLog: target.windLog, buoyLog: target.buoy_distance, max_up_distance: target.max_up_distance });
      dispatch({ type: 'SET_TARGET_USER', target: target, user: user });
    } else {
      //メンバーデータ有りパターン
      members.forEach((m) => {
        target.mergeMemberData(m);
      });
      dispatch({ type: "UPDATE_DISP_DATA", target: target, gpsLog: target.gpsLog, motionLog: target.motionLog, windLog: target.windLog, buoyLog: target.buoy_distance, max_up_distance: target.max_up_distance });
      dispatch({ type: 'SET_TARGET_USER', target: target, user, user });
    }

    if (!target.practice) {
      dispatch({ type: 'SET_SELECT_BUTTON_VISIBLE', prevButtonState: true, nextButtonState: true });
    }
    else {
      var legIndex = target.practice.legs._legs.findIndex((p) => p.id === target.id);
      if (legIndex <= 0) {
        dispatch({ type: 'SET_SELECT_BUTTON_VISIBLE', prevButtonState: true, nextButtonState: false });
      } else if (legIndex >= target.practice.legs._legs.length - 1) {
        dispatch({ type: 'SET_SELECT_BUTTON_VISIBLE', prevButtonState: false, nextButtonState: true });
      } else {
        dispatch({ type: 'SET_SELECT_BUTTON_VISIBLE', prevButtonState: false, nextButtonState: false });
      }
    }
  }
}


export const updatePolar = ({ target }) => {
  return (dispatch, getState) => {
    dispatch({ type: "SET_MESSAGE", msgType: null, message: null });
    dispatch({ type: "UPDATE_POLAR", target: target });
  }
}

export const updateCompare = ({ target }) => {
  return (dispatch, getState) => {
    dispatch({ type: "SET_MESSAGE", msgType: null, message: null });
    dispatch({ type: "UPDATE_COMPARE", target: target });
    //dispatch({ type: 'SET_TARGET_USER', target: target, user: user });
  }
}

export const updateTarget = ({ target, user }) => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_TARGET_USER', target: target, user: user });
  }
}

export const setPracticesOfDay = ({ practicesOfDay }) => {
  // console.log(practicesOfDay)
  return (dispatch, getState) => {
    dispatch({ type: 'SET_PRACTICES_OF_DAY', practicesOfDay: practicesOfDay });
  }
}

export const setSelectedDate = (date) => {
  return (dispatch, getState) => {

    if (date)
      dispatch({ type: 'SET_SELECTED_DATE', selectedDate: date.format('YYYY-MM-DD') });
    else
      dispatch({ type: 'SET_SELECTED_DATE', selectedDate: null });
  }
}

export const setCalendarDay = (calendarDay) => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_CALENDAR_DAY', calendarDay: calendarDay });
  }
}

export const loading = () => {
  return (dispatch, getState) => {
    dispatch({ type: "PRACTICE_LOADING" });
  }
}

export const loaded = () => {
  return (dispatch, getState) => {
    dispatch({ type: "PRACTICE_LOADED" });
  }
}

export const graphArea = (graphArea) => {
  return (dispatch, getState) => {
    dispatch({ type: "GRAPH_AREA", graphArea: graphArea });
  }
}


//Leg分割パターンを取得
export const getCalcPattern = ({ target }) => {

  return (dispatch, getState) => {

    const token = getState().auth.token;

    let onSuccess = function (res) {
      if (res.status === 200) {

        if (target) {
          let flg = false;
          res.data.forEach((ptn) => {
            if (target.calc_mode == ptn.id) {
              ptn.checked = true;
              flg = true;
            }
            else
              ptn.checked = false;
          });

          if (!flg) {
            res.data.forEach((ptn) => {
              if (ptn.algorithm == 0) {
                ptn.checked = true;
              }
              else
                ptn.checked = false;
            });
          }

        } else {
          res.data.forEach((ptn) => {
            ptn.checked = false;
          });
        }

        dispatch({ type: 'GET_CALC_PATTERN', calc_pattern: res.data });

      } else {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      }
    }

    let onError = function (res) {
      if (res.status >= 500) {
        console.log("Server Error!");
        throw res;
      } else if (res.status >= 400 && res.status < 500) {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      } else {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      }
    }

    API.getCalcPattern({ token: token, onSuccess: onSuccess, onError: onError });
  }
}

//Leg分割パターンを設定
export const setCalcPattern = (calc_patterns, selected_id) => {

  return (dispatch, getState) => {

    var new_ptn = [];
    calc_patterns.forEach((ptn) => {
      let p;
      p = ptn;
      if (p.id == selected_id) {
        p.checked = true;
      } else {
        p.checked = false;
      }
      new_ptn.push(p);
    });
    dispatch({ type: 'GET_CALC_PATTERN', calc_pattern: new_ptn });
  }
}


//Eventリストを取得
export const getEvents = ({ target }) => {
  return (dispatch, getState) => {

    const token = getState().auth.token;

    let onSuccess = function (res) {
      if (res.status === 200) {

        dispatch({ type: 'GET_EVENTS', events: res.data });

      } else {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      }
    }

    let onError = function (res) {
      if (res.status >= 500) {
        console.log("Server Error!");
        throw res;
      } else if (res.status >= 400 && res.status < 500) {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      } else {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      }
    }

    API.getEvents({ token: token, onSuccess: onSuccess, onError: onError });
  }
}


export const loadLastSetting = ({cbfunc, state}) => {

  return (dispatch, getState) => {

    //dispatch({type: "PRACTICE_LOADING"});
    const token = getState().auth.token;
    let practices = null;

    let onSuccess = function (res, cbfunc, state) {
      if (res.status === 200) {
        dispatch({ type: "GET_SETTING", setting_list: res.data.setting });
        cbfunc(res.data.setting, state)
      } else {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      }
    }

    let onError = function (res) {
      if (res.status >= 500) {
        console.log("Server Error!");
        throw res;
      } else if (res.status >= 400 && res.status < 500) {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      } else {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      }
    }

    API.loadLastSetting({ token: token, onSuccess: onSuccess, onError: onError, cbfunc: cbfunc, state: state });
  }
}


//Tabを切り替えたときに、再度パネルの表示を変更
export const trackMode = (flg) => {
  return (dispatch, getState) => {
    dispatch({ type: "TRACK_MODE", track_mode: flg });
  }
}

