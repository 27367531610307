const Config = () => {
  if (process.env.NODE_ENV === 'test') {
    // test
    return {
        env: process.env.NODE_ENV,
        apiURL: process.env.REACT_APP_TEST_API_URL,
        wsapiURL: process.env.REACT_APP_TEST_WS_API_URL
    }
  } else if (process.env.REACT_APP_RUN_ENV === 'production') {
    // production
    return {
        //apiURL: process.env.REACT_APP_PROD_API_URL
        env: process.env.REACT_APP_RUN_ENV,
        apiURL: process.env.REACT_APP_PROD_API_URL,
        wsapiURL: process.env.REACT_APP_PROD_WS_API_URL
    }
  }else if (process.env.REACT_APP_RUN_ENV === 'staging') {
    // production
    return {
        env: process.env.REACT_APP_RUN_ENV,
        apiURL: process.env.REACT_APP_STAG_API_URL,
        wsapiURL: process.env.REACT_APP_STAG_WS_API_URL
    }
  }
  // development
  return {
      env: process.env.NODE_ENV,
      apiURL: process.env.REACT_APP_DEV_API_URL,
      wsapiURL: process.env.REACT_APP_DEV_WS_API_URL
  }
}
export const ENV = Config().env
export const HOST = Config().apiURL
export const wsHOST = Config().wsapiURL
