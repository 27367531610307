import React, { Component } from 'react';
import { connect } from "react-redux";
import './Main.css';
import { Container, Row, Col, Button, ButtonGroup, ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import MapBrowser from './map';
import Summary from '../summary/Summary';
import Summary2 from '../summary/Summary2';
import SummaryRowing from '../summary/SummaryRowing';
import Table from 'react-bootstrap/Table';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip } from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Practices from '../../models/Practices';
import Loading from '../Loading';
import { main, teamList, playerSelector } from "../../actions";
import { Tab, Tabs } from 'react-bootstrap';
import Polar from './polar/polar';
import Motion from './motion/Motion';
import PropTypes from 'prop-types';
import TeamList from '../sidePanel/Team/TeamList';
import Slider from "../slider/Slider";
import {to_d, copyToClipboard} from '../helpers';

const dispItems = [
  { "id": 0, "name": "SPEED", "value": 0, "dataKeyStb": "spd_s", "dataKeyPt": "spd_p", "unit": "km/h" },
  { "id": 1, "name": "VMG", "value": 0, "dataKeyStb": "vmg_s", "dataKeyPt": "vmg_p", "unit": "km/h" },
  { "id": 2, "name": "ANGLE", "value": 0, "dataKeyStb": "ang_s", "dataKeyPt": "ang_p", "unit": "deg" },
  { "id": 3, "name": "HEAD", "value": 0, "dataKeyStb": "hed_s", "dataKeyPt": "hed_p", "unit": "deg" },
  { "id": 4, "name": "WIND", "value": 0, "dataKeyStb": "wds_s", "dataKeyPt": "wds_p", "unit": "m/s" },
  { "id": 5, "name": "WIND", "value": 0, "dataKeyStb": "wdd_s", "dataKeyPt": "wdd_p", "unit": "deg" },
  { "id": 6, "name": "PITCH", "value": 0, "dataKeyStb": "pit_s", "dataKeyPt": "pit_p", "unit": "deg" },
  { "id": 7, "name": "ROLL", "value": 0, "dataKeyStb": "rol_s", "dataKeyPt": "rol_p", "unit": "deg" },
  { "id": 8, "name": "M_DIST", "value": 0, "dataKeyStb": "dis_s", "dataKeyPt": "dis_p", "unit": "m" },
  { "id": 9, "name": "APPR", "value": 0, "dataKeyStb": "vel_s", "dataKeyPt": "vel_p", "unit": "m/s" },
  { "id": 10, "name": "UPWIND", "value": 0, "dataKeyStb": "mupd_s", "dataKeyPt": "mupd_p", "unit": "m" },
  { "id": 11, "name": "DIST", "value": 0, "dataKeyStb": "dist_s", "dataKeyPt": "dist_p", "unit": "m" },
];

const memberItems = [
  { "id": 0, "name": "SPEED", "value": 0, "dataKey": "m_spd", "dataKeyStb": "m_spd_s", "dataKeyPt": "m_spd_p", "unit": "km/h" },
  { "id": 1, "name": "VMG", "value": 0, "dataKey": "m_vmg", "dataKeyStb": "m_vmg_s", "dataKeyPt": "m_vmg_p", "unit": "km/h" },
  { "id": 2, "name": "ANGLE", "value": 0, "dataKey": "m_ang", "dataKeyStb": "m_ang_s", "dataKeyPt": "m_ang_p", "unit": "deg" },
  { "id": 3, "name": "HEAD", "value": 0, "dataKey": "m_hed", "dataKeyStb": "m_hed_s", "dataKeyPt": "m_hed_p", "unit": "deg" },
  { "id": 4, "name": "WIND", "value": 0, "dataKey": "m_wds", "dataKeyStb": "m_wds_s", "dataKeyPt": "m_wds_p", "unit": "m/s" },
  { "id": 5, "name": "WIND", "value": 0, "dataKey": "m_wdd", "dataKeyStb": "m_wdd_s", "dataKeyPt": "m_wdd_p", "unit": "deg" },
  { "id": 6, "name": "PITCH", "value": 0, "dataKey": "m_pit", "dataKeyStb": "m_pit_s", "dataKeyPt": "m_pit_p", "unit": "deg" },
  { "id": 7, "name": "ROLL", "value": 0, "dataKey": "m_rol", "dataKeyStb": "m_rol_s", "dataKeyPt": "m_rol_p", "unit": "deg" },
  { "id": 8, "name": "M_DIST", "value": 0, "dataKey": "m_dis", "dataKeyStb": "m_dis_s", "dataKeyPt": "m_dis_p", "unit": "m" },
  { "id": 9, "name": "APPR", "value": 0, "dataKey": "m_vel", "dataKeyStb": "m_vel_s", "dataKeyPt": "m_vel_p", "unit": "m/s" },
  { "id": 10, "name": "UPWIND", "value": 0, "dataKey": "m_mupd", "dataKeyStb": "m_mupd_s", "dataKeyPt": "m_mupd_p", "unit": "m" },
  { "id": 11, "name": "DIST", "value": 0,  "dataKey": "m_dist", "dataKeyStb": "m_dist_s", "dataKeyPt": "m_dist_p", "unit": "m" },
];

const dataLog = [
];


//現在のプラクティスorレグIDを保持、変更があった場合、必要な初期化処理をDidupdateにて実施
var prevPracticeId = null;
var prevLegId = null;
var upd_time = null;
var prevPractices = null;
var prevUserId = null;

//現在表示中のTabを保持
var activeTab = null;

//Map表示用デバイスクラス的な
function Device(id, spd, vmg, ang, pos, rotate, lines, main, wind, wds, wdd, all_lines, name, unit, wind_unit) {
  this.id = id;
  this.spd = spd;
  this.vmg = vmg;
  this.ang = ang;
  this.pos = pos;
  this.rotate = rotate;
  this.all_lines = all_lines;
  this.lines = lines;
  this.main = main;
  this.wind = wind;
  this.windspd = wds;
  this.winddir = wdd;
  this.isStarboard = true;
  this.name = name;
  this.unit = unit;
  this.wind_unit = wind_unit;
}


class Track extends React.Component {

  static propTypes = {
    handleSelectTargetPracticesOrLeg: PropTypes.func,
  }

  //Variable
  ws = null;
  firstGps = true;

  //Constractor
  constructor(props) {
    super(props);
    this.state = {
      list: dispItems,
      device: "7777",
      graph: dataLog,
      limit: 600,
      isToggleOn: false,
      dataKeyId: 0,
      dataKeyStb: "spd_s",
      dataKeyPt: "spd_p",
      dataKeyMember: "m_spd",
      center: { lat: 35.301318, lng: 139.480219 },
      windspeed: 0,
      winddir: 0,
      windpos: { lat: 0, lng: 0 },
      deviceMap: {
        7777:
        {
          id: '7777',
          spd: 0,
          vmg: 0,
          ang: 0,
          pos: [0, 0],
          rotate: 0,
          lines: [],
          unit: "km/h",
          wind_unit: "m/s",
        }
      },
      isStarboard: true,
      isAuto: true,
      loading: false,
      now_t: "00:00:00",
      end_t: "00:00:00",
      select_from_t: "00:00:00.0",
      select_to_t: "00:00:00.0",
      yaxis: false,
      selectedSpd: 0,
      selectedDir: 0,
      memberPolar: null,
      test: 0,
      graphHeight: 10,
      polarSize: 250,
      zoom: 15,
      isPC: true,
      gridIndex: 0,
      calcLine: [],
      selectionRange: {min:0, max:this.props.gpsLog ? this.props.gpsLog.length - 1 : 0},
      dataRange: { min: 0, max:this.props.gpsLog ? this.props.gpsLog.length - 1 : 0, step: 1 }
    };


    this.handleMouseMoveOnGraph = this.handleMouseMoveOnGraph.bind(this);
    this.handleSelectTabs = this.handleSelectTabs.bind(this);
    this.windowSizecheck = this.windowSizecheck.bind(this);
    this.handleSelectTargetPracticesOrLeg = this.handleSelectTargetPracticesOrLeg.bind(this);
    this.setSlider = this.setSlider.bind(this)
    this.handleSelectDataRange = this.handleSelectDataRange.bind(this)
    this.handleClickSelectTime = this.handleClickSelectTime.bind(this)
    this.handleClickCopyUpData = this.handleClickCopyUpData.bind(this)
    this.handleClickCopyDwData = this.handleClickCopyDwData.bind(this)
    this.handleClickCopyMotionData = this.handleClickCopyMotionData.bind(this)

  }


  componentDidMount() {
    let newDevMap = this.state.deviceMap;
    delete newDevMap[7777];//delete

    this.windowSizecheck();
    window.addEventListener('resize', this.windowSizecheck);
  }

  windowSizecheck() {
    var graphSize = 0;
    var polarSize = 0;
    var zoom = 0;
    var isPC = true;
    // @media screen and (max-width: 780px) と同じ
    var mql = window.matchMedia('screen and (max-width: 992px)');

    function checkBreakPoint(mql) {
      if (mql.matches) {//Mobile
        graphSize = 170;
        polarSize = 190;
        zoom = 14;
        isPC = false;
      } else {//PC
        graphSize = 250;
        polarSize = 250;
        zoom = 15;
        isPC = true;
      }
    }
    // ブレイクポイントの瞬間に発火
    mql.addListener(checkBreakPoint);

    // 初回チェック
    checkBreakPoint(mql);

    this.setState({
      graphHeight: graphSize,
      polarSize: polarSize,
      zoom: zoom,
      isPC: isPC,
    });

  }



  //グラフ上のマウスオーバーを検知
  handleMouseMoveOnGraph(event) {

    let index = event.activeTooltipIndex;

    if (index == undefined) {
      return;
    } else {
      //Trackingモードタブのみ、リストを更新する
      this.updateListData(index);
      this.updateMapData(index);
    }

		//データ選択区間の自動化
		let r = this.state.selectionRange;
		let new_max = r.max;
		if(index > r.max){
			new_max = this.props.gpsLog.length - 1;
		}

    let selectedPoint = { firstPoint: index, secondPoint: new_max };
    let selectedStartTime = this.props.gpsLog[selectedPoint.firstPoint].time
    let selectedEndTime   = this.props.gpsLog[selectedPoint.secondPoint].time

		this.setState(state => ({
			select_from_t: selectedStartTime,
			select_to_t:   selectedEndTime,
			selectionRange: {min: index, 
											 max: new_max,
											}
			}));
  }


  //Tabsのイベント
  handleSelectTabs(eventKey) {
    this.activeTab = eventKey;
  }

  //練習日送り・戻しボタンのイベント
  handleClickLeg(currentTarget, handleSelectTargetLeg, direction) {
    //Main.jsのtargetのPractices内のインデックスを取得
    var legIndex = currentTarget.practice.legs._legs.findIndex((p) => p.id === currentTarget.id);
    legIndex = legIndex + direction;
    let newTarget = currentTarget.practice.legs._legs[legIndex];
    handleSelectTargetLeg(newTarget);
  }

  //グラフ表示データの切り替え
  changeGraph(id) {
    let disp = this.state.list;

    this.setState(state => ({
      dataKeyId: id,
      dataKeyStb: disp[id].dataKeyStb,
      dataKeyPt: disp[id].dataKeyPt,
      dataKeyMember: memberItems[id].dataKey,
    }));

    //グラフデータの再作成
    let ary = this.props.gpsLog;
    if (id <= 3) {
      if (id == 2) {
        this.setState({
          yaxis: true,
        });
      } else {
        this.setState({
          yaxis: false,
        });
      }
    } else if (id <= 5) {
      ary = this.props.windLog;
      this.setState({
        yaxis: false,
      });
    } else if (id <= 7) {
      ary = this.props.motionLog;
      this.setState({
        yaxis: false,
      });
    } else if (id <= 9) {
      ary = this.props.buoyLog;
      this.setState({
        yaxis: false,
      });
    } else if (id <= 10) {
      ary = this.props.max_up_distance;
      this.setState({
        yaxis: false,
      });
    } else if (id <= 11) {
      ary = this.props.gpsLog;
      this.setState({
        yaxis: false,
      });
    }

    if (ary != undefined) {
      //Refresh!
      this.setState({
        graph: ary.slice(),
      });
    }
    this.setState({
      gridIndex: id,
    });
  }
  initListData() {
    this.updateListData(0);

    //スライドバー時間の初期化
    this.setState({
      select_from_t: this.props.gpsLog ? this.props.gpsLog[0].time : "00:00:00.0",
      select_to_t: this.props.gpsLog ? this.props.gpsLog[this.props.gpsLog.length-1].time : "00:00:00.0",
      dataRange: { min: 0, max:this.props.gpsLog ? this.props.gpsLog.length - 1 : 0, step: 1 },
      selectionRange: {min:0, max:this.props.gpsLog ? this.props.gpsLog.length - 1 : 0},
      calcLine : [],
    });

  }


  //性能対策（表示点数の抑制）
  reduceData( ary ){
    let size = 60*60;
    let disp_ary = [];
    if (ary.length > size) {
      let d = parseInt(ary.length / size);
      for (let i = 0; i < ary.length; i += d) {
        disp_ary.push(ary[i]);
      }
    }
    else
      disp_ary = ary;
    return disp_ary;
  }


  //初期表示時のデータ表示
  updateListData(i) {
    let graph = this.state.list;
    //Wind
    let g = this.props.gpsLog[i];
    let w = this.props.windLog[i];
    let m = this.props.motionLog[i];
    let d = this.props.buoyLog[i];
    let u = this.props.max_up_distance[i];

    let spd, vmg, ang, hed, pit, rol, dis, vel, wspd = 0, wdir = 0, upd, dist;
    spd = g.spd;
    vmg = g.vmg;
    ang = g.ang;
    hed = g.hed;
    wspd = w.wds;
    wdir = w.wdd;
    pit = m.pit;
    rol = m.rol;
    dis = d.dis;
    vel = d.vel;
    upd = u.mupd;
    dist = g.dist;
    graph[0].value = spd;
    graph[1].value = vmg;
    graph[2].value = ang;
    graph[3].value = hed;
    graph[4].value = wspd;
    graph[5].value = wdir;
    graph[6].value = pit;
    graph[7].value = rol;
    graph[8].value = dis;
    graph[9].value = vel;
    graph[10].value = upd;
    graph[11].value = dist;
    //stateの更新
    this.setState({
      list: graph.slice(),
      windspeed: wspd,
      winddir: wdir,
      now_t: g.time,
      end_t: this.props.gpsLog[this.props.gpsLog.length - 1].time,
    });
  }


  //MemberのGPS航跡
  initMemberDeviceMap() {
    var p = this.props;
    var practices;

    if (p.practices) {
      practices = p.practices;
      ///前回のPracticesを記憶
      prevPractices = p.practices;
    } else if (prevPractices) {
      practices = prevPractices;
    } else
      return;

    //check unload practice form json
    var i = 0;
    for (i = 0; i < practices.length; i++) {
      if (practices[i].gpses[0] == undefined) {
        return;
      }
    }

    var st = this.props.target.started_at;
    var et = this.props.target.ended_at;

    var all_st = this.props.target.started_at;
    var all_et = this.props.target.ended_at;

    if (!this.props.target.practice) {
    }
    else {// or Leg
      all_st = this.props.target.practice.started_at;
      all_et = this.props.target.practice.ended_at;
    }

    var line = [];
    var all_line = [];
    var dev;
    //deviceMapの更新
    let newDevMap = this.state.deviceMap;
    practices.forEach(function (m) {
      m.gpses.forEach((mg) => {
        if ((all_st <= mg.time) && (mg.time <= all_et)) {
          all_line.push([mg.lat, mg.lon]);
        }
        if ((st <= mg.time) && (mg.time <= et)) {
          line.push([mg.lat, mg.lon]);
        }
      });

      //デバイスクラスの作成(GPSの１個目をマーカー位置)
      dev = new Device(m.user.id, 0, 0, 0,
        { lat: null, lng: null }, 0, line,
        false, false,
        0, 0, all_line, m.user.last_name);
      newDevMap[m.user.id] = dev;//update
    });

    //stateの更新
    this.setState({
      deviceMap: newDevMap,
    });
  }


  //Mapの初期表示状態の制御
  initDeviceMap() {
    this.updateMapData(0);
  }

  updateMapDataUsingTime(time) {
    this.updateMapData(0);
  }


  updateMapData(i) {
    var p = this.props;
    var s = this.state;

    var g = this.props.gpsLog[i];
    var m = this.props.motionLog[i];
    var w = this.props.windLog[i];
    var dev;
    var mp = null;
    //デバイスクラスの作成(GPSの１個目をマーカー位置)
    dev = new Device(p.target.user.id, g.spd, g.vmg, g.ang,
      { lat: p.target.gpsLine[i][0], lng: p.target.gpsLine[i][1] }, g.hed, p.target.gpsLine,
      true, false,
      w.wds, w.wdd, p.target.gpsAllLine, p.target.user.last_name, p.target.user.unit, p.target.user.wind_unit);

    //deviceMapの更新
    let newDevMap = this.state.deviceMap;
    newDevMap[p.target.user.id] = dev;//update

    //MemberのPracticesがあるかどうか
    if (p.practices) {
      p.practices.forEach(function (m) {
        if (s.deviceMap[m.user.id]) {

          //memberのpolarで配列を作る
          if (m.polar)
            mp = m.polar;

          m.gpsLog.forEach((mg, index) => {
            if (mg.time == g.time) {
              let gg = m.gpsLog[index];
              let mm = m.motionLog[index];
              let ww = m.windLog[index];
              let dd = new Device(m.user.id, gg.spd, gg.vmg, gg.ang,
                { lat: m.gpsLine[index][0], lng: m.gpsLine[index][1] }, gg.hed, s.deviceMap[m.user.id].lines,
                false, false,
                ww.wds, ww.wdd, s.deviceMap[m.user.id].all_lines, m.user.last_name, m.user.unit, m.user.wind_unit);
              newDevMap[m.user.id] = dd;//update

              return;
            }
          });
        } else {
          if (mp)
            mp = null;
        }
      });
    }

    //Polarに渡す風上に対する角度を計算
    let polar_angle = g.hed - w.wdd;
    if (polar_angle < 0)
      polar_angle = 360 + polar_angle

    //stateの更新
    this.setState({
      deviceMap: newDevMap,
      center: this.getMapCenter(p.target.gpsLine),
      selectedSpd: g.spd,
      selectedDir: polar_angle,
      memberPolar: mp,
    });

  }

  getMapCenter(gpses) {
    var lat = 0;
    var lon = 0;
    gpses.forEach((gps) => {
      lat += gps[0];
      lon += gps[1];
    });
    lat = lat / gpses.length;
    lon = lon / gpses.length;
    return ({ lat: lat, lng: lon });
  }

  //Practice or Legデータロード後に呼び出される
  //componentWillReceiveProps(nextProps) {
  componentDidUpdate() {

    // Typical usage (don't forget to compare props):
    if (!this.props.targetUser) {
      this.props.loaded();
      return;
    }

    //前回のtargetUserが異なっていたら、このタイミングでDeviceリストを強制リセット
    if (prevUserId != this.props.targetUser.id) {
      //前回の履歴はクリア
      prevPracticeId = null;
      prevLegId = null;
      upd_time = null;
      prevUserId = null;
      prevPractices = null;

      //Deviceリストも初期化する(切り替え直後がtarget==undefinedという前提で、以後のState変更ロジックが動かない想定
      let newDevMap = {};
      this.setState({
        deviceMap: newDevMap,
      });
      prevUserId = this.props.targetUser.id;
      return;
    }


    if (this.props.target == undefined) {
      return;
    } else {
      //reset if practice changed
      if ((prevPracticeId != this.props.target.id) || (prevLegId != this.props.target.currentLeg)
        || (upd_time != this.props.upd_time)) {
        this.initListData();
        this.initDeviceMap();
        this.changeGraph(this.state.gridIndex);
        this.initMemberDeviceMap();
      }
      //updete now Practice ID
      prevPracticeId = this.props.target.id;
      prevLegId = this.props.target.currentLeg;
      upd_time = this.props.upd_time;
      prevUserId = this.props.target.user.id;

    }

  }

  handleSelectTargetPracticesOrLeg(target) {
    this.loadTarget({ target: target, user: this.props.targetUser });
  }

  //PracticeまたはLegのロード
  loadTarget({ target }) {
    this.props.loading();

    if (target != undefined) {
      target.themeColor1 = '#ff5100';
      target.themeColor2 = '#3a5a98';
      this.setState({
        isLoaded: false,
      });
      target.load({ onSuccess: this.finalizeLoadTarget, props: this.props });
    } else {
      //targetの更新
      this.props.updateTarget({ target: undefined });
      this.setState({
        isLoaded: true,
      });
    }
  }

  //コールバック関数
  finalizeLoadTarget(target, props) {

    props.getCalcPattern({ target: target });//Leg分割パターンの取得

    if (props.compare) {
      props.updateCompare({ target: target });
      return;
    }
    else {
      props.updatePolar({ target: target });
    }


    if (!props.members) {
      //update Graph data
      props.updateDispData({ target: target, user: props.targetUser });
    } else {
      //or If already selected member
      var flg = false;
      props.members.forEach((member) => {
        if ((member.isActive) && (target)) {
          props.getMembersPractices({ target: target, from: target.started_at, to: target.ended_at, member: member, user: props.targetUser });
          flg = true;
        }
      });

      //Activeが１つもないまたは、targetがないとき、普通にロードする。
      if (!flg)
        props.updateDispData({ target: target, user: props.targetUser });
    }
    props.loaded();
  }

  reLoadMap() {
  }


  handleSelectDataRange(event) {
    let selectedPoint = { firstPoint: event[0], secondPoint: event[1] };
    let selectedStartTime = this.props.gpsLog[selectedPoint.firstPoint].time
    let selectedEndTime   = this.props.gpsLog[selectedPoint.secondPoint].time
		this.setState(state => ({
			select_from_t: selectedStartTime,
			select_to_t:   selectedEndTime,
			}));

		//統計値の再計算
		this.props.reCalculationSummary({target: this.props.target, from_idx: selectedPoint.firstPoint, to_idx: selectedPoint.secondPoint});

		//Map上のラインの表示
		let gpsLine = []
		for(let i=selectedPoint.firstPoint; i <= selectedPoint.secondPoint; i++){
			let gps = this.props.target.gpses[i];
			gpsLine.push([gps.lat, gps.lon]);
		}
		this.setState({
			calcLine : gpsLine
		});
  }

	setSlider(points) {
		if(points[1] - points[0]+1 < 10){
			alert("Data length must be at least 10 seconds.");
			return;
		}
		this.setState(state => ({
			selectionRange: {min: points[0], 
											 max: points[1]},
		}));
	}


  //秒数指定ボタン
  handleClickSelectTime(t) {
		let r = this.state.selectionRange;
		let max = this.props.gpsLog.length - 1
		let new_max = max;
		if(r.min + t-1 < max){
			new_max = r.min + t-1
		}
    let selectedEndTime   = this.props.gpsLog[new_max].time
		this.setState(state => ({
			select_to_t:   selectedEndTime,
			selectionRange: {min: r.min, 
											 max: new_max,
			}
		}));
    let selectedPoint = { firstPoint: r.min, secondPoint: new_max };
		//統計値の再計算
		this.props.reCalculationSummary({target: this.props.target, from_idx: selectedPoint.firstPoint, to_idx: selectedPoint.secondPoint});
		//Map上のラインの表示
		let gpsLine = []
		for(let i=r.min; i <= new_max; i++){
			let gps = this.props.target.gpses[i];
			gpsLine.push([gps.lat, gps.lon]);
		}
		this.setState({
			calcLine : gpsLine
		});
  }

  //アップインドデータコピーボタン
  handleClickCopyUpData() {
		let out="Sail_no";
		console.log(this.props.target);
		out =  this.props.target.wind_direction + "\n";
		out += this.props.target.st.format("kk:mm:ss") + "\n";
		out += this.props.target.et.format("kk:mm:ss") + "\n\n";
		out += this.props.target.max_speed + "\n";
		out += this.props.target.max_10s_spd + "\n";
		out += this.props.target.ave_speed + "\n";
		out += this.props.target.ave_vmg_up;

		//クリップボードにコピー
		copyToClipboard(out);
  }

  //ダウンウインドデータコピーボタン
  handleClickCopyDwData() {
		let out="Sail_no";
		console.log(this.props.target);
		out =  this.props.target.wind_direction + "\n";
		out += this.props.target.st.format("kk:mm:ss") + "\n";
		out += this.props.target.et.format("kk:mm:ss") + "\n\n";
		out += this.props.target.max_speed + "\n";
		out += this.props.target.max_10s_spd + "\n";
		out += this.props.target.ave_speed + "\n";
		out += this.props.target.ave_vmg_down;

		//クリップボードにコピー
		copyToClipboard(out);

  }

  //Pitch/Rollデータコピーボタン
  handleClickCopyMotionData() {
		console.log(this.props.target);
		let out="";
		let r = this.state.selectionRange;
		//Map上のラインの表示
		let gpsData = []
		for(let i=r.min; i <= r.max; i++){
			let gps = this.props.target.gpses[i];
			out += gps.time.format("kk:mm:ss") + "\t";
			out += gps.speed + "\t";
			out += gps.pitch + "\t";
			out += gps.roll + "\n";
		}

		//クリップボードにコピー
		copyToClipboard(out);
  }



  render() {
    if (this.props.target == undefined) {
      return <Loading />;
    }

    var GraphArea;
    const rowStyle = { padding: '1px' };
    var graphStyle = { width: '100%', height: this.state.graphHeight, padding: '1px' };
    GraphArea = (
      <div style={graphStyle}>
        <ResponsiveContainer width="100%" height="100%" minHeight={100}>
          <LineChart data={this.state.graph} onMouseMove={this.handleMouseMoveOnGraph} >
            <YAxis reversed={this.state.yaxis} />
            <Tooltip />
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            <Line type="monotone" dataKey={this.state.dataKeyStb} stroke="#006400" isAnimationActive={false} />
            <Line type="monotone" dataKey={this.state.dataKeyPt} stroke="#dc143c" isAnimationActive={false} />
            <Line type="monotone" dataKey={this.state.dataKeyMember} stroke="#a9a9a9" isAnimationActive={false} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );

    this.props.GraphArea(GraphArea);


    let polar_tab = (
      <Tab eventKey="polar" title="Polar">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-lg-7">
              <Polar polarSize={this.state.polarSize} polar={this.props.target.polar} memberPolar={this.state.memberPolar} selectedDir={this.state.selectedDir} selectedSpd={this.state.selectedSpd} maxVmg={this.props.target.max_vmg} test={this.state.test} />
            </div> {/*col*/}
            <div className="d-none d-lg-block col-lg-5" >
              <Summary target={this.props.target} list={this.state.list} authUser={this.props.authUser} />
            </div> {/*col*/}
          </div> {/*row*/}
        </div> {/*container*/}
      </Tab>
    )

    let sailing_tab = (
      <Summary2 target={this.props.target} list={this.state.list} authUser={this.props.authUser} />
    )

    let rowing_tab = (
      <SummaryRowing target={this.props.target}  authUser={this.props.authUser} />
    )

    let sailing_table = (
      <Table striped bordered hover variant="dark">
        <tbody >
          <tr>
            <td className={this.state.dataKeyId === 0 ? 'Active2' : 'NotActive2'} onClick={this.changeGraph.bind(this, 0)}>
              <span className="Unit2" >SPEED</span>
              <span className="summary">{this.state.list[0].value}</span>
              <span className="Unit2">{this.props.authUser.unit}</span>
            </td>
            <td className={this.state.dataKeyId === 1 ? 'Active2' : 'NotActive2'} onClick={this.changeGraph.bind(this, 1)}><span className="Unit2" >VMG</span>  <span className="summary">{this.state.list[1].value} </span> <span className="Unit2">{this.props.authUser.unit}</span></td>
          </tr>
          <tr>
            <td className={this.state.dataKeyId === 2 ? 'Active2' : 'NotActive2'} onClick={this.changeGraph.bind(this, 2)}><span className="Unit2" >ANGLE</span> <span className="summary">{this.state.list[2].value} </span><span className="Unit2">{this.state.list[2].unit}</span></td>
            <td className={this.state.dataKeyId === 3 ? 'Active2' : 'NotActive2'} onClick={this.changeGraph.bind(this, 3)}><span className="Unit2" >HEAD</span> <span className="summary">{this.state.list[3].value} </span><span className="Unit2">{this.state.list[3].unit}</span></td>
          </tr>
          <tr>
            <td className={this.state.dataKeyId === 4 ? 'Active2' : 'NotActive2'} onClick={this.changeGraph.bind(this, 4)}><span className="Unit2" >WIND</span> <span className="summary">{this.state.list[4].value} </span><span className="Unit2">{this.props.authUser.wind_unit}</span></td>
            <td className={this.state.dataKeyId === 5 ? 'Active2' : 'NotActive2'} onClick={this.changeGraph.bind(this, 5)}><span className="Unit2" >WIND</span> <span className="summary">{this.state.list[5].value} </span><span className="Unit2">{this.state.list[5].unit}</span></td>
          </tr>
          {true &&
            <tr>
              <td className={this.state.dataKeyId === 6 ? 'Active2' : 'NotActive2'} onClick={this.changeGraph.bind(this, 6)}><span className="Unit2" >PITCH</span> <span className="summary">{this.state.list[6].value} </span><span className="Unit2">{this.state.list[6].unit}</span></td>
              <td className={this.state.dataKeyId === 7 ? 'Active2' : 'NotActive2'} onClick={this.changeGraph.bind(this, 7)}><span className="Unit2" >HEEL</span>  <span className="summary">{this.state.list[7].value}</span> <span className="Unit2">{this.state.list[7].unit}</span></td>
            </tr>
          }
          <tr>
            <td className={this.state.dataKeyId === 8 ? 'Active2' : 'NotActive2'} onClick={this.changeGraph.bind(this, 8)}><span className="Unit2" >M_DIST</span> <span className="summary">{this.state.list[8].value} </span><span className="Unit2">{this.state.list[8].unit}</span></td>
            <td className={this.state.dataKeyId === 9 ? 'Active2' : 'NotActive2'} onClick={this.changeGraph.bind(this, 9)}><span className="Unit2" >APPR</span>  <span className="summary">{this.state.list[9].value}</span> <span className="Unit2">{this.props.authUser.unit}</span></td>
          </tr>
          <tr>
            <td className={this.state.dataKeyId === 10 ? 'Active2' : 'NotActive2'} onClick={this.changeGraph.bind(this, 10)}><span className="Unit2" >UPWIND</span> <span className="summary">{this.state.list[10].value} </span><span className="Unit2">{this.props.authUser.unit}</span></td>
            <td className={this.state.dataKeyId === 11 ? 'Active2' : 'NotActive2'} onClick={this.changeGraph.bind(this, 11)}><span className="Unit2" >DIST</span> <span className="summary">{this.state.list[11].value} </span><span className="Unit2">{this.state.list[11].unit}</span></td>
          </tr>
        </tbody>
      </Table>
    )

    let rowing_table = (
      <Table striped bordered hover variant="dark">
        <tbody >
          <tr>
            <td className={this.state.dataKeyId === 0 ? 'Active2' : 'NotActive2'} onClick={this.changeGraph.bind(this, 0)}><span className="Unit2" >SPEED</span> <span className="summary">{this.state.list[0].value} </span><span className="Unit2">{this.props.authUser.unit}</span></td>
            <td className={this.state.dataKeyId === 3 ? 'Active2' : 'NotActive2'} onClick={this.changeGraph.bind(this, 3)}><span className="Unit2" >HEAD</span> <span className="summary">{this.state.list[3].value} </span><span className="Unit2">{this.state.list[3].unit}</span></td>
          </tr>
          <tr>
            <td className={this.state.dataKeyId === 11 ? 'Active2' : 'NotActive2'} onClick={this.changeGraph.bind(this, 11)}><span className="Unit2" >DIST</span> <span className="summary">{this.state.list[11].value} </span><span className="Unit2">{this.state.list[11].unit}</span></td>
          </tr>
        </tbody>
      </Table>
    )



    return (
      <Container>
        <Row className="GridArea" >
          <Col xs={5} lg={5} >
            <MapBrowser
              zoom={this.state.zoom}
              deviceMap={this.state.deviceMap}
              center={this.state.center}
              windSpeed={this.state.windspeed} windDirection={this.state.winddir}
              windpos={this.state.windpos}
              buoy_coordinate={this.props.target.buoy_coordinate}
              authUser={this.props.authUser}
              calcLine={this.state.calcLine}
            />
          </Col>
          <Col xs={7} lg={7} >
            <Tabs
              transition={false}
              defaultActiveKey="summary"
            >
              <Tab eventKey="track" title="Track">
                {  this.props.authUser.organization.event.id == 1 ?  sailing_table : rowing_table }
              </Tab>
              { this.props.authUser.organization.event.id == 1 ?  polar_tab : "" }
              <Tab eventKey="motion" title="Motion">
                <div className="container">
                  <div className="row">
                    <div className="col-xs-6 col-lg-6">
                      <Motion title={"Pitch"} polarSize={this.state.polarSize} angle={this.state.list[6].value} value={this.state.list[6].value - 90} />
                    </div> {/*col*/}
                    <div className="col-xs-6 col-lg-6">
                      <Motion title={"Heel"} polarSize={this.state.polarSize} angle={this.state.list[7].value} value={this.state.list[7].value} />
                    </div> {/*col*/}
                  </div> {/*row*/}
                </div> {/*container*/}
              </Tab>

              <Tab eventKey="summary" title="Summary">
                {  this.props.authUser.organization.event.id == 1 ?  sailing_tab : rowing_tab }
              </Tab>


              <Tab eventKey="member" title="Member">
                <TeamList target={this.props.target} />
              </Tab>

            </Tabs>

          </Col>
        </Row>
        {/*練習日　送り・戻しボタン*/}
        <div className="row">
          <div className="col-1">
            <button disabled={this.props.prevButtonState} onClick={() => this.handleClickLeg(this.props.target, this.handleSelectTargetPracticesOrLeg, -1)}>Prev</button>
          </div> {/*col*/}
          <div className="col-1">
            <button disabled={this.props.nextButtonState} onClick={() => this.handleClickLeg(this.props.target, this.handleSelectTargetPracticesOrLeg, 1)}>Next</button>
          </div> {/*col*/}
          {this.state.isPC && <div className="col-1"></div>}
          <div className="col-8">
          </div> {/*col*/}
          <div className="col-1">
            <button disabled={this.props.nextButtonState} onClick={() => this.handleClickLeg(this.props.target, this.handleSelectTargetPracticesOrLeg, 1)}>Next</button>
          </div> {/*col*/}
        </div> {/*row*/}

        <Row style={rowStyle}>
          <Col >
            {this.props.isLoading ? (<FontAwesomeIcon icon={['fas', 'spinner']} size="3x" spin />) : ('')}
            {GraphArea}
          </Col >
        </Row>
        <Row>
          <Col xs={1}><span> {this.state.now_t} </span></Col>
          {this.state.isPC && <Col xs={1}></Col>}
          <Col xs={8}></Col>
          <Col xs={1}><span> {this.state.end_t} </span></Col>
        </Row>
        <Row>

          <Col xs={1}><span> {this.state.select_from_t} </span></Col>
          {this.state.isPC && <Col xs={1}></Col>}
          <Col xs={8}></Col>
          <Col xs={1}><span> {this.state.select_to_t} </span></Col>
        </Row>
        <Row>
          <Col xs={1}></Col>
					<Slider 
						dataRange={this.state.dataRange} 
						selectionRange={this.state.selectionRange} 
						handleSelectDataRange={this.handleSelectDataRange}
						setSlider={this.setSlider}
						/>
        </Row>
        <Row>
          <div className="col-1">
            <button onClick={() => this.handleClickSelectTime(20)}>20s</button>
          </div> {/*col*/}
          <div className="col-1">
            <button onClick={() => this.handleClickSelectTime(60)}>60s</button>
          </div> {/*col*/}
          <div className="col-1">
            <button onClick={() => this.handleClickSelectTime(40)}>40s</button>
          </div> {/*col*/}
          <div className="col-1">
            <button onClick={() => this.handleClickCopyUpData()}>UPCPY</button>
          </div> {/*col*/}
          <div className="col-1">
            <button onClick={() => this.handleClickCopyDwData()}>DWCPY</button>
          </div> {/*col*/}
          <div className="col-1">
            <button onClick={() => this.handleClickCopyMotionData()}>MotionCPY</button>
          </div> {/*col*/}
        </Row>

      </Container>
    );
  }
}


const mapStateToProps = state => {
  return {
    target:     state.PlayerSelector.target,
    targetUser: state.PlayerSelector.user,

    members:    state.TeamList.members,
    practices:  state.TeamList.practices,
    gpsLog:     state.TeamList.gpsLog,
    motionLog:  state.TeamList.motionLog,
    windLog:    state.TeamList.windLog,
    buoyLog:    state.TeamList.buoyLog,
    member:     state.TeamList.member, //target
    upd_time:   state.TeamList.upd_time,
    max_up_distance: state.TeamList.max_up_distance,

    isLoading:        state.Main.loading,
    prevButtonState:  state.Main.prevButtonState,
    nextButtonState:  state.Main.nextButtonState,
    authUser:         state.auth.user,
    is_track_mode:    state.Main.track_mode,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loading: () => dispatch(main.loading()),
    loaded: () => dispatch(main.loaded()),
    GraphArea: (graphArea) => dispatch(main.graphArea(graphArea)),
    getCalcPattern: ({ target }) => dispatch(main.getCalcPattern({ target })),
    updatePolar: ({ target }) => dispatch(main.updatePolar({ target })),
    updateCompare: ({ target }) => dispatch(main.updateCompare({ target })),
    updateDispData: ({ target, members_practices, user }) => dispatch(main.updateDispData({ target, members_practices, user })),
    getMembersPractices: ({ target, from, to, member, user }) => dispatch(teamList.getMembersPractices({ target, from, to, member, user })),
    reCalculationSummary: ({ target, from_idx, to_idx }) => dispatch(playerSelector.reCalculationSummary({ target, from_idx, to_idx })),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Track);
