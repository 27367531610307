import React, { Component, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Container, Table, Row, Col, Button, ButtonGroup, ToggleButton, ToggleButtonGroup, ListGroup, Form } from 'react-bootstrap';
import Loading from '../../../Loading';
import Map from './Map';
import API from '../../../API';
import { main } from "../../../../actions";
import Slider from "../../../slider/Slider";
import TimePicker from 'rc-time-picker';
import ReactDOM from 'react-dom';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import Moment from 'moment';
import ErasableTrack from './map/ErasableTrack';

const pointArray = { firstPoint: 0, secondPoint: 0 }
//Map表示用デバイスクラス的な
function Device(id, spd, vmg, ang, pos, rotate, lines, main, wind, wds, wdd, all_lines, name, unit, wind_unit) {
  this.id = id;
  this.spd = spd;
  this.vmg = vmg;
  this.ang = ang;
  this.pos = pos;
  this.rotate = rotate;
  this.all_lines = all_lines;
  this.lines = lines;
  this.main = main;
  this.wind = wind;
  this.windspd = wds;
  this.winddir = wdd;
  this.isStarboard = true;
  this.name = name;
  this.unit = unit;
  this.wind_unit = wind_unit;
}

/**
  *  Legの開始日時からの elapsedTime val を加算した hh:mm:ss 形式の時刻を返します。
  *  同じPlacticeの場合最初のLegから経過時間が増え続けるので、Legごとの最小経過時間で加算する経過時間を補正する
  *  グラフマウスオーバー時のグラフ左下の時刻、スライダー操作時のハンドルツールチップで表示することを想定しています。
  *  @param {*} val erapsed_time (leg.minElapsedTime - leg.maxElapsedTime)
  */

class ConfigForm extends Component {

  static propTypes = {
    practice: PropTypes.object,
    handleCloseExternalDataModal: PropTypes.func,
    user: PropTypes.object,
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      windDirection: this.props.practice.windDirection,
      windSpeed:  this.props.practice.wind_speed ? this.props.practice.wind_speed : 0,
      windSpeedMin:  this.props.practice.wind_speed_min ? this.props.practice.wind_speed_min : 0,
      calc_parm: this.props.practice.calc_parm,
      buoy_coordinate: this.props.practice.buoy_coordinate,
      practice_moments: [],
      selected_start_time: null,
      selected_end_time: null,
      started_at: null,
      ended_at: null,
      graphArea: null,
      selected_points: pointArray,
      alert: "",
      PalamResult: true,
      selectionRange: {min:0, max:this.props.practice.gpses.length-1},
      selectedGpses: [],
      event: this.props.user.organization.event.id,
      setting: {},
      setting_list: this.props.practice.setting ? this.props.practice.setting : [],
    };

    this.windConditionFileRef = React.createRef();
    this.windConditionTextRef = React.createRef();

    this.rudderFileRef = React.createRef();
    this.rudderTextRef = React.createRef();

    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.selectFileWindCondition = this.selectFileWindCondition.bind(this);
    this.selectFileRudder = this.selectFileRudder.bind(this);
    this.onChangeFileWindCondition = this.onChangeFileWindCondition.bind(this);
    this.onChangeRudder = this.onChangeRudder.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleRecalculate = this.handleRecalculate.bind(this);
    this.handleWindDirectionChange = this.handleWindDirectionChange.bind(this);
    this.handleWindSpeedChange = this.handleWindSpeedChange.bind(this);
    this.handleWindSpeedMinChange = this.handleWindSpeedMinChange.bind(this);
    //Radio イベント用メソッド
    this.handleRadioClick = this.handleRadioClick.bind(this);
    this.handleCalcParmChange = this.handleCalcParmChange.bind(this);
    //ブイ座標設定イベント
    this.handleClickOnMap = this.handleClickOnMap.bind(this);
    //時間設定イベント
    this.handleStartTime = this.handleStartTime.bind(this);
    this.handleEndTime = this.handleEndTime.bind(this);
    this.handleAddPracticeTime = this.handleAddPracticeTime.bind(this);
    this.handleRemovePracticeTime = this.handleRemovePracticeTime.bind(this);
    this.handleChangeStartTime = this.handleChangeStartTime.bind(this);
    this.handleChangeEndTime = this.handleChangeEndTime.bind(this);
    this.handleSelectDataRange = this.handleSelectDataRange.bind(this)
    this.setSlider = this.setSlider.bind(this)
    this.handleChangeEvent = this.handleChangeEvent.bind(this)
    //セッティング
    this.handleAddSetting = this.handleAddSetting.bind(this);
    this.handleChangeNewSettingItem = this.handleChangeNewSettingItem.bind(this);
    this.handleChangeNewSettingValue = this.handleChangeNewSettingValue.bind(this);
    this.handleChangeSettingItem = this.handleChangeSettingItem.bind(this);
    this.handleChangeSettingValue = this.handleChangeSettingValue.bind(this);
    this.handleRemoveSetting = this.handleRemoveSetting.bind(this);
    this.loadLastSetting = this.loadLastSetting.bind(this);
  }

  componentDidMount() {
    let pt = [];
    let practiceTimes = [];

    if (this.props.practice.practice_times) {
      pt = this.props.practice.practice_times.slice();

      pt.forEach(e => {
        practiceTimes.push({ "start_time": Moment(e.start_time), "end_time": Moment(e.end_time) })
      });
    }

    this.setState(state => ({
      practice_moments: practiceTimes,
      selected_start_time: this.props.practice.started_at,
      selected_end_time: this.props.practice.ended_at,
      started_at: this.props.practice.started_at,
      ended_at: this.props.practice.ended_at
    }));
  }

  //パラメータチェック
  parametercheck(selectedStartTime, selectedEndTime, index) {
    let alert = "";
    let result = true
    let startJudg = selectedStartTime - this.props.practice.started_at._d
    let endJudg = this.props.practice.ended_at._d - selectedEndTime

    if (selectedStartTime && selectedEndTime) {
      if (endJudg >= 0 && startJudg >= 0) {
        let timeDiff = selectedEndTime - selectedStartTime;
        if (timeDiff > 0) {

          let practiceMoments = this.state.practice_moments.slice();
          let i = 0;
          practiceMoments.forEach(e => {

            if (selectedStartTime < e.start_time && selectedEndTime < e.start_time) {

            } else if (selectedStartTime > e.end_time && selectedEndTime > e.end_time) {

            } else if (i != index) {
              result = false
              alert = "時間が重複しています"
            }
            i++
          });

          if (result) {
            this.setState(state => ({
              selected_start_time: selectedStartTime,
              selected_end_time: selectedEndTime,
            }));
          }

        } else {
          result = false
          alert = "終了時間は開始時間より後に設定してください"
        }

      } else {
        result = false
        alert = "練習時間内で設定してください"
      }
    }

    this.setState(state => ({
      PalamResult: result,
      alert: alert
    }));

    return result
  }

  handleStartTime(event) {
    //this.parametercheck(event, this.state.selected_end_time, null)
  }

  handleEndTime(event) {
    //this.parametercheck(this.state.selected_start_time, event, null)
  }

  handleSelectDataRange(event) {
    let selectedPoint = { firstPoint: event[0], secondPoint: event[1] };
    let selectedStartTime = this.props.practice.gpses[selectedPoint.firstPoint].time
    let selectedEndTime = this.props.practice.gpses[selectedPoint.secondPoint].time
    this.parametercheck(selectedStartTime, selectedEndTime, null)

    this.setState(state => ({
      selected_points: selectedPoint,
    }));

    var selectedGpses = [];
    let gpses = this.props.practice.gpses;
    for (let i = selectedPoint.firstPoint; i <= selectedPoint.secondPoint; i++) {
      selectedGpses.push(gpses[i]);
    }

    this.setState({
      selectedGpses : selectedGpses,
    });

  }

  handleAddPracticeTime() {
    if (this.state.PalamResult) {
      if(!this.parametercheck(this.state.selected_start_time, this.state.selected_end_time, null))
        return

      let practiceMoments = this.state.practice_moments.slice();
      practiceMoments.push({ "start_time": this.state.selected_start_time, "end_time": this.state.selected_end_time })
      this.state.selected_start_time = this.state.started_at;
      this.state.selected_end_time = this.state.ended_at;

      //Start auto select
      this.setState(state => ({
        practice_moments: practiceMoments,
        selectionRange: {min: this.state.selected_points.secondPoint+1, 
                         max: this.props.practice.gpses.length-1},
      }));

      if(this.state.selected_points.secondPoint < this.props.practice.gpses.length-1){
        this.setState(state => ({
          practice_moments: practiceMoments,
          //選択した次の時間と、データ最後の時間を自動でセット
          selected_start_time: this.props.practice.gpses[this.state.selected_points.secondPoint+1].time,
          selected_end_time: this.props.practice.gpses[this.props.practice.gpses.length-1].time,
          selectionRange: {min: this.state.selected_points.secondPoint+1, 
                           max: this.props.practice.gpses.length-1},
        }));
        let selectedGpses = [];
        let gpses  = this.props.practice.gpses

        for (var i = this.state.selected_points.secondPoint+1; i <= gpses.length-1; i++) {
          selectedGpses.push(gpses[i]);
        }
        this.setState({
          selectedGpses : selectedGpses,
        });
      }//End auto select

    }
  }

  handleRemovePracticeTime(index) {
    let practiceMoments = this.state.practice_moments.slice();
    practiceMoments.splice(index, 1)
    this.setState(state => ({
      practice_moments: practiceMoments,
    }));
  }

  handleChangeStartTime(event, index) {
    let practiceMoments = this.state.practice_moments.slice();

    if (this.parametercheck(event, practiceMoments[index].end_time, index)) {
      practiceMoments[index] = { "start_time": event, "end_time": practiceMoments[index].end_time }
      this.setState(state => ({
        practice_moments: practiceMoments,
      }));
    }
  }

  handleChangeEndTime(event, index) {
    let practiceMoments = this.state.practice_moments.slice();

    if (this.parametercheck(practiceMoments[index].start_time, event, index)) {
      practiceMoments[index] = { "start_time": practiceMoments[index].start_time, "end_time": event }
      this.setState(state => ({
        practice_moments: practiceMoments,
      }));
    }
  }

  /**
   * Practiceの更新
   */
  handleSubmit() {
    this.setState({ loading: true });
    let wind = this.windConditionFileRef.current;
    let rudder = this.rudderFileRef.current;


    let calc_mode;
    this.props.calc_patterns.forEach((ptn) => {
      if (ptn.checked) {
        calc_mode = ptn.id;
        return;
      }
    });

    let calc_parm = 0;
    if (this.state.calc_parm)
      calc_parm = this.state.calc_parm;
    if (calc_parm < 100)
      calc_parm = 100;

    let buoy_coordinate = [null, null]
    if (this.state.buoy_coordinate != [null]) {
      buoy_coordinate = this.state.buoy_coordinate
    }

    API.putPractice({
      user: this.props.user,
      practiceId: this.props.practice.id,
      windDirection: this.state.windDirection,
      windSpeed: this.state.windSpeed,
      windSpeedMin: this.state.windSpeedMin,
      calcMode: calc_mode,
      calcParm: calc_parm,
      buoy_coordinate: buoy_coordinate,
      practice_times: this.state.practice_moments,
      event_id: this.state.event,
      setting: this.state.setting_list,
      onSuccess: () => { this.handleFileUpload(wind, rudder); },
      onError: (error) => {
        console.log(error);
        alert(`Edit failed. ${error}`);
        console.log(error);
        this.props.handleCloseExternalDataModal();
      }
    });

  }

  /**
   * Practiceの削除
   */
  handleDelete() {
    this.setState({ loading: true });

    API.deletePractice({
      user: this.props.user,
      practiceId: this.props.practice.id,
      onSuccess: () => {
        alert('削除を受け付けました。データの反映には2,3分程度かかります。ブラウザを更新してください。');
        this.setState({ loading: false });
        this.props.handleCloseExternalDataModal();
      },
      onError: (error) => {
        console.error(error);
        this.setState({ loading: false });
        this.props.handleCloseExternalDataModal();
        alert('削除エラーが発生しました。');
      }
    });
  }


  /**
    * sub ファイルのアップロード
    */
  handleFileUpload(wind, rudder) {
    if (wind.files.length === 0 && rudder.files.length === 0) {
      // Just proceed to next step
      console.log('No wind and rudder');
      this.handleRecalculate();
      return;
    }
    let data = new FormData();

    let practiceTimes = this.state.practice_moments.slice()

    data.append('wind', wind.files[0]);
    data.append('rudder', rudder.files[0]);
    API.postExternalFileToPractice({
      user: this.props.user,
      practiceId: this.props.practice.id,
      files: data,
      onSuccess: this.handleRecalculate,
      onError: (error) => {
        alert(`Upload failed. ${error.response.data}`);
        console.log(error);
      },
      practiceTimes
    });
  }

  /**
    * sub 再計算
    */
  handleRecalculate() {
    API.recalculatePractice({
      user: this.props.user,
      practiceId: this.props.practice.id,
      onSuccess: () => {
        alert('アップロードが完了しました。データの反映には2,3分程度かかります。');
        this.setState({ loading: false });
        this.props.handleCloseExternalDataModal();
      },
      onError: (error) => {
        console.error(error);
        this.setState({ loading: false });
        this.props.handleCloseExternalDataModal();
      }
    });
  }


  //Map上の座標がクリックされたら呼び出される
  handleClickOnMap = function (lonlat) {

    this.setState({
      buoy_coordinate: lonlat,
    });
  }


  /**
   * ラジオボタンをクリック
   */
  handleRadioClick(e) {
    let name = e.target.name;
    this.props.setCalcPattern(this.props.calc_patterns, name);
  }

  handleCalcParmChange(event) {
    this.setState({ calc_parm: parseInt(event.target.value) });
  }

  selectFileWindCondition() {
    this.windConditionFileRef.current.click();
  }

  selectFileRudder() {
    this.rudderFileRef.current.click();
  }

  noticeRudder() {
    if (this.props.practice.hasRudder) {
      return (<span>{'すでに登録済みのデータがあるため、上書きされます'} </span>);
    } else {
      return (<span> </span>);
    }
  }

  noticeWind() {
    if (this.props.practice.hasWind) {
      return (<span>{'すでに登録済みのデータがあるため、上書きされます'} </span>);
    } else {
      return (<span> </span>);
    }
  }

  onChangeFileWindCondition() {
    // ファイルが選択されたら、ファイル名をテキストボックスへ設定
    this.windConditionTextRef.current.value = this.windConditionFileRef.current.value.split(/[\\/]/).pop() || '';
  }

  onChangeRudder() {
    // ファイルが選択されたら、ファイル名をテキストボックスへ設定
    this.rudderTextRef.current.value = this.rudderFileRef.current.value.split(/[\\/]/).pop() || '';
  }

  handleWindDirectionChange(event) {
    this.setState({ windDirection: parseInt(event.target.value) });
  }

  handleWindSpeedChange(event) {
    this.setState({ windSpeed: event.target.value });
  }

  handleWindSpeedMinChange(event) {
    this.setState({ windSpeedMin: event.target.value });
  }

  handleChangeEvent(event) {
    this.setState({ event: event.target.value });
  }

  formatMomentToText(time) {
    if (time) {
      return time.format("HH:mm:ss")
    }
  }

  setSlider(points){
    this.setState(state => ({
      selectionRange: {min: points[0], 
                       max: points[1]},
    }));
  }

  //--- セッティング ------------------------------------
  handleAddSetting() {
    let setting_list = this.state.setting_list;
    setting_list.push(this.state.setting)
    this.setState({
      setting_list : setting_list,
    });
  }

  handleRemoveSetting(index) {
    let setting_list = this.state.setting_list.slice();
    setting_list.splice(index, 1)
    this.setState(state => ({
      setting_list: setting_list,
    }));
  }

  handleChangeNewSettingItem(e, index) {
    let setting = this.state.setting;
    let key = e.target.value
    let ns = {}
    if(Object.keys(setting).length > 0){
      let old = setting
      let old_key = Object.keys(setting)[0]
      ns[key] = old[old_key]
    } else {
      ns[key] = ""
    }
    setting = ns
    this.setState(state => ({
      setting: setting,
    }));
  }

  handleChangeNewSettingValue(e, index) {
    let setting = this.state.setting;
    let val = e.target.value
    let ns = {}
    if(Object.keys(setting).length > 0){
      let old = setting
      let key = Object.keys(setting)[0]
      ns[key] = val
    } else {
      ns["noname"] = val
    }
    setting = ns
    this.setState(state => ({
      setting: setting,
    }));
  }

  handleChangeSettingItem(e, index) {
    let setting_list = this.state.setting_list.slice();
    let key = e.target.value
    let ns = {}
    if(setting_list.length > 0){
      let old = setting_list[index]
      let old_key = Object.keys(setting_list[index])[0]
      ns[key] = old[old_key]
    } else {
      ns[key] = ""
    }
    setting_list[index] = ns
    this.setState(state => ({
      setting_list: setting_list,
    }));
  }

  handleChangeSettingValue(e, index) {
    let setting_list = this.state.setting_list.slice();
    let val = e.target.value
    let ns = {}
    if(setting_list.length > 0){
      let old = setting_list[index]
      let key = Object.keys(setting_list[index])[0]
      ns[key] = val
    } else {
      ns["noname"] = val
    }
    setting_list[index] = ns
    this.setState(state => ({
      setting_list: setting_list,
    }));
  }

  loadLastSetting() {
    let cbfunc = function(setting_list, state) {
      state.setState({
        setting_list: setting_list ? setting_list : [], 
      });
    }
    this.props.loadLastSetting({cbfunc:cbfunc, state:this});
  }

  //--- end セッティング ------------------------------------



  render() {
    if (!this.props.practice || this.state.loading) {
      return (<em>Loading...</em>);
    }

    //-----------------------------------------------
    const elapsedTimeToTimestamp = (val) => {
      return Moment(this.props.practice.gpses[val].time).utcOffset('+0900').format('HH:mm:ss');
    };


    //Radio Button
    const radio = this.props.calc_patterns;
    const listItems = radio.map((r) =>
      <label key={r.id} >
        <input type="radio" name={r.id} value={r.id} checked={r.checked} onChange={this.handleRadioClick} />
        {r.description}
      </label>
    );

    //List box
    let Options = function(e) {
        return  <option key={e.id} value={e.id}>{e.name} </option>;
    };

    let event_id = this.props.user.organization.event.id;
    if(this.props.practice.event)
      event_id = this.props.practice.event.id;

    const event_types = (
      <Form.Group>
        <label htmlFor="event">Select training type.</label>
        <Form.Control as="select" onChange={this.handleChangeEvent} defaultValue={event_id} >
          {this.props.events.map(Options)}
        </Form.Control>
      </Form.Group>
    );


    //分割時間指定用
    const practiceMoments = this.state.practice_moments.map((r, index) =>
      <div className='settings-elm' key={index}>
        <TimePicker
          value={r.start_time}
          onChange={(e) => this.handleChangeStartTime(e, index)}
        />
        <TimePicker
          value={r.end_time}
          onChange={(e) => this.handleChangeEndTime(e, index)}
        />

        <button
          onClick={() => this.handleRemovePracticeTime(index)}
          className='settings-file-btn'
        >削除</button>
      </div>
    )

    //セッティング用
    const setting = this.state.setting_list.map((r, index) =>
      <div className='settings-elm' key={index}>
          <table>
            <tbody>
              <tr>
                <td> <Form.Control type="text" placeholder="項目" value={Object.keys(r)[0]} onChange={(e) => this.handleChangeSettingItem(e, index)}/> </td>
                <td> <Form.Control type="text" placeholder="値" value={r[Object.keys(r)[0]]} onChange={(e) => this.handleChangeSettingValue(e, index)} /> </td>
                <td>
                  <button
                    onClick={() => this.handleRemoveSetting(index)}
                    className='settings-file-btn'
                  >削除</button>
                </td>
              </tr>
            </tbody>
          </table>
      </div>
    )


    //グラフ描画
    var dataRange = {
      min: 0,
      max: this.props.practice.gpses.length - 1,
      step: 1
    }

    const slider = {
      "marginLeft": 60, "marginRight": 4.1
    };

    return (
      <Container>
        <h1>{'練習' + this.props.practice.id}</h1>

        <hr className='settings-hr' />

        <Row>
          <Col xs={6} lg={6} >
            <span>
              <div className='settings-item'>
                <div className='settings-lbl'>
                  <span className='settings-lbl-txt'>分割タイプを変更する</span>
                </div>
                <div className='settings-elm'>
                  {listItems}
                </div>
              </div>
            </span>
            <span>
              <div className='settings-item'>
                <div className='settings-lbl'>
                  <span className='settings-lbl-txt'>分割距離を変更する [100-5000]mで入力可</span>
                </div>
                <div className='settings-elm'>
                  <input type='number' id='number'
                    onChange={this.handleCalcParmChange}
                    defaultValue={this.state.calc_parm}
                    min={100}
                    max={5000}
                  />
                </div>
              </div>
            </span>
          </Col>
          <Col xs={6} lg={6} >
             <span>
              <div className='settings-item'>
                <div className='settings-lbl'>
                  <span className='settings-lbl-txt'>競技を変更する</span>
                </div>
                <div className='settings-elm'>
                  {event_types}
                </div>
              </div>
            </span>
         </Col>
        </Row>

        <hr className='settings-hr' />

        <Row>
          <Col xs={6} lg={6} >
            <div className='settings-lbl'>
              <span className='settings-lbl-txt'>練習時間を設定する。</span>
            </div>
            <div style={{ color: 'red', fontSize: '20px' }}>
              {this.state.alert}
            </div>
            {practiceMoments}
            <div className='settings-elm'>
              <TimePicker
                value={this.state.selected_start_time}
                onChange={this.handleStartTime}
              />
              <TimePicker
                value={this.state.selected_end_time}
                onChange={this.handleEndTime}
              />
              <button
                onClick={this.handleAddPracticeTime}
                className='settings-file-btn'
              >
                追加
                </button>
            </div>
            {this.props.graphArea}
            <div style={slider}>
              <Slider 
                dataRange={dataRange} 
                selectionRange={this.state.selectionRange} 
                handleSelectDataRange={this.handleSelectDataRange}
                customTipFormatter={elapsedTimeToTimestamp}
                setSlider={this.setSlider}
                />

              <table width="100%">
                <tbody>
                  <tr>
                    <td align="left">{this.formatMomentToText(this.state.started_at)}</td>
                    <td align="right"> {this.formatMomentToText(this.state.ended_at)}</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </Col>

          <Col xs={6} lg={6} >
          <Row>
            <Col xs={8} lg={8} >
              <Row>
                <Col xs={4} lg={4} >
                <div className='settings-item'>
                  <div className='settings-lbl'>
                    <span className='settings-lbl-txt'>風向を変更する。</span>
                  </div>
                </div>
                  <input type='number' id='number'
                    onChange={this.handleWindDirectionChange}
                    defaultValue={this.state.windDirection}
                    min={0}
                    max={359}
                  />
                </Col>
                <Col xs={4} lg={4} >
                  <div className='settings-item'>
                    <div className='settings-lbl'>
                      <span className='settings-lbl-txt'>最大風速(m/s)</span>
                    </div>
                  </div>
                  <input type='number' id='wind_spd'
                    onChange={this.handleWindSpeedChange}
                    defaultValue={this.state.windSpeed}
                  />
                </Col>
                <Col xs={4} lg={4} >
                  <div className='settings-item'>
                    <div className='settings-lbl'>
                      <span className='settings-lbl-txt'>最低風速(m/s)</span>
                    </div>
                  </div>
                  <input type='number' id='wind_spd_min'
                    onChange={this.handleWindSpeedMinChange}
                    defaultValue={this.state.windSpeedMin}
                  />
                </Col>
              </Row>
            </Col>


            <Col xs={4} lg={4} >
              <div className='settings-item'>
                <div className='settings-elm'>
                  <div className='settings-lbl'>
                    <span className='settings-lbl-txt'>ブイ座標を設定する。</span>
                  </div>
                  <div className='settings-elm'>
                    <input
                      value={this.state.buoy_coordinate[1]}
                      readOnly={true}
                    />
                    <input
                      value={this.state.buoy_coordinate[0]}
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>
            </Col>
            </Row>
            <Map
              target={this.props.practice}
              windDirection={this.state.windDirection}
              handleClickOnMap={this.handleClickOnMap}
              selectedPoints={this.state.selected_points}
              selectedGpses={this.state.selectedGpses}
            />

          </Col>
        </Row>

        <hr className='settings-hr' />

        <div className='settings-lbl'>
          <span className='settings-lbl-txt'>セッティング記録する。</span>
          <span>
            <button
              onClick={this.loadLastSetting}
              className='settings-file-btn'
            >最新のセッティングをロード</button>
          </span>
        </div>
        {setting}
        <div className='settings-elm'>
          <table>
            <tbody>
            <tr>
              <td> <Form.Control type="text" placeholder="項目" onChange={(e) => this.handleChangeNewSettingItem(e, 0)}/> </td>
              <td> <Form.Control type="text" placeholder="値"   onChange={(e) => this.handleChangeNewSettingValue(e, 0)} /> </td>
              <td> <button onClick={this.handleAddSetting} className='settings-file-btn' > 追加 </button>< /td>
            </tr>
            </tbody>
          </table>
        </div>

        <hr className='settings-hr' />

        <div className='settings-item'>
          <div className='settings-lbl'>
            <span className='settings-lbl-txt'>風向きデータをアップロードする。</span>
            {this.noticeWind()}
          </div>
          <div className='settings-elm'>
            <input type='text'
              id='wind_condition_file_name'
              name='wind_condition_file_name'
              className='settings-file-txt' readOnly={true}
              onClick={this.selectFileWindCondition}
              ref={this.windConditionTextRef}
            />
            <button
              onClick={this.selectFileWindCondition}
              className='settings-file-btn'
            >参照する</button>
          </div>
          <input type='file' id='wind_condition_file'
            ref={this.windConditionFileRef}
            onChange={this.onChangeFileWindCondition}
            className='hide'
          />
        </div>
        <hr className='settings-hr' />
        <div className='settings-item'>
          <div className='settings-lbl'>
            <span className='settings-lbl-txt'>ラダーデータをアップロードする。</span>
            {this.noticeRudder()}
          </div>
          <div className='settings-elm'>
            <input type='text'
              id='rudder_file_name'
              name='rudder_file_name'
              className='settings-file-txt' readOnly={true}
              onClick={this.selectFileRudder}
              ref={this.rudderTextRef}
            />
            <button
              onClick={this.selectFileRudder}
              className='settings-file-btn'
            >参照する</button>
          </div>
          <input type='file' id='rudder_file'
            ref={this.rudderFileRef}
            onChange={this.onChangeRudder}
            className='hide'
          />
        </div>

        <Row>
          <Col xs={11} lg={11} >
            <Button className='settings-regist-btn' onClick={this.handleSubmit} >更新</Button>
            <Button  variant="secondary" className='settings-cancel-btn' onClick={this.props.handleCloseExternalDataModal}>キャンセル</Button>
          </Col>
          <Col xs={1} lg={1} >
            <Button  variant="danger" onClick={this.handleDelete}>練習の削除</Button>
          </Col>
        </Row>
      </Container >
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.auth.user,
    calc_patterns: state.Main.calc_patterns,
    practice_times: state.Main.practice_times,
    graphArea: state.Main.graphArea,
    events: state.Main.events,
    setting_list: state.Main.setting_list,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCalcPattern: (calc_patterns, selected_id) => dispatch(main.setCalcPattern(calc_patterns, selected_id)),
    loadLastSetting: ({cbfunc, state}) => dispatch(main.loadLastSetting({cbfunc, state})),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigForm);
