import React, { Component } from 'react'
import L from 'leaflet'
import { CircleMarker , Popup , Polyline, Tooltip } from 'react-leaflet'
import RotatedMarker from './rotatedMarker'

const playerIcon = new L.Icon({
    iconUrl: require('./navi-arrow.png'),
    //iconUrl: require('./wind-2x.png'),
    iconSize: new L.Point(20, 20),
});

const windIcon = new L.Icon({
    iconUrl: require('./wind-2x.png'),
    iconSize: new L.Point(20, 20),
});


export default class MainPlayer extends Component {

  render() {
    const { device } = this.props;
		console.log(device)
    const CircleM = <CircleMarker center={device.pos} radius={18} />

    const GpsPop = (
      <Popup direction={'top'} offset={[0, -20]} >
        <table>
         <tbody>
         <tr>
           <th>Name</th><td>{ device.name }</td>
         </tr>
         <tr>
           <th>Device</th><td>{ device.id }</td>
         </tr>
         <tr>
           <th>SPEED</th><td>{ device.spd } {this.props.unit}</td>
         </tr>
         <tr>
           <th>VMG</th><td>{ device.vmg } {this.props.unit}</td>
         </tr>
         <tr>
           <th>ANGLE</th><td>{ device.ang } deg</td>
         </tr>
         </tbody>
        </table>
     </Popup>);

    const WindPop = (
      <Popup direction={'top'} offset={[0, -20]} >
        <table>
         <tbody>
         <tr>
           <th>Device</th><td>{ device.id }</td>
         </tr>
         <tr>
           <th>SPEED</th><td>{ device.spd } {this.props.unit}</td>
         </tr>
         <tr>
           <th>WIND</th><td>{ device.windspd } m/s</td>
         </tr>
         <tr>
           <th>WIND</th><td>{ device.winddir } deg</td>
         </tr>
         </tbody>
        </table>
     </Popup>);

    return (
        <span>
          <RotatedMarker
              // if position changes, marker will drift its way to new position
              //position={ deviceMap[deviceId].pos }
              position={ device.pos }
              // time in ms that marker will take to reach its destination
              //duration={1000}
              icon={ device.wind ? windIcon : playerIcon }
              rotationAngle={device.rotate}
              rotationOrigin={'center'}
          >

          <Tooltip className={"tooltip"} permanent={true} direction="right" offset={[5, 2]} interactive={true}>
            <div>{device.name}</div>
          </Tooltip>

            {device.wind ? WindPop : GpsPop}
          </RotatedMarker>
          {device.main && CircleM}
          <Polyline positions={ device.lines.slice() } color={'red'}  />
        </span>
  );}

}
