import API from '../components/API';

export const updateLocation = ( org_id, org_location, history ) => {

  return (dispatch, getState) => {

    const token = getState().auth.token;

    let onSuccess = function (res, history) {
      if (res.status === 200) {

        dispatch({ type: 'SUT_ORGANIZATION', organization: res.organization });
        history.push('/');

      } else {
        dispatch({ type: "SUT_ORGANIZATION_ERROR", organization: res.data });
        throw res.data;
      }
    }

    let onError = function (res) {
      if (res.status >= 500) {
        console.log("Server Error!");
        throw res;
      } else if (res.status >= 400 && res.status < 500) {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      } else {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      }
    }

    API.putOrganization({ token: token, org_id: org_id, org_location: org_location, onSuccess: onSuccess, onError: onError, history: history });
  }
}
