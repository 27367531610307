import * as d3 from 'd3';
import './polar-graph.css';

export default class PolarBase {
    constructor() {
        this.container = null;
        this.containerW = null;
        this.layerSvgBg = null;
        this.r = 228;//default circleSize
        this.data = null;
        this.max_vmgs = null;
        this.max_spds = null;
        this.isInited = false;
        this.isInitedWindLabel = false;
        this.resizeTimer = null;
        this.opts = {};
    }
    static init = function (arg) {
        // 一度イニシャライズ完了していたら何もしない
        if (this.isInited) {
            return;
        }
        // オプション指定が無ければここで処理を終了
        if (!arg) {
            console.error('No arguments');
            return;
        } else {
            // オプション値を上書きコピー
            this.opts = {
                elem: arg.elem || null,
                title: arg.title || null,
                labels: arg.labels ? arg.labels : null,
                circlSize: arg.circlSize || 228,
                ticksSuffix: arg.ticksSuffix || null
            };
        }

        // レンダリング対象の要素が無ければここで処理を終了
        if (!document.getElementById(arg.elem.split('#')[1])) {
            console.error("No polar element");
            return;
        };

        // イニシャライズ

        // 全要素を格納するコンテナを取得
        this.container = document.getElementById(arg.elem.split('#')[1]);
        this.container.classList.add('polar-container');
        // 円の半径を設定
        this.r = this.opts.circlSize / 2;
        // グラフの背景を表示
        this.drawSvgBg();
        // グラフの軸ラベルを表示
        this.drawSvgAxisLabels();
        // グラフのタイトルを表示
        this.drawTitle();
        // 初期表示完了フラグを更新
        this.isInited = true;
    };
    // ユーザのデータ名ラベルを追加
    static drawDataNameLabels = function () {
        if (this.isInited) {
            // データ更新時
            d3.select(this.opts.elem + ' .polar-data-name-list').remove();
        }
        if (this.data.length) {
            var listElem = document.createElement('ul');
            listElem.classList.add('polar-data-name-list');
            var i;
            for (i = 0; i < this.data.length; i = (i + 1) | 0) {
                if (this.data[i].label) {
                    this.setLabel(this.data, i, listElem)
                }
            };
            if (this.max_vmgs) {
                let max_vmgs = [];
                max_vmgs.push(this.max_vmgs)
                this.setLabel(max_vmgs, 0, listElem)
            }
            if (this.max_spds) {
                let max_spds = [];
                max_spds.push(this.max_spds)
                this.setLabel(max_spds, 0, listElem)
            }
        }
    };

    static setLabel = (data, i, listElem) => {
        var listItem = document.createElement('li');
        var dotItem = document.createElement('div');
        dotItem.classList.add('polar-data-name-dot');
        dotItem.style.backgroundColor = data[i].color;
        listItem.innerHTML = data[i].label;
        listItem.appendChild(dotItem);
        listElem.appendChild(listItem);
        if (i === data.length - 1) {
            if(this.container)
              this.container.appendChild(listElem);
        }
    }

    // 風速ラベルを追加
    static drawWindSpeed = function (arg) {
        this.optsWindLabel = {
            windlabel: arg.windlabel === false ? arg.windlabel : true,
            windspeed: arg.windspeed || ''
        }
        // 初回のみ要素を生成
        if (this.optsWindLabel.windlabel && !this.isInitedWindLabel) {
            this.isInitedWindLabel = true;
            var windSpeedContainer = document.createElement('div');
            windSpeedContainer.classList.add('polar-wind-speed-container');
            var windTitleElem = document.createElement('div');
            windTitleElem.classList.add('polar-wind-speed-title');
            windTitleElem.innerHTML = 'WIND';
            windSpeedContainer.appendChild(windTitleElem);
            var windSpeedElem = document.createElement('div');
            windSpeedElem.classList.add('polar-wind-speed');
            windSpeedElem.innerHTML = this.optsWindLabel.windspeed;
            windSpeedContainer.appendChild(windSpeedElem);
            this.container.appendChild(windSpeedContainer);
        } else if (this.optsWindLabel.windlabel && this.isInitedWindLabel) {
            // 二度目以降はデータの更新
            d3.select(this.opts.elem + ' .polar-wind-speed').text(this.optsWindLabel.windspeed);
        } else if (!this.optsWindLabel.windlabel) {
            d3.select(this.opts.elem + ' .polar-wind-speed-container').remove();
        }
    };
    // グラフタイトルを追加
    static drawTitle = function () {
        if (this.opts.title) {
            var titleElem = document.createElement('div');
            titleElem.classList.add('polar-title');
            titleElem.innerHTML = this.opts.title;
            this.container.appendChild(titleElem);
        }
    };
    // 背景生成
    static drawSvgBg = function () {
        this.layerSvgBg = d3.select(this.opts.elem).append('svg')
            .attr('class', 'polar-svg-layer-bg')
            .attr('width', this.r * 2)
            .attr('height', this.r * 2);
        // 背景の円
        this.layerSvgBg.append('circle')
            .attr('class', 'polar-svg-bg-circle')
            .attr('cx', this.r)
            .attr('cy', this.r)
            .attr('r', this.r)
            .attr('fill', '#3b3c3d');
        // 縦横軸の線
        var layerAxis = this.layerSvgBg.append('g')
            .attr('class', 'polar-svg-layer-axis');
        layerAxis.append('line')
            .attr('x1', this.r)
            .attr('x2', this.r)
            .attr('y1', 0)
            .attr('y2', this.r * 2)
            .attr('stroke-width', 1)
            .attr('fill', 'none')
            .attr('stroke', '#e1e1e1');
        layerAxis.append('line')
            .attr('x1', this.r)
            .attr('x2', this.r)
            .attr('y1', 0)
            .attr('y2', this.r * 2)
            .attr('stroke-width', 1)
            .attr('fill', 'none')
            .attr('transform', 'rotate(90)')
            .attr('transform-origin', 'center center')
            .attr('stroke', '#e1e1e1');
        var layerSvgHighlights = d3.select(this.opts.elem).append('svg')
            .attr('class', 'polar-svg-layer-highlights')
            .attr('width', this.r * 2)
            .attr('height', this.r * 2);
        var layerArc = layerSvgHighlights.append('g')
            .attr('class', 'polar-svg-layer-highlights-arc')
            .attr('transform', 'translate(' + this.r + ',' + this.r + ')');
    };
    // 軸のラベルを追加
    static drawSvgAxisLabels = function () {
        var verticalPadding = 25;
        // コンテナの幅を取得
        this.containerW = this.container.clientWidth;
        var center = {
            x: this.containerW / 2,
            y: this.r + verticalPadding
        };
        if (this.isInited) {
            // 初回以外は要素の位置を更新
            d3.select(this.opts.elem + ' .polar-svg-layer-axis-labels')
                .attr('width', this.containerW)
                .attr('height', this.r * 2 + verticalPadding * 2);
            // 軸ラベルtop
            d3.select(this.opts.elem + ' .polar-svg-axis-label-top')
                .attr('x', center.x)
                .attr('y', center.y - this.r - 3);
            // 軸ラベルright
            d3.select(this.opts.elem + ' .polar-svg-axis-label-right')
                .attr('x', center.x + this.r + 3)
                .attr('y', center.y);
            // 軸ラベルbottom
            d3.select(this.opts.elem + ' .polar-svg-axis-label-bottom')
                .attr('x', center.x)
                .attr('y', center.y + this.r + 3);
            // 軸ラベルleft
            d3.select(this.opts.elem + ' .polar-svg-axis-label-left')
                .attr('x', center.x - this.r - 3)
                .attr('y', center.y);
        } else {
            // 初回は要素を追加
            var layerAxisLabels = d3.select(this.opts.elem).append('svg')
                .attr('class', 'polar-svg-layer-axis-labels')
                .attr('width', this.containerW)
                .attr('height', this.r * 2 + verticalPadding * 2);
            // 軸ラベルtop
            layerAxisLabels.append('text')
                .text(this.opts.labels[0] + ' (0°)')
                .attr('class', 'polar-svg-axis-label polar-svg-axis-label-top')
                .attr('x', center.x)
                .attr('y', center.y - this.r - 3)
                .attr('text-anchor', 'middle')
                .attr('dominant-baseline', 'text-after-edge')
                .style('font-size', '10px');
            // 軸ラベルright
            layerAxisLabels.append('text')
                .attr('class', 'polar-svg-axis-label polar-svg-axis-label-right')
                .attr('x', center.x + this.r + 3)
                .attr('y', center.y)
                .attr('text-anchor', 'start')
                .attr('dominant-baseline', 'middle')
                .style('font-size', '10px');
            var labelR = d3.select(this.opts.elem + ' .polar-svg-axis-label-right');
            labelR.append('tspan')
                .text('(90°)')
                .attr('dy', 12);
            labelR.append('tspan')
                .text(this.opts.labels[1])
                .attr('dx', -21)
                .attr('dy', -12);
            // 軸ラベルbottom
            layerAxisLabels.append('text')
                .text(this.opts.labels[2] + ' (180°)')
                .attr('class', 'polar-svg-axis-label polar-svg-axis-label-bottom')
                .attr('x', center.x)
                .attr('y', center.y + this.r + 3)
                .attr('text-anchor', 'middle')
                .attr('dominant-baseline', 'text-before-edge')
                .style('font-size', '10px');
            // 軸ラベルleft
            layerAxisLabels.append('text')
                .attr('class', 'polar-svg-axis-label polar-svg-axis-label-left')
                .attr('x', center.x - this.r - 3)
                .attr('y', center.y)
                .attr('text-anchor', 'end')
                .attr('dominant-baseline', 'middle')
                .style('font-size', '10px');
            var labelL = d3.select(this.opts.elem + ' .polar-svg-axis-label-left');
            labelL.append('tspan')
                .text(this.opts.labels[3]);
            labelL.append('tspan')
                .text('(270°)')
                .attr('dx', -27)
                .attr('dy', 12);
        }
    };
    // ハイライト、アーチを追加
    static drawHighlight = function () {
        if (this.isInited) {
            // データ更新時
            d3.select(this.opts.elem + ' .polar-svg-layer-highlights').remove();
            d3.select(this.opts.elem + ' .polar-svg-layer-arc').remove();
        }
        // 背景のアーチ、ハイライト
        var layerArc = this.layerSvgBg.insert('g', '.polar-svg-layer-axis')
            .attr('class', 'polar-svg-layer-arc')
            .attr('transform', 'translate(' + this.r + ',' + this.r + ')');
        // データの数によってアーチの数を調整
        switch (this.data.length) {
            case 0:
                // 何もしないで終了
                return;
                break;
            case 1:
                if (!this.data[0].highlights) { break; }
                // 外側アーチのみ描画
                var outerArc = d3.svg.arc()
                    .innerRadius(this.r - 10)
                    .outerRadius(this.r - 7)
                    .startAngle(0)
                    .endAngle(Math.PI * 2);
                layerArc.append('path')
                    .attr('d', outerArc)
                    .attr('fill', '#17181D');
                break;
            case 2:
                if (!this.data[0].highlights && !this.data[1].highlights) { break; }
                // 外側アーチ描画
                var outerArc = d3.svg.arc()
                    .innerRadius(this.r - 10)
                    .outerRadius(this.r - 7)
                    .startAngle(0)
                    .endAngle(Math.PI * 2);
                layerArc.append('path')
                    .attr('d', outerArc)
                    .attr('fill', '#17181D');
                // 内側アーチ描画
                var innerArc = d3.svg.arc()
                    .innerRadius(this.r - 20)
                    .outerRadius(this.r - 17)
                    .startAngle(0)
                    .endAngle(Math.PI * 2);
                layerArc.append('path')
                    .attr('d', innerArc)
                    .attr('fill', '#17181D');
                break;
            default:
                // 何もしないで終了
                break;
        }

        if (this.data.length) {
            var i;
            for (i = 0; i < this.data.length; i = (i + 1) | 0) {
                if (this.data[i].highlights) {
                    var highlightArr = this.data[i].highlights;
                    var deg = Math.PI / 180;
                    var j;
                    for (var j = 0; j < highlightArr.length; j = (j + 1) | 0) {
                        switch (this.data.length) {
                            case 0:
                                return;
                                break;
                            case 1:
                                // データが1つの場合ハイライトを外側に描画
                                var arc = d3.svg.arc()
                                    .innerRadius(this.r - 7)
                                    .outerRadius(this.r)
                                    .startAngle((highlightArr[j] - 3.75) * Math.PI / 180)
                                    .endAngle((highlightArr[j] + 3.75) * Math.PI / 180 + (Math.PI / 24 * Math.PI / 180));
                                layerArc.append('path')
                                    .attr('d', arc)
                                    .attr('fill', this.data[i].color);
                                break;
                            case 2:
                                // データが2つの場合
                                if (i === 0) {
                                    // 1つ目のデータのハイライトを内側に描画
                                    var arc = d3.svg.arc()
                                        .innerRadius(this.r - 17)
                                        .outerRadius(this.r - 10)
                                        .startAngle((highlightArr[j] - 3.75) * Math.PI / 180)
                                        .endAngle((highlightArr[j] + 3.75) * Math.PI / 180 + (Math.PI / 24 * Math.PI / 180));
                                    layerArc.append('path')
                                        .attr('d', arc)
                                        .attr('fill', this.data[i].color);
                                };
                                // 2つ目のデータのハイライトを外側に描画
                                if (i === 1) {
                                    var arc = d3.svg.arc()
                                        .innerRadius(this.r - 7)
                                        .outerRadius(this.r)
                                        .startAngle((highlightArr[j] - 3.75) * Math.PI / 180)
                                        .endAngle((highlightArr[j] + 3.75) * Math.PI / 180 + (Math.PI / 24 * Math.PI / 180));
                                    layerArc.append('path')
                                        .attr('d', arc)
                                        .attr('fill', this.data[i].color);
                                };
                                break;
                            default:
                                break;
                        };
                    };
                };
            };
        };
    };
    // データの反映
    static draw = function (arg) {
        if (!arg) { return; }
        this.data = arg.data || [];
        this.max_vmgs = arg.max_vmgs || [];
        this.max_spds = arg.max_spds || [];
        // データ名ラベルを表示
        this.drawDataNameLabels();
        // ハイライトを反映
        this.drawHighlight();

        var config = {};
        var polarData = [];
        var directions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 243, 244, 245, 246, 247, 248, 249, 250, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260, 261, 262, 263, 264, 265, 266, 267, 268, 269, 270, 271, 272, 273, 274, 275, 276, 277, 278, 279, 280, 281, 282, 283, 284, 285, 286, 287, 288, 289, 290, 291, 292, 293, 294, 295, 296, 297, 298, 299, 300, 301, 302, 303, 304, 305, 306, 307, 308, 309, 310, 311, 312, 313, 314, 315, 316, 317, 318, 319, 320, 321, 322, 323, 324, 325, 326, 327, 328, 329, 330, 331, 332, 333, 334, 335, 336, 337, 338, 339, 340, 341, 342, 343, 344, 345, 346, 347, 348, 349, 350, 351, 352, 353, 354, 355, 356, 357, 358, 359];
        var polarLayout = {
            width: this.opts.circlSize,
            height: this.opts.circlSize,
            orientation: -90,
            needsEndSpacing: false,
            backgroundColor: 'none',
            showLegend: false,
            tickColor: '#5C5F6B',
            font: {
                color: '#fff',
                outlineColor: 'none'
            },
            angularAxis: {
                labelsVisible: false,
            },
            radialAxis: {
                orientation: -90,
                gridLinesVisible: true,
                ticksSuffix: this.opts.ticksSuffix
            },
            margin: {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0
            }
        };

        // データの数によって円の半径をmarginで調整
        switch (this.data.length) {
            case 0:
                return;
                break;
            case 1:
                if (this.data[0].highlights) {
                    polarLayout.margin = {
                        top: 10,
                        bottom: 10,
                        left: 10,
                        right: 10
                    }
                }
                break;
            case 2:
                if (this.data[0].highlights && this.data[1].highlights) {
                    polarLayout.margin = {
                        top: 21,
                        bottom: 21,
                        left: 21,
                        right: 21
                    }
                }
                break;
            default:
                return;
                break;
        }

        // 下レイヤーのグラフデータを格納
        if (this.data[1]) {
            var polar2 = {
                t: directions,
                r: this.data[1].r,
                color: 'none',
                strokeColor: this.data[1].color,
                geometry: 'LinePlot'
            };
            polarData.push(polar2);
        };
        // 上レイヤーのグラフデータを格納
        if (this.data[0]) {
            var polar1 = {
                t: directions,
                r: this.data[0].r,
                color: 'none',
                strokeColor: this.data[0].color,
                geometry: 'LinePlot'
            };
            polarData.push(polar1);
        };

        var max_vmgs = {
            t: directions,
            r: arg.max_vmgs.r,
            color: 'none',
            strokeColor: arg.max_vmgs.color,
            geometry: 'LinePlot'
        };
        polarData.push(max_vmgs);

        var max_spds = {
            t: directions,
            r: arg.max_spds.r,
            color: 'none',
            strokeColor: arg.max_spds.color,
            geometry: 'LinePlot'
        };
        polarData.push(max_spds);

        var graph = {
            data: polarData,
            layout: polarLayout
        };

        // データの更新時
        if (this.isInited) {
            // 再描画のため要素を空にする
            d3.select(this.opts.elem + ' .polar-curve-container').remove();
        }
        // カーブ、目盛の描画
        Mu.util.deepExtend(config, graph);
        micropolar.Axis().config(config).render(d3.select(this.opts.elem).append('div').attr('class', 'polar-curve-container'));

        // グラフを最上面レイヤーにする
        var radicalAxisLayer = document.getElementById(this.opts.elem.split('#')[1]).querySelectorAll('.grid-circle');
        var parentLayer = document.getElementById(this.opts.elem.split('#')[1]).querySelector('.chart-group');
        var afterLayer = document.getElementById(this.opts.elem.split('#')[1]).querySelector('.geometry-group');
        var i;
        for (var i = 0; i < radicalAxisLayer.length; i = (i + 1) | 0) {
            parentLayer.insertBefore(radicalAxisLayer[i], afterLayer);
        };
    };
    static adjustLayout = function () {
        // イベント多発に依る負荷軽減用対策
        var self = this;
        clearTimeout(this.resizeTimer);
        this.resizeTimer = setTimeout(function () {
            //処理内容
            self.drawSvgAxisLabels();
        }, 100);
    };
}

// -----------------------------------------------------------------------------------

var micropolar = {
    version: "0.2.2"
};
var Mu = micropolar;

Mu.Axis = function module() {
    var config = {
        data: [],
        layout: {}
    }, inputConfig = {}, liveConfig = {};
    var svg, container, dispatch = d3.dispatch("hover"), radialScale, angularScale;
    var angularTooltip, radialTooltip, geometryTooltip;
    var exports = {};
    function render(_container) {
        container = _container || container;
        var data = config.data;
        var axisConfig = config.layout;
        if (typeof container === "string" || container.nodeName) {
            container = d3.select(container);
        }
        container.datum(data).each(function (_data, _index) {
            var dataOriginal = _data.slice();
            liveConfig = {
                data: Mu.util.cloneJson(dataOriginal),
                layout: Mu.util.cloneJson(axisConfig)
            };
            var colorIndex = 0;
            dataOriginal.forEach(function (d, i) {
                if (!d.color) {
                    d.color = axisConfig.defaultColorRange[colorIndex];
                    colorIndex = (colorIndex + 1) % axisConfig.defaultColorRange.length;
                }
                if (!d.strokeColor) {
                    d.strokeColor = d.geometry === "LinePlot" ? d.color : d3.rgb(d.color).darker().toString();
                }
                liveConfig.data[i].color = d.color;
                liveConfig.data[i].strokeColor = d.strokeColor;
                liveConfig.data[i].strokeDash = d.strokeDash;
                liveConfig.data[i].strokeSize = d.strokeSize;
            });
            var data = dataOriginal.filter(function (d, i) {
                var visible = d.visible;
                return typeof visible === "undefined" || visible === true;
            });
            var isStacked = false;
            var dataWithGroupId = data.map(function (d, i) {
                isStacked = isStacked || typeof d.groupId !== "undefined";
                return d;
            });
            var dataYStack = [];
            if (isStacked) {
                var grouped = d3.nest().key(function (d, i) {
                    return typeof d.groupId !== "undefined" ? d.groupId : "unstacked";
                }).entries(dataWithGroupId);
                var stacked = grouped.map(function (d, i) {
                    if (d.key === "unstacked") {
                        return d.values;
                    } else {
                        var prevArray = d.values[0].r.map(function (d, i) {
                            return 0;
                        });
                        d.values.forEach(function (d, i, a) {
                            d.yStack = [prevArray];
                            dataYStack.push(prevArray);
                            prevArray = Mu.util.sumArrays(d.r, prevArray);
                        });
                        return d.values;
                    }
                });
                data = d3.merge(stacked);
            }
            data.forEach(function (d, i) {
                d.t = Array.isArray(d.t[0]) ? d.t : [d.t];
                d.r = Array.isArray(d.r[0]) ? d.r : [d.r];
            });
            var radius = Math.min(axisConfig.width - axisConfig.margin.left - axisConfig.margin.right, axisConfig.height - axisConfig.margin.top - axisConfig.margin.bottom) / 2;
            radius = Math.max(10, radius);
            var chartCenter = [axisConfig.margin.left + radius, axisConfig.margin.top + radius];
            var extent;
            if (isStacked) {
                var highestStackedValue = d3.max(Mu.util.sumArrays(Mu.util.arrayLast(data).r[0], Mu.util.arrayLast(dataYStack)));
                extent = [0, highestStackedValue];
            } else {
                extent = d3.extent(Mu.util.flattenArray(data.map(function (d, i) {
                    return d.r;
                })));
            }
            if (axisConfig.radialAxis.domain !== Mu.DATAEXTENT) {
                extent[0] = 0;
            }
            radialScale = d3.scale.linear().domain(axisConfig.radialAxis.domain !== Mu.DATAEXTENT && axisConfig.radialAxis.domain ? axisConfig.radialAxis.domain : extent).range([0, radius]);
            liveConfig.layout.radialAxis.domain = radialScale.domain();
            var angularDataMerged = Mu.util.flattenArray(data.map(function (d, i) {
                return d.t;
            }));
            var isOrdinal = typeof angularDataMerged[0] === "string";
            var ticks;
            if (isOrdinal) {
                if (isStacked) {
                    angularDataMerged = Mu.util.deduplicate(angularDataMerged);
                }
                ticks = angularDataMerged.slice();
                angularDataMerged = d3.range(angularDataMerged.length);
                data = data.map(function (d, i) {
                    var result = d;
                    d.t = [angularDataMerged];
                    if (isStacked) {
                        result.yStack = d.yStack;
                    }
                    return result;
                });
            }
            var hasOnlyLineOrDotPlot = data.filter(function (d, i) {
                return d.geometry === "LinePlot" || d.geometry === "DotPlot";
            }).length === data.length;
            var needsEndSpacing = axisConfig.needsEndSpacing === null ? isOrdinal || !hasOnlyLineOrDotPlot : axisConfig.needsEndSpacing;
            var useProvidedDomain = axisConfig.angularAxis.domain && axisConfig.angularAxis.domain !== Mu.DATAEXTENT && !isOrdinal && axisConfig.angularAxis.domain[0] >= 0;
            var angularDomain = useProvidedDomain ? axisConfig.angularAxis.domain : d3.extent(angularDataMerged);
            var angularDomainStep = Math.abs(angularDataMerged[1] - angularDataMerged[0]);
            if (hasOnlyLineOrDotPlot && !isOrdinal) {
                angularDomainStep = 0;
            }
            var angularDomainWithPadding = angularDomain.slice();
            if (needsEndSpacing && isOrdinal) {
                angularDomainWithPadding[1] += angularDomainStep;
            }
            var tickCount = axisConfig.angularAxis.ticksCount || 4;
            if (tickCount > 8) {
                tickCount = tickCount / (tickCount / 8) + tickCount % 8;
            }
            if (axisConfig.angularAxis.ticksStep) {
                tickCount = (angularDomainWithPadding[1] - angularDomainWithPadding[0]) / tickCount;
            }
            var angularTicksStep = axisConfig.angularAxis.ticksStep || (angularDomainWithPadding[1] - angularDomainWithPadding[0]) / (tickCount * (axisConfig.minorTicks + 1));
            if (ticks) {
                angularTicksStep = Math.max(Math.round(angularTicksStep), 1);
            }
            if (!angularDomainWithPadding[2]) {
                angularDomainWithPadding[2] = angularTicksStep;
            }
            var angularAxisRange = d3.range.apply(this, angularDomainWithPadding);
            angularAxisRange = angularAxisRange.map(function (d, i) {
                return parseFloat(d.toPrecision(12));
            });
            angularScale = d3.scale.linear().domain(angularDomainWithPadding.slice(0, 2)).range(axisConfig.direction === "clockwise" ? [0, 360] : [360, 0]);
            liveConfig.layout.angularAxis.domain = angularScale.domain();
            liveConfig.layout.angularAxis.endPadding = needsEndSpacing ? angularDomainStep : 0;
            svg = d3.select(this).select("svg.chart-root");
            if (typeof svg === "undefined" || svg.empty()) {
                var skeleton = '<svg xmlns="http://www.w3.org/2000/svg" class="chart-root">' + '<g class="outer-group">' + '<g class="chart-group">' + '<circle class="background-circle"></circle>' + '<g class="geometry-group"></g>' + '<g class="radial axis-group">' + '<circle class="outside-circle"></circle>' + "</g>" + '<g class="angular axis-group"></g>' + '<g class="guides-group"><line></line><circle r="0"></circle></g>' + "</g>" + '<g class="legend-group"></g>' + '<g class="tooltips-group"></g>' + '<g class="title-group"><text></text></g>' + "</g>" + "</svg>";
                var doc = new DOMParser().parseFromString(skeleton, "application/xml");
                var newSvg = this.appendChild(this.ownerDocument.importNode(doc.documentElement, true));
                svg = d3.select(newSvg);
            }
            svg.select(".guides-group").style({
                "pointer-events": "none"
            });
            svg.select(".angular.axis-group").style({
                "pointer-events": "none"
            });
            svg.select(".radial.axis-group").style({
                "pointer-events": "none"
            });
            var chartGroup = svg.select(".chart-group");
            var lineStyle = {
                fill: "none",
                stroke: axisConfig.tickColor
            };
            var fontStyle = {
                "font-size": axisConfig.font.size,
                "font-family": axisConfig.font.family,
                fill: axisConfig.font.color,
                "text-shadow": ["-1px 0px", "1px -1px", "-1px 1px", "1px 1px"].map(function (d, i) {
                    return " " + d + " 0 " + axisConfig.font.outlineColor;
                }).join(",")
            };
            var legendContainer, legendBBox;
            if (axisConfig.showLegend) {
                legendContainer = svg.select(".legend-group").attr({
                    transform: "translate(" + [radius, axisConfig.margin.top] + ")"
                }).style({
                    display: "block"
                });
                var elements = data.map(function (d, i) {
                    var datumClone = Mu.util.cloneJson(d);
                    datumClone.symbol = d.geometry === "DotPlot" ? d.dotType || "circle" : d.geometry !== "LinePlot" ? "square" : "line";
                    datumClone.visibleInLegend = typeof d.visibleInLegend === "undefined" || d.visibleInLegend;
                    datumClone.color = d.geometry === "LinePlot" ? d.strokeColor : d.color;
                    return datumClone;
                });
                var legendConfigMixin1 = Mu.util.deepExtend({}, Mu.Legend.defaultConfig().legendConfig);
                var legendConfigMixin2 = Mu.util.deepExtend(legendConfigMixin1, {
                    container: legendContainer,
                    elements: elements,
                    reverseOrder: axisConfig.legend.reverseOrder
                });
                var legendConfigMixin3 = {
                    data: data.map(function (d, i) {
                        return d.name || "Element" + i;
                    }),
                    legendConfig: legendConfigMixin2
                };
                Mu.Legend().config(legendConfigMixin3)();
                legendBBox = legendContainer.node().getBBox();
                radius = Math.min(axisConfig.width - legendBBox.width - axisConfig.margin.left - axisConfig.margin.right, axisConfig.height - axisConfig.margin.top - axisConfig.margin.bottom) / 2;
                radius = Math.max(10, radius);
                chartCenter = [axisConfig.margin.left + radius, axisConfig.margin.top + radius];
                radialScale.range([0, radius]);
                liveConfig.layout.radialAxis.domain = radialScale.domain();
                legendContainer.attr("transform", "translate(" + [chartCenter[0] + radius, chartCenter[1] - radius] + ")");
            } else {
                legendContainer = svg.select(".legend-group").style({
                    display: "none"
                });
            }
            svg.attr({
                width: axisConfig.width,
                height: axisConfig.height
            }).style({
                opacity: axisConfig.opacity
            });
            chartGroup.attr("transform", "translate(" + chartCenter + ")").style({
                cursor: "crosshair"
            });
            var centeringOffset = [(axisConfig.width - (axisConfig.margin.left + axisConfig.margin.right + radius * 2 + (legendBBox ? legendBBox.width : 0))) / 2, (axisConfig.height - (axisConfig.margin.top + axisConfig.margin.bottom + radius * 2)) / 2];
            centeringOffset[0] = Math.max(0, centeringOffset[0]);
            centeringOffset[1] = Math.max(0, centeringOffset[1]);
            svg.select(".outer-group").attr("transform", "translate(" + centeringOffset + ")");
            if (axisConfig.title) {
                var title = svg.select("g.title-group text").style(fontStyle).text(axisConfig.title);
                var titleBBox = title.node().getBBox();
                title.attr({
                    x: chartCenter[0] - titleBBox.width / 2,
                    y: chartCenter[1] - radius - 20
                });
            }
            var radialAxis = svg.select(".radial.axis-group");
            if (axisConfig.radialAxis.gridLinesVisible) {
                var gridCircles = radialAxis.selectAll("circle.grid-circle").data(radialScale.ticks(5));
                gridCircles.enter().append("circle").attr({
                    "class": "grid-circle"
                }).style(lineStyle);
                gridCircles.attr("r", radialScale);
                gridCircles.exit().remove();
            }
            radialAxis.select("circle.outside-circle").attr({
                r: radius
            }).style(lineStyle);
            var backgroundCircle = svg.select("circle.background-circle").attr({
                r: radius
            }).style({
                fill: axisConfig.backgroundColor,
                stroke: axisConfig.stroke
            });
            function currentAngle(d, i) {
                return angularScale(d) % 360 + axisConfig.orientation;
            }
            if (axisConfig.radialAxis.visible) {
                var axis = d3.svg.axis().scale(radialScale).ticks(5).tickSize(5);
                radialAxis.call(axis).attr({
                    transform: "rotate(" + axisConfig.radialAxis.orientation + ")"
                });
                radialAxis.selectAll(".domain").style(lineStyle);
                radialAxis.selectAll("g>text").text(function (d, i) {
                    return this.textContent + axisConfig.radialAxis.ticksSuffix;
                }).style(fontStyle).style({
                    "text-anchor": "start"
                }).attr({
                    x: 5,
                    y: -9,
                    dx: 0,
                    dy: 0,
                    transform: function (d, i) {
                        if (axisConfig.radialAxis.tickOrientation === "horizontal") {
                            return "rotate(" + -axisConfig.radialAxis.orientation + ") translate(" + [0, fontStyle["font-size"]] + ") scale(0.8)";
                        } else {
                            return "translate(" + [0, fontStyle["font-size"]] + ") scale(0.8)";
                        }
                    },
                    opacity: function (d, i) {
                        if (d === 0) {
                            return 0;
                        } else {
                            return 1;
                        }
                    }
                });
                radialAxis.selectAll("g>line").style({
                    stroke: "black"
                });
            }
            var angularAxis = svg.select(".angular.axis-group").selectAll("g.angular-tick").data(angularAxisRange);
            var angularAxisEnter = angularAxis.enter().append("g").classed("angular-tick", true);
            angularAxis.attr({
                transform: function (d, i) {
                    return "rotate(" + currentAngle(d, i) + ")";
                }
            }).style({
                display: axisConfig.angularAxis.visible ? "block" : "none"
            });
            angularAxis.exit().remove();
            angularAxisEnter.append("line").classed("grid-line", true).classed("major", function (d, i) {
                return i % (axisConfig.minorTicks + 1) === 0;
            }).classed("minor", function (d, i) {
                return i % (axisConfig.minorTicks + 1) !== 0;
            }).style(lineStyle);
            angularAxisEnter.selectAll(".minor").style({
                stroke: axisConfig.minorTickColor
            });
            angularAxis.select("line.grid-line").attr({
                x1: axisConfig.tickLength ? radius - axisConfig.tickLength : 0,
                x2: radius
            }).style({
                display: axisConfig.angularAxis.gridLinesVisible ? "block" : "none"
            });
            angularAxisEnter.append("text").classed("axis-text", true).style(fontStyle);
            var ticksText = angularAxis.select("text.axis-text").attr({
                x: radius + axisConfig.labelOffset,
                dy: ".35em",
                transform: function (d, i) {
                    var angle = currentAngle(d, i);
                    var rad = radius + axisConfig.labelOffset;
                    var orient = axisConfig.angularAxis.tickOrientation;
                    if (orient === "horizontal") {
                        return "rotate(" + -angle + " " + rad + " 0)";
                    } else if (orient === "radial") {
                        return angle < 270 && angle > 90 ? "rotate(180 " + rad + " 0)" : null;
                    } else {
                        return "rotate(" + (angle <= 180 && angle > 0 ? -90 : 90) + " " + rad + " 0)";
                    }
                }
            }).style({
                "text-anchor": "middle",
                display: axisConfig.angularAxis.labelsVisible ? "block" : "none"
            }).text(function (d, i) {
                if (i % (axisConfig.minorTicks + 1) !== 0) {
                    return "";
                }
                if (ticks) {
                    return ticks[d] + axisConfig.angularAxis.ticksSuffix;
                } else {
                    return d + axisConfig.angularAxis.ticksSuffix;
                }
            }).style(fontStyle);
            if (axisConfig.angularAxis.rewriteTicks) {
                ticksText.text(function (d, i) {
                    if (i % (axisConfig.minorTicks + 1) !== 0) {
                        return "";
                    }
                    return axisConfig.angularAxis.rewriteTicks(this.textContent, i);
                });
            }
            var rightmostTickEndX = d3.max(chartGroup.selectAll(".angular-tick text")[0].map(function (d, i) {
                return d.getCTM().e + d.getBBox().width;
            }));
            legendContainer.attr({
                transform: "translate(" + [radius + rightmostTickEndX, axisConfig.margin.top] + ")"
            });
            var hasGeometry = svg.select("g.geometry-group").selectAll("g").size() > 0;
            var geometryContainer = svg.select("g.geometry-group").selectAll("g.geometry").data(data);
            geometryContainer.enter().append("g").attr({
                "class": function (d, i) {
                    return "geometry geometry" + i;
                }
            });
            geometryContainer.exit().remove();
            if (data[0] || hasGeometry) {
                var geometryConfigs = [];
                data.forEach(function (d, i) {
                    var geometryConfig = {};
                    geometryConfig.radialScale = radialScale;
                    geometryConfig.angularScale = angularScale;
                    geometryConfig.container = geometryContainer.filter(function (dB, iB) {
                        return iB === i;
                    });
                    geometryConfig.geometry = d.geometry;
                    geometryConfig.orientation = axisConfig.orientation;
                    geometryConfig.direction = axisConfig.direction;
                    geometryConfig.index = i;
                    geometryConfigs.push({
                        data: d,
                        geometryConfig: geometryConfig
                    });
                });
                var geometryConfigsGrouped = d3.nest().key(function (d, i) {
                    return typeof d.data.groupId !== "undefined" || "unstacked";
                }).entries(geometryConfigs);
                var geometryConfigsGrouped2 = [];
                geometryConfigsGrouped.forEach(function (d, i) {
                    if (d.key === "unstacked") {
                        geometryConfigsGrouped2 = geometryConfigsGrouped2.concat(d.values.map(function (d, i) {
                            return [d];
                        }));
                    } else {
                        geometryConfigsGrouped2.push(d.values);
                    }
                });
                geometryConfigsGrouped2.forEach(function (d, i) {
                    var geometry;
                    if (Array.isArray(d)) {
                        geometry = d[0].geometryConfig.geometry;
                    } else {
                        geometry = d.geometryConfig.geometry;
                    }
                    var finalGeometryConfig = d.map(function (dB, iB) {
                        return Mu.util.deepExtend(Mu[geometry].defaultConfig(), dB);
                    });
                    Mu[geometry]().config(finalGeometryConfig)();
                });
            }
            var guides = svg.select(".guides-group");
            var tooltipContainer = svg.select(".tooltips-group");
            angularTooltip = angularTooltip || Mu.tooltipPanel().config({
                container: tooltipContainer,
                fontSize: 8
            })();
            radialTooltip = radialTooltip || Mu.tooltipPanel().config({
                container: tooltipContainer,
                fontSize: 8
            })();
            geometryTooltip = geometryTooltip || Mu.tooltipPanel().config({
                container: tooltipContainer,
                fontSize: 8
            })();
            var angularValue, radialValue;
            if (!isOrdinal) {
                var angularGuideLine = guides.select("line").attr({
                    x1: 0,
                    y1: 0,
                    y2: 0
                }).style({
                    stroke: "grey",
                    "pointer-events": "none"
                });
                chartGroup.on("mousemove.angular-guide", function (d, i) {
                    var mouseAngle = Mu.util.getMousePos(backgroundCircle).angle;
                    angularGuideLine.attr({
                        x2: -radius,
                        transform: "rotate(" + mouseAngle + ")"
                    }).style({
                        opacity: .5
                    });
                    var angleWithOriginOffset = (mouseAngle + 180 + 360 - axisConfig.orientation) % 360;
                    angularValue = angularScale.invert(angleWithOriginOffset);
                    var pos = Mu.util.convertToCartesian(radius + 12, mouseAngle + 180);
                    angularTooltip.text(Mu.util.round(angularValue)).move([pos[0] + chartCenter[0], pos[1] + chartCenter[1]]);
                }).on("mouseout.angular-guide", function (d, i) {
                    guides.select("line").style({
                        opacity: 0
                    });
                });
            }
            if (axisConfig.radialAxis.visible !== false) {
                var angularGuideCircle = guides.select("circle").style({
                    stroke: "grey",
                    fill: "none"
                });
                chartGroup.on("mousemove.radial-guide", function (d, i) {
                    var r = Mu.util.getMousePos(backgroundCircle).radius;
                    angularGuideCircle.attr({
                        r: r
                    }).style({
                        opacity: .5
                    });
                    radialValue = radialScale.invert(Mu.util.getMousePos(backgroundCircle).radius);
                    var pos = Mu.util.convertToCartesian(r, axisConfig.radialAxis.orientation);
                    radialTooltip.text(Mu.util.round(radialValue)).move([pos[0] + chartCenter[0], pos[1] + chartCenter[1]]);
                }).on("mouseout.radial-guide", function (d, i) {
                    angularGuideCircle.style({
                        opacity: 0
                    });
                    geometryTooltip.hide();
                    angularTooltip.hide();
                    radialTooltip.hide();
                });
            }
            svg.selectAll(".geometry-group .mark").on("mouseover.tooltip", function (d, i) {
                var el = d3.select(this);
                var color = el.style("fill");
                var newColor = "black";
                var opacity = el.style("opacity") || 1;
                el.attr({
                    "data-opacity": opacity
                });
                if (color !== "none") {
                    el.attr({
                        "data-fill": color
                    });
                    newColor = d3.hsl(color).darker().toString();
                    el.style({
                        fill: newColor,
                        opacity: 1
                    });
                    var textData = {
                        t: Mu.util.round(d[0]),
                        r: Mu.util.round(d[1])
                    };
                    if (isOrdinal) {
                        textData.t = ticks[d[0]];
                    }
                    var text = "t: " + textData.t + ", r: " + textData.r;
                    var bbox = this.getBoundingClientRect();
                    var svgBBox = svg.node().getBoundingClientRect();
                    var pos = [bbox.left + bbox.width / 2 - centeringOffset[0] - svgBBox.left, bbox.top + bbox.height / 2 - centeringOffset[1] - svgBBox.top];
                    geometryTooltip.config({
                        color: newColor
                    }).text(text);
                    geometryTooltip.move(pos);
                } else {
                    color = el.style("stroke");
                    el.attr({
                        "data-stroke": color
                    });
                    newColor = d3.hsl(color).darker().toString();
                    el.style({
                        stroke: newColor,
                        opacity: 1
                    });
                }
            }).on("mousemove.tooltip", function (d, i) {
                if (d3.event.which !== 0) {
                    return false;
                }
                if (d3.select(this).attr("data-fill")) {
                    geometryTooltip.show();
                }
            }).on("mouseout.tooltip", function (d, i) {
                geometryTooltip.hide();
                var el = d3.select(this);
                var fillColor = el.attr("data-fill");
                if (fillColor) {
                    el.style({
                        fill: fillColor,
                        opacity: el.attr("data-opacity")
                    });
                } else {
                    el.style({
                        stroke: el.attr("data-stroke"),
                        opacity: el.attr("data-opacity")
                    });
                }
            });
        });
        return exports;
    }
    exports.render = function (_container) {
        render(_container);
        return this;
    };
    exports.config = function (_x) {
        if (!arguments.length) {
            return config;
        }
        var xClone = Mu.util.cloneJson(_x);
        xClone.data.forEach(function (d, i) {
            if (!config.data[i]) {
                config.data[i] = {};
            }
            Mu.util.deepExtend(config.data[i], Mu.Axis.defaultConfig().data[0]);
            Mu.util.deepExtend(config.data[i], d);
        });
        Mu.util.deepExtend(config.layout, Mu.Axis.defaultConfig().layout);
        Mu.util.deepExtend(config.layout, xClone.layout);
        return this;
    };
    exports.getLiveConfig = function () {
        return liveConfig;
    };
    exports.getinputConfig = function () {
        return inputConfig;
    };
    exports.radialScale = function (_x) {
        return radialScale;
    };
    exports.angularScale = function (_x) {
        return angularScale;
    };
    exports.svg = function () {
        return svg;
    };
    d3.rebind(exports, dispatch, "on");
    return exports;
};

Mu.Axis.defaultConfig = function (d, i) {
    var config = {
        data: [{
            t: [1, 2, 3, 4],
            r: [10, 11, 12, 13],
            name: "Line1",
            geometry: "LinePlot",
            color: null,
            strokeDash: "solid",
            strokeColor: null,
            strokeSize: "1",
            visibleInLegend: true,
            opacity: 1
        }],
        layout: {
            defaultColorRange: d3.scale.category10().range(),
            title: null,
            height: 450,
            width: 500,
            margin: {
                top: 40,
                right: 40,
                bottom: 40,
                left: 40
            },
            font: {
                size: 12,
                color: "gray",
                outlineColor: "white",
                family: "Tahoma, sans-serif"
            },
            direction: "clockwise",
            orientation: 0,
            labelOffset: 10,
            radialAxis: {
                domain: null,
                orientation: -45,
                ticksSuffix: "",
                visible: true,
                gridLinesVisible: true,
                tickOrientation: "horizontal",
                rewriteTicks: null
            },
            angularAxis: {
                domain: [0, 360],
                ticksSuffix: "",
                visible: true,
                gridLinesVisible: true,
                labelsVisible: true,
                tickOrientation: "horizontal",
                rewriteTicks: null,
                ticksCount: null,
                ticksStep: null
            },
            minorTicks: 0,
            tickLength: null,
            tickColor: "silver",
            minorTickColor: "#eee",
            backgroundColor: "none",
            needsEndSpacing: null,
            showLegend: true,
            legend: {
                reverseOrder: false
            },
            opacity: 1
        }
    };
    return config;
};

Mu.util = {};

Mu.DATAEXTENT = "dataExtent";

Mu.AREA = "AreaChart";

Mu.LINE = "LinePlot";

Mu.DOT = "DotPlot";

Mu.BAR = "BarChart";

Mu.PIE = "PieChart";

Mu.util._override = function (_objA, _objB) {
    for (var x in _objA) {
        if (x in _objB) {
            _objB[x] = _objA[x];
        }
    }
};

Mu.util._extend = function (_objA, _objB) {
    for (var x in _objA) {
        _objB[x] = _objA[x];
    }
};

Mu.util._rndSnd = function () {
    return Math.random() * 2 - 1 + (Math.random() * 2 - 1) + (Math.random() * 2 - 1);
};

Mu.util.dataFromEquation2 = function (_equation, _step) {
    var step = _step || 6;
    var data = d3.range(0, 360 + step, step).map(function (deg, index) {
        var theta = deg * Math.PI / 180;
        var radius = _equation(theta);
        return [deg, radius];
    });
    return data;
};

Mu.util.dataFromEquation = function (_equation, _step, _name) {
    var step = _step || 6;
    var t = [], r = [];
    d3.range(0, 360 + step, step).forEach(function (deg, index) {
        var theta = deg * Math.PI / 180;
        var radius = _equation(theta);
        t.push(deg);
        r.push(radius);
    });
    var result = {
        t: t,
        r: r
    };
    if (_name) {
        result.name = _name;
    }
    return result;
};

Mu.util.ensureArray = function (_val, _count) {
    if (typeof _val === "undefined") {
        return null;
    }
    var arr = [].concat(_val);
    return d3.range(_count).map(function (d, i) {
        return arr[i] || arr[0];
    });
};

Mu.util.fillArrays = function (_obj, _valueNames, _count) {
    _valueNames.forEach(function (d, i) {
        _obj[d] = Mu.util.ensureArray(_obj[d], _count);
    });
    return _obj;
};

Mu.util.cloneJson = function (json) {
    return JSON.parse(JSON.stringify(json));
};

Mu.util.deepExtend = function (destination, source) {
    for (var property in source) {
        if (source[property] && source[property].constructor && source[property].constructor === Object) {
            destination[property] = destination[property] || {};
            Mu.util.deepExtend(destination[property], source[property]);
        } else {
            destination[property] = source[property];
        }
    }
    return destination;
};

Mu.util.validateKeys = function (obj, keys) {
    if (typeof keys === "string") {
        keys = keys.split(".");
    }
    var next = keys.shift();
    return obj[next] && (!keys.length || objHasKeys(obj[next], keys));
};

Mu.util.sumArrays = function (a, b) {
    return d3.zip(a, b).map(function (d, i) {
        return d3.sum(d);
    });
};

Mu.util.arrayLast = function (a) {
    return a[a.length - 1];
};

Mu.util.arrayEqual = function (a, b) {
    var i = Math.max(a.length, b.length, 1);
    while (i-- >= 0 && a[i] === b[i]);
    return i === -2;
};

Mu.util.flattenArray = function (arr) {
    var r = [];
    while (!Mu.util.arrayEqual(r, arr)) {
        r = arr;
        arr = [].concat.apply([], arr);
    }
    return arr;
};

Mu.util.deduplicate = function (arr) {
    return arr.filter(function (v, i, a) {
        return a.indexOf(v) === i;
    });
};

Mu.util.convertToCartesian = function (radius, theta) {
    var thetaRadians = theta * Math.PI / 180;
    var x = radius * Math.cos(thetaRadians);
    var y = radius * Math.sin(thetaRadians);
    return [x, y];
};

Mu.util.round = function (_value, _digits) {
    var digits = _digits || 2;
    var mult = Math.pow(10, digits);
    return Math.round(_value * mult) / mult;
};

Mu.util.getMousePos = function (_referenceElement) {
    var mousePos = d3.mouse(_referenceElement.node());
    var mouseX = mousePos[0];
    var mouseY = mousePos[1];
    var mouse = {};
    mouse.x = mouseX;
    mouse.y = mouseY;
    mouse.pos = mousePos;
    mouse.angle = (Math.atan2(mouseY, mouseX) + Math.PI) * 180 / Math.PI;
    mouse.radius = Math.sqrt(mouseX * mouseX + mouseY * mouseY);
    return mouse;
};

Mu.util.duplicatesCount = function (arr) {
    var uniques = {}, val;
    var dups = {};
    for (var i = 0, len = arr.length; i < len; i++) {
        val = arr[i];
        if (val in uniques) {
            uniques[val]++;
            dups[val] = uniques[val];
        } else {
            uniques[val] = 1;
        }
    }
    return dups;
};

Mu.util.duplicates = function (arr) {
    return Object.keys(Mu.util.duplicatesCount(arr));
};

Mu.util.translator = function (obj, sourceBranch, targetBranch, reverse) {
    if (reverse) {
        var targetBranchCopy = targetBranch.slice();
        targetBranch = sourceBranch;
        sourceBranch = targetBranchCopy;
    }
    var value = sourceBranch.reduce(function (previousValue, currentValue) {
        if (typeof previousValue !== "undefined") {
            return previousValue[currentValue];
        }
    }, obj);
    if (typeof value === "undefined") {
        return;
    }
    sourceBranch.reduce(function (previousValue, currentValue, index) {
        if (typeof previousValue === "undefined") {
            return;
        }
        if (index === sourceBranch.length - 1) {
            delete previousValue[currentValue];
        }
        return previousValue[currentValue];
    }, obj);
    targetBranch.reduce(function (previousValue, currentValue, index) {
        if (typeof previousValue[currentValue] === "undefined") {
            previousValue[currentValue] = {};
        }
        if (index === targetBranch.length - 1) {
            previousValue[currentValue] = value;
        }
        return previousValue[currentValue];
    }, obj);
};

Mu.PolyChart = function module() {
    var config = [Mu.PolyChart.defaultConfig()];
    var dispatch = d3.dispatch("hover");
    var dashArray = {
        solid: "none",
        dash: [5, 2],
        dot: [2, 5]
    };
    var colorScale;
    function exports() {
        var geometryConfig = config[0].geometryConfig;
        var container = geometryConfig.container;
        if (typeof container === "string") {
            container = d3.select(container);
        }
        container.datum(config).each(function (_config, _index) {
            var isStack = !!_config[0].data.yStack;
            var data = _config.map(function (d, i) {
                if (isStack) {
                    return d3.zip(d.data.t[0], d.data.r[0], d.data.yStack[0]);
                } else {
                    return d3.zip(d.data.t[0], d.data.r[0]);
                }
            });
            var angularScale = geometryConfig.angularScale;
            var domainMin = geometryConfig.radialScale.domain()[0];
            var generator = {};
            generator.bar = function (d, i, pI) {
                var dataConfig = _config[pI].data;
                var h = geometryConfig.radialScale(d[1]) - geometryConfig.radialScale(0);
                var stackTop = geometryConfig.radialScale(d[2] || 0);
                var w = dataConfig.barWidth;
                d3.select(this).attr({
                    "class": "mark bar",
                    d: "M" + [[h + stackTop, -w / 2], [h + stackTop, w / 2], [stackTop, w / 2], [stackTop, -w / 2]].join("L") + "Z",
                    transform: function (d, i) {
                        return "rotate(" + (geometryConfig.orientation + angularScale(d[0])) + ")";
                    }
                });
            };
            generator.dot = function (d, i, pI) {
                var stackedData = d[2] ? [d[0], d[1] + d[2]] : d;
                var symbol = d3.svg.symbol().size(_config[pI].data.dotSize).type(_config[pI].data.dotType)(d, i);
                d3.select(this).attr({
                    "class": "mark dot",
                    d: symbol,
                    transform: function (d, i) {
                        var coord = convertToCartesian(getPolarCoordinates(stackedData));
                        return "translate(" + [coord.x, coord.y] + ")";
                    }
                });
            };
            var line = d3.svg.line.radial().interpolate(_config[0].data.lineInterpolation).radius(function (d) {
                return geometryConfig.radialScale(d[1]);
            }).angle(function (d) {
                return geometryConfig.angularScale(d[0]) * Math.PI / 180;
            });
            generator.line = function (d, i, pI) {
                var lineData = d[2] ? data[pI].map(function (d, i) {
                    return [d[0], d[1] + d[2]];
                }) : data[pI];
                d3.select(this).each(generator.dot).style({
                    opacity: function (dB, iB) {
                        return +_config[pI].data.dotVisible;
                    },
                    fill: markStyle.stroke(d, i, pI)
                }).attr({
                    "class": "mark dot"
                });
                if (i > 0) {
                    return;
                }
                var lineSelection = d3.select(this.parentNode).selectAll("path.line").data([0]);
                lineSelection.enter().insert("path");
                lineSelection.attr({
                    "class": "line",
                    d: line(lineData),
                    transform: function (dB, iB) {
                        return "rotate(" + (geometryConfig.orientation + 90) + ")";
                    },
                    "pointer-events": "none"
                }).style({
                    fill: function (dB, iB) {
                        return markStyle.fill(d, i, pI);
                    },
                    "fill-opacity": 0,
                    stroke: function (dB, iB) {
                        return markStyle.stroke(d, i, pI);
                    },
                    "stroke-width": function (dB, iB) {
                        return markStyle["stroke-width"](d, i, pI);
                    },
                    "stroke-dasharray": function (dB, iB) {
                        return markStyle["stroke-dasharray"](d, i, pI);
                    },
                    opacity: function (dB, iB) {
                        return markStyle.opacity(d, i, pI);
                    },
                    display: function (dB, iB) {
                        return markStyle.display(d, i, pI);
                    }
                });
            };
            var angularRange = geometryConfig.angularScale.range();
            var triangleAngle = Math.abs(angularRange[1] - angularRange[0]) / data[0].length * Math.PI / 180;
            var arc = d3.svg.arc().startAngle(function (d) {
                return -triangleAngle / 2;
            }).endAngle(function (d) {
                return triangleAngle / 2;
            }).innerRadius(function (d) {
                return geometryConfig.radialScale(domainMin + (d[2] || 0));
            }).outerRadius(function (d) {
                return geometryConfig.radialScale(domainMin + (d[2] || 0)) + geometryConfig.radialScale(d[1]);
            });
            generator.arc = function (d, i, pI) {
                d3.select(this).attr({
                    "class": "mark arc",
                    d: arc,
                    transform: function (d, i) {
                        return "rotate(" + (geometryConfig.orientation + angularScale(d[0]) + 90) + ")";
                    }
                });
            };
            var pieArc = d3.svg.arc().outerRadius(geometryConfig.radialScale.range()[1]);
            var pie = d3.layout.pie().value(function (d) {
                return d[1];
            });
            var pieData = pie(data[0]);
            generator.pie = function (d, i, pI) {
                d3.select(this).attr({
                    "class": "mark arc",
                    d: pieArc(pieData[i], i)
                });
            };
            var markStyle = {
                fill: function (d, i, pI) {
                    return _config[pI].data.color;
                },
                stroke: function (d, i, pI) {
                    return _config[pI].data.strokeColor;
                },
                "stroke-width": function (d, i, pI) {
                    return _config[pI].data.strokeSize + "px";
                },
                "stroke-dasharray": function (d, i, pI) {
                    return dashArray[_config[pI].data.strokeDash];
                },
                opacity: function (d, i, pI) {
                    return _config[pI].data.opacity;
                },
                display: function (d, i, pI) {
                    return typeof _config[pI].data.visible === "undefined" || _config[pI].data.visible ? "block" : "none";
                }
            };
            var geometryLayer = d3.select(this).selectAll("g.layer").data(data);
            geometryLayer.enter().append("g").attr({
                "class": "layer"
            });
            var geometry = geometryLayer.selectAll("path.mark").data(function (d, i) {
                return d;
            });
            geometry.enter().append("path").attr({
                "class": "mark"
            });
            geometry.style(markStyle).each(generator[geometryConfig.geometryType]);
            geometry.exit().remove();
            geometryLayer.exit().remove();
            function getPolarCoordinates(d, i) {
                var r = geometryConfig.radialScale(d[1]);
                var t = (geometryConfig.angularScale(d[0]) + geometryConfig.orientation) * Math.PI / 180;
                return {
                    r: r,
                    t: t
                };
            }
            function convertToCartesian(polarCoordinates) {
                var x = polarCoordinates.r * Math.cos(polarCoordinates.t);
                var y = polarCoordinates.r * Math.sin(polarCoordinates.t);
                return {
                    x: x,
                    y: y
                };
            }
        });
    }
    exports.config = function (_x) {
        if (!arguments.length) {
            return config;
        }
        _x.forEach(function (d, i) {
            if (!config[i]) {
                config[i] = {};
            }
            Mu.util.deepExtend(config[i], Mu.PolyChart.defaultConfig());
            Mu.util.deepExtend(config[i], d);
        });
        return this;
    };
    exports.getColorScale = function () {
        return colorScale;
    };
    d3.rebind(exports, dispatch, "on");
    return exports;
};

Mu.PolyChart.defaultConfig = function () {
    var config = {
        data: {
            name: "geom1",
            t: [[1, 2, 3, 4]],
            r: [[1, 2, 3, 4]],
            dotType: "circle",
            dotSize: 64,
            dotVisible: false,
            barWidth: 20,
            color: "#ffa500",
            strokeSize: 1,
            strokeColor: "silver",
            strokeDash: "solid",
            opacity: 1,
            index: 0,
            visible: true,
            visibleInLegend: true
        },
        geometryConfig: {
            geometry: "LinePlot",
            geometryType: "arc",
            direction: "clockwise",
            orientation: 0,
            container: "body",
            radialScale: null,
            angularScale: null,
            colorScale: d3.scale.category20()
        }
    };
    return config;
};

Mu.BarChart = function module() {
    return Mu.PolyChart();
};

Mu.BarChart.defaultConfig = function () {
    var config = {
        geometryConfig: {
            geometryType: "bar"
        }
    };
    return config;
};

Mu.AreaChart = function module() {
    return Mu.PolyChart();
};

Mu.AreaChart.defaultConfig = function () {
    var config = {
        geometryConfig: {
            geometryType: "arc"
        }
    };
    return config;
};

Mu.DotPlot = function module() {
    return Mu.PolyChart();
};

Mu.DotPlot.defaultConfig = function () {
    var config = {
        geometryConfig: {
            geometryType: "dot",
            dotType: "circle"
        }
    };
    return config;
};

Mu.LinePlot = function module() {
    return Mu.PolyChart();
};

Mu.LinePlot.defaultConfig = function () {
    var config = {
        geometryConfig: {
            geometryType: "line"
        }
    };
    return config;
};

Mu.Legend = function module() {
    var config = Mu.Legend.defaultConfig();
    var dispatch = d3.dispatch("hover");
    function exports() {
        var legendConfig = config.legendConfig;
        var flattenData = config.data.map(function (d, i) {
            return [].concat(d).map(function (dB, iB) {
                var element = Mu.util.deepExtend({}, legendConfig.elements[i]);
                element.name = dB;
                element.color = [].concat(legendConfig.elements[i].color)[iB];
                return element;
            });
        });
        var data = d3.merge(flattenData);
        data = data.filter(function (d, i) {
            return legendConfig.elements[i] && (legendConfig.elements[i].visibleInLegend || typeof legendConfig.elements[i].visibleInLegend === "undefined");
        });
        if (legendConfig.reverseOrder) {
            data = data.reverse();
        }
        var container = legendConfig.container;
        if (typeof container === "string" || container.nodeName) {
            container = d3.select(container);
        }
        var colors = data.map(function (d, i) {
            return d.color;
        });
        var lineHeight = legendConfig.fontSize;
        var isContinuous = legendConfig.isContinuous === null ? typeof data[0] === "number" : legendConfig.isContinuous;
        var height = isContinuous ? legendConfig.height : lineHeight * data.length;
        var legendContainerGroup = container.classed("legend-group", true);
        var svg = legendContainerGroup.selectAll("svg").data([0]);
        var svgEnter = svg.enter().append("svg").attr({
            width: 300,
            height: height + lineHeight,
            xmlns: "http://www.w3.org/2000/svg",
            "xmlns:xlink": "http://www.w3.org/1999/xlink",
            version: "1.1"
        });
        svgEnter.append("g").classed("legend-axis", true);
        svgEnter.append("g").classed("legend-marks", true);
        var dataNumbered = d3.range(data.length);
        var colorScale = d3.scale[isContinuous ? "linear" : "ordinal"]().domain(dataNumbered).range(colors);
        var dataScale = d3.scale[isContinuous ? "linear" : "ordinal"]().domain(dataNumbered)[isContinuous ? "range" : "rangePoints"]([0, height]);
        var shapeGenerator = function (_type, _size) {
            var squareSize = _size * 3;
            if (_type === "line") {
                return "M" + [[-_size / 2, -_size / 12], [_size / 2, -_size / 12], [_size / 2, _size / 12], [-_size / 2, _size / 12]] + "Z";
            } else if (d3.svg.symbolTypes.indexOf(_type) !== -1) {
                return d3.svg.symbol().type(_type).size(squareSize)();
            } else {
                return d3.svg.symbol().type("square").size(squareSize)();
            }
        };
        if (isContinuous) {
            var gradient = svg.select(".legend-marks").append("defs").append("linearGradient").attr({
                id: "grad1",
                x1: "0%",
                y1: "0%",
                x2: "0%",
                y2: "100%"
            }).selectAll("stop").data(colors);
            gradient.enter().append("stop");
            gradient.attr({
                offset: function (d, i) {
                    return i / (colors.length - 1) * 100 + "%";
                }
            }).style({
                "stop-color": function (d, i) {
                    return d;
                }
            });
            svg.append("rect").classed("legend-mark", true).attr({
                height: legendConfig.height,
                width: legendConfig.colorBandWidth,
                fill: "url(#grad1)"
            });
        } else {
            var legendElement = svg.select(".legend-marks").selectAll("path.legend-mark").data(data);
            legendElement.enter().append("path").classed("legend-mark", true);
            legendElement.attr({
                transform: function (d, i) {
                    return "translate(" + [lineHeight / 2, dataScale(i) + lineHeight / 2] + ")";
                },
                d: function (d, i) {
                    var symbolType = d.symbol;
                    return shapeGenerator(symbolType, lineHeight);
                },
                fill: function (d, i) {
                    return colorScale(i);
                }
            });
            legendElement.exit().remove();
        }
        var legendAxis = d3.svg.axis().scale(dataScale).orient("right");
        var axis = svg.select("g.legend-axis").attr({
            transform: "translate(" + [isContinuous ? legendConfig.colorBandWidth : lineHeight, lineHeight / 2] + ")"
        }).call(legendAxis);
        axis.selectAll(".domain").style({
            fill: "none",
            stroke: "none"
        });
        axis.selectAll("line").style({
            fill: "none",
            stroke: isContinuous ? legendConfig.textColor : "none"
        });
        axis.selectAll("text").style({
            fill: legendConfig.textColor,
            "font-size": legendConfig.fontSize
        }).text(function (d, i) {
            return data[i].name;
        });
        return exports;
    }
    exports.config = function (_x) {
        if (!arguments.length) {
            return config;
        }
        Mu.util.deepExtend(config, _x);
        return this;
    };
    d3.rebind(exports, dispatch, "on");
    return exports;
};

Mu.Legend.defaultConfig = function (d, i) {
    var config = {
        data: ["a", "b", "c"],
        legendConfig: {
            elements: [{
                symbol: "line",
                color: "red"
            }, {
                symbol: "square",
                color: "yellow"
            }, {
                symbol: "diamond",
                color: "limegreen"
            }],
            height: 150,
            colorBandWidth: 30,
            fontSize: 12,
            container: "body",
            isContinuous: null,
            textColor: "grey",
            reverseOrder: false
        }
    };
    return config;
};

Mu.tooltipPanel = function () {

    var tooltipEl, tooltipTextEl, backgroundEl;
    var config = {
        container: null,
        hasTick: false,
        fontSize: 12,
        color: "white",
        padding: 5
    };
    var id = "tooltip-" + Mu.tooltipPanel.uid++;
    var tickSize = 10;
    var exports = function () {
        tooltipEl = config.container.selectAll("g." + id).data([0]);
        var tooltipEnter = tooltipEl.enter().append("g").classed(id, true).style({
            "pointer-events": "none",
            display: "none"
        });
        backgroundEl = tooltipEnter.append("path").style({
            fill: "white",
            "fill-opacity": .9
        }).attr({
            d: "M0 0"
        });
        tooltipTextEl = tooltipEnter.append("text").attr({
            dx: config.padding + tickSize,
            dy: +config.fontSize * .3
        });
        return exports;
    };
    exports.text = function (_text) {
        var l = d3.hsl(config.color).l;
        var strokeColor = l >= .5 ? "#aaa" : "white";
        var fillColor = l >= .5 ? "black" : "white";
        var text = _text || "";
        tooltipTextEl.style({
            fill: fillColor,
            "font-size": config.fontSize + "px"
        }).text(text);
        var padding = config.padding;
        var bbox = tooltipTextEl.node().getBBox();
        var boxStyle = {
            fill: config.color,
            stroke: strokeColor,
            "stroke-width": "2px"
        };
        var backGroundW = bbox.width + padding * 2 + tickSize;
        var backGroundH = bbox.height + padding * 2;
        backgroundEl.attr({
            d: "M" + [[tickSize, -backGroundH / 2], [tickSize, -backGroundH / 4], [config.hasTick ? 0 : tickSize, 0], [tickSize, backGroundH / 4], [tickSize, backGroundH / 2], [backGroundW, backGroundH / 2], [backGroundW, -backGroundH / 2]].join("L") + "Z"
        }).style(boxStyle);
        tooltipEl.attr({
            transform: "translate(" + [tickSize, -backGroundH / 2 + padding * 2] + ")"
        });
        tooltipEl.style({
            display: "block"
        });
        return exports;
    };
    exports.move = function (_pos) {
        if (!tooltipEl) {
            return;
        }
        tooltipEl.attr({
            transform: "translate(" + [_pos[0], _pos[1]] + ")"
        }).style({
            display: "block"
        });
        return exports;
    };
    exports.hide = function () {
        if (!tooltipEl) {
            return;
        }
        tooltipEl.style({
            display: "none"
        });
        return exports;
    };
    exports.show = function () {
        if (!tooltipEl) {
            return;
        }
        tooltipEl.style({
            display: "block"
        });
        return exports;
    };
    exports.config = function (_x) {
        Mu.util.deepExtend(config, _x);
        return exports;
    };
    return exports;
};

Mu.tooltipPanel.uid = 1;

Mu.adapter = {};

Mu.adapter.plotly = function module() {
    var exports = {};
    exports.convert = function (_inputConfig, reverse) {
        var outputConfig = {};
        if (_inputConfig.data) {
            outputConfig.data = _inputConfig.data.map(function (d, i) {
                var r = Mu.util.deepExtend({}, d);
                var toTranslate = [[r, ["marker", "color"], ["color"]], [r, ["marker", "opacity"], ["opacity"]], [r, ["marker", "line", "color"], ["strokeColor"]], [r, ["marker", "line", "dash"], ["strokeDash"]], [r, ["marker", "line", "width"], ["strokeSize"]], [r, ["marker", "symbol"], ["dotType"]], [r, ["marker", "size"], ["dotSize"]], [r, ["marker", "barWidth"], ["barWidth"]], [r, ["line", "interpolation"], ["lineInterpolation"]], [r, ["showlegend"], ["visibleInLegend"]]];
                toTranslate.forEach(function (d, i) {
                    Mu.util.translator.apply(null, d.concat(reverse));
                });
                if (!reverse) {
                    delete r.marker;
                }
                if (reverse) {
                    delete r.groupId;
                }
                if (!reverse) {
                    if (r.type === "scatter") {
                        if (r.mode === "lines") {
                            r.geometry = "LinePlot";
                        } else if (r.mode === "markers") {
                            r.geometry = "DotPlot";
                        } else if (r.mode === "lines+markers") {
                            r.geometry = "LinePlot";
                            r.dotVisible = true;
                        }
                    } else if (r.type === "area") {
                        r.geometry = "AreaChart";
                    } else if (r.type === "bar") {
                        r.geometry = "BarChart";
                    }
                    delete r.mode;
                    delete r.type;
                } else {
                    if (r.geometry === "LinePlot") {
                        r.type = "scatter";
                        if (r.dotVisible === true) {
                            delete r.dotVisible;
                            r.mode = "lines+markers";
                        } else {
                            r.mode = "lines";
                        }
                    } else if (r.geometry === "DotPlot") {
                        r.type = "scatter";
                        r.mode = "markers";
                    } else if (r.geometry === "AreaChart") {
                        r.type = "area";
                    } else if (r.geometry === "BarChart") {
                        r.type = "bar";
                    }
                    delete r.geometry;
                }
                return r;
            });
            if (!reverse && _inputConfig.layout && _inputConfig.layout.barmode === "stack") {
                var duplicates = Mu.util.duplicates(outputConfig.data.map(function (d, i) {
                    return d.geometry;
                }));
                outputConfig.data.forEach(function (d, i) {
                    var idx = duplicates.indexOf(d.geometry);
                    if (idx !== -1) {
                        outputConfig.data[i].groupId = idx;
                    }
                });
            }
        }
        if (_inputConfig.layout) {
            var r = Mu.util.deepExtend({}, _inputConfig.layout);
            var toTranslate = [[r, ["plot_bgcolor"], ["backgroundColor"]], [r, ["showlegend"], ["showLegend"]], [r, ["radialaxis"], ["radialAxis"]], [r, ["angularaxis"], ["angularAxis"]], [r.angularaxis, ["showline"], ["gridLinesVisible"]], [r.angularaxis, ["showticklabels"], ["labelsVisible"]], [r.angularaxis, ["nticks"], ["ticksCount"]], [r.angularaxis, ["tickorientation"], ["tickOrientation"]], [r.angularaxis, ["ticksuffix"], ["ticksSuffix"]], [r.angularaxis, ["range"], ["domain"]], [r.angularaxis, ["endpadding"], ["endPadding"]], [r.radialaxis, ["showline"], ["gridLinesVisible"]], [r.radialaxis, ["tickorientation"], ["tickOrientation"]], [r.radialaxis, ["ticksuffix"], ["ticksSuffix"]], [r.radialaxis, ["range"], ["domain"]], [r.angularAxis, ["showline"], ["gridLinesVisible"]], [r.angularAxis, ["showticklabels"], ["labelsVisible"]], [r.angularAxis, ["nticks"], ["ticksCount"]], [r.angularAxis, ["tickorientation"], ["tickOrientation"]], [r.angularAxis, ["ticksuffix"], ["ticksSuffix"]], [r.angularAxis, ["range"], ["domain"]], [r.angularAxis, ["endpadding"], ["endPadding"]], [r.radialAxis, ["showline"], ["gridLinesVisible"]], [r.radialAxis, ["tickorientation"], ["tickOrientation"]], [r.radialAxis, ["ticksuffix"], ["ticksSuffix"]], [r.radialAxis, ["range"], ["domain"]], [r.font, ["outlinecolor"], ["outlineColor"]], [r.legend, ["traceorder"], ["reverseOrder"]], [r, ["labeloffset"], ["labelOffset"]], [r, ["defaultcolorrange"], ["defaultColorRange"]]];
            toTranslate.forEach(function (d, i) {
                Mu.util.translator.apply(null, d.concat(reverse));
            });
            if (!reverse) {
                if (r.angularAxis && typeof r.angularAxis.ticklen !== "undefined") {
                    r.tickLength = r.angularAxis.ticklen;
                }
                if (r.angularAxis && typeof r.angularAxis.tickcolor !== "undefined") {
                    r.tickColor = r.angularAxis.tickcolor;
                }
            } else {
                if (typeof r.tickLength !== "undefined") {
                    r.angularaxis.ticklen = r.tickLength;
                    delete r.tickLength;
                }
                if (r.tickColor) {
                    r.angularaxis.tickcolor = r.tickColor;
                    delete r.tickColor;
                }
            }
            if (r.legend && typeof r.legend.reverseOrder !== "boolean") {
                r.legend.reverseOrder = r.legend.reverseOrder !== "normal";
            }
            if (r.legend && typeof r.legend.traceorder === "boolean") {
                r.legend.traceorder = r.legend.traceorder ? "reversed" : "normal";
                delete r.legend.reverseOrder;
            }
            if (r.margin && typeof r.margin.t !== "undefined") {
                var source = ["t", "r", "b", "l", "pad"];
                var target = ["top", "right", "bottom", "left", "pad"];
                var margin = {};
                d3.entries(r.margin).forEach(function (dB, iB) {
                    margin[target[source.indexOf(dB.key)]] = dB.value;
                });
                r.margin = margin;
            }
            if (reverse) {
                delete r.needsEndSpacing;
                delete r.minorTickColor;
                delete r.minorTicks;
                if (r.angularaxis) {
                    delete r.angularaxis.ticksCount;
                    delete r.angularaxis.ticksCount;
                    delete r.angularaxis.ticksStep;
                    delete r.angularaxis.rewriteTicks;
                    delete r.angularaxis.nticks;
                }
                if (r.radialaxis) {
                    delete r.radialaxis.ticksCount;
                    delete r.radialaxis.ticksCount;
                    delete r.radialaxis.ticksStep;
                    delete r.radialaxis.rewriteTicks;
                    delete r.radialaxis.nticks;
                }
            }
            outputConfig.layout = r;
        }
        return outputConfig;
    };
    return exports;
};

//slightly slower
function objHasKeys(obj, keys) {
    var next = keys.shift();
    return obj[next] && (!keys.length || objHasKeys(obj[next], keys));
}
