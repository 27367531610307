import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import PracticesCarendar from './PracticesCarendar/PracticesCarendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tab, Tabs } from 'react-bootstrap-tabs';

class LeftPanel extends Component {

  static propTypes = {
  }

  constructor(props) {
    super(props);

    this.state = {
      memberPolar : null,
      polarSize: 400,
      selectedSpd: 0,
      selectedDir: 0,
    };

  }


  render(){

    const mode_polar = (
        <Tab label="Compare">
          <PracticesCarendar
            compare={true}
          />
        </Tab>
    );


    return (
      <div className="container-fluid">
        <div id="menuswitch">
          <div align="right">
            <FontAwesomeIcon icon="backward" onClick={this.props.handleCloseLeftPanel}/>
          </div>
        </div>
        <Tabs>
          <Tab label="Main">
            <PracticesCarendar
              compare={false}
            />
          </Tab>
          { mode_polar }
        </Tabs>
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    target: state.PlayerSelector.target,
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftPanel);
