import React, { Component } from 'react'
import { RadarChart, PolarGrid, PolarAngleAxis, Radar, Tooltip } from 'recharts';
import { Container, Row, Col, Button, ButtonGroup, ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

export default class Polar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      polar: null,
      memberPolar: null,
      oldSelectedDir: 0,
      maxVmg: 0,
    };
  }

  componentDidMount() {
    //Polarデータの初期化
    if (!this.props.polar)
      return;
    let polar = [];
    for (let key in this.props.polar) {
      let data = {
        angle: key,
        value: this.props.polar[key],
        memberValue: 0,
        selectedSpd: 0,
        maxVmgStarPort: 0,
        maxVmgUpDown: 0,
      };
      polar.push(data);
    }
    polar[this.props.maxVmg.max_port.direction].maxVmgStarPort = this.props.maxVmg.max_port.spd;
    polar[this.props.maxVmg.max_starboad.direction].maxVmgStarPort = this.props.maxVmg.max_starboad.spd;

    polar[this.props.maxVmg.upper_port.direction].maxVmgUpDown = this.props.maxVmg.upper_port.spd;
    polar[this.props.maxVmg.upper_starboad.direction].maxVmgUpDown = this.props.maxVmg.upper_starboad.spd;
    polar[this.props.maxVmg.down_port.direction].maxVmgUpDown = this.props.maxVmg.down_port.spd;
    polar[this.props.maxVmg.down_starboad.direction].maxVmgUpDown = this.props.maxVmg.down_starboad.spd;

    this.setState({
      polar: polar,
    });
  }

  componentWillReceiveProps(nextProps) {
    let polar = [];
    if (this.state.polar) {
      if (this.state.polar.length >= 1) {
        polar = Array.from(this.state.polar);

        for (let key in nextProps.polar) {
          polar[key].value = nextProps.polar[key];
          polar[key].maxVmgStarPort = 0;
          polar[key].maxVmgUpDown = 0;
        }

        if (this.props.maxVmg != null) {

          // if (this.props.maxVmg.down_sarboad) {

          polar[this.props.maxVmg.max_port.direction].maxVmgStarPort = this.props.maxVmg.max_port.spd;
          polar[this.props.maxVmg.max_starboad.direction].maxVmgStarPort = this.props.maxVmg.max_starboad.spd;

          polar[this.props.maxVmg.upper_port.direction].maxVmgUpDown = this.props.maxVmg.upper_port.spd;
          polar[this.props.maxVmg.upper_starboad.direction].maxVmgUpDown = this.props.maxVmg.upper_starboad.spd;
          polar[this.props.maxVmg.down_port.direction].maxVmgUpDown = this.props.maxVmg.down_port.spd;
          polar[this.props.maxVmg.down_starboad.direction].maxVmgUpDown = this.props.maxVmg.down_starboad.spd;
          // }
        }

        polar[this.state.oldSelectedDir].selectedSpd = 0;
        polar[nextProps.selectedDir].selectedSpd = nextProps.selectedSpd;

        if (nextProps.memberPolar) {

          for (let key in nextProps.polar) {
            polar[key].memberValue = nextProps.memberPolar[key];
          }
        } else {
          for (let key in nextProps.polar) {
            polar[key].memberValue = 0;
          }
        }
        this.state.oldSelectedDir = this.props.selectedDir;
      }

      this.setState({ polar: polar });
    }
  }


  render() {
    if (!this.props.polar)
      return <p>No polar data. Please re-calculate practice.</p>;

    return (
      <div>
        <Container>
          <Row>
            <Col xs={7}>
              <RadarChart // レーダーチャートのサイズや位置、データを指定
                height={this.props.polarSize} //レーダーチャートの全体の高さを指定
                width={this.props.polarSize} //レーダーチャートの全体の幅を指定
                cx="40%" //要素の左を基準に全体の50%移動
                cy="40%" //要素の上を基準に全体の50%移動
                data={this.state.polar} //ここにArray型のデータを指定
              >
                <PolarGrid /> // レーダーのグリッド線を表示
                {/* <PolarAngleAxis
            dataKey="angle" //Array型のデータの、数値を表示したい値のキーを指定
          /> */}
                <Radar //レーダーの色や各パラメーターのタイトルを指定
                  name="SPD"  //hoverした時に表示される名前を指定
                  dataKey="memberValue" //Array型のデータのパラメータータイトルを指定
                  stroke="#777777"  //レーダーの線の色を指定
                  fill="#777777" //レーダーの中身の色を指定
                  fillOpacity={0.6} //レーダーの中身の色の薄さを指定
                />
                <Radar //レーダーの色や各パラメーターのタイトルを指定
                  name="SPD"  //hoverした時に表示される名前を指定
                  dataKey="value" //Array型のデータのパラメータータイトルを指定
                  stroke="#7DC5FB"  //レーダーの線の色を指定
                  fill="#7DC5FB" //レーダーの中身の色を指定
                  fillOpacity={0.6} //レーダーの中身の色の薄さを指定
                />
                <Radar //レーダーの色や各パラメーターのタイトルを指定
                  name="SPD"  //hoverした時に表示される名前を指定
                  dataKey="selectedSpd" //Array型のデータのパラメータータイトルを指定
                  stroke="#d88884"  //レーダーの線の色を指定
                  fill="#d88884" //レーダーの中身の色を指定
                  fillOpacity={0.6} //レーダーの中身の色の薄さを指定
                />
                <Radar //レーダーの色や各パラメーターのタイトルを指定
                  name="SPD"  //hoverした時に表示される名前を指定
                  dataKey="maxVmgStarPort" //Array型のデータのパラメータータイトルを指定
                  stroke="#7400C5"  //レーダーの線の色を指定
                  fill="#7400C5" //レーダーの中身の色を指定
                  fillOpacity={0.6} //レーダーの中身の色の薄さを指定
                />
                <Radar //レーダーの色や各パラメーターのタイトルを指定
                  name="SPD"  //hoverした時に表示される名前を指定
                  dataKey="maxVmgUpDown" //Array型のデータのパラメータータイトルを指定
                  stroke="#00FF11"  //レーダーの線の色を指定
                  fill="#00FF11" //レーダーの中身の色を指定
                  fillOpacity={0.6} //レーダーの中身の色の薄さを指定
                />

                <Tooltip /> //hoverすると各パラメーターの値が表示される
              </RadarChart>
            </Col>
          </Row>
        </Container>
      </div>

    );
  }

}
