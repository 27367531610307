import React, { Component } from 'react'
import L from 'leaflet'
import { CircleMarker , Popup , Polyline } from 'react-leaflet'
import RotatedMarker from './rotatedMarker'
import TurnMarkIcon from '../sidePanel/PracticesCarendar/ConfigModal/map/turnmark.png';


const markIcon = new L.Icon({
    iconUrl: TurnMarkIcon,
    iconSize: new L.Point(20, 20),
});

export default class TurnMark extends Component {

  render() {

    var coordinate = [0,0];
    if(this.props.coordinate[0]){
      coordinate = [this.props.coordinate[1], this.props.coordinate[0]];
    }

    return (
          <RotatedMarker
              position={ coordinate }
              icon={ markIcon }
          >
          </RotatedMarker>
  );}
}
