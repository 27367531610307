//Edit
import API from '../components/API';

export const getDevices = () => {

  return (dispatch, getState) => {

    const token = getState().auth.token;

    let onSuccess = function (res) {
      if (res.status === 200) {

        //PagiList$BMQ%G%P%$%90lMw$N@8@.(B
        let devices = [];
        res.data.forEach((dev) => {
          let row = {
            id: dev.id,
            category: dev.category,
            user: " - ",
            searchstr: "" + dev.id,
          }
          if (dev.user) {
            row.user_id = dev.user.id;
            row.user = dev.user.last_name + " " + dev.user.first_name;
            row.searchstr = dev.id + "" + dev.user.last_name + " " + dev.user.first_name;
          }
          devices.push(row);
        });

        dispatch({ type: 'GET_DEVICES_FOR_LIST', devices: devices });

      } else {
        dispatch({ type: "GET_DEVICES_ERROR", data: res.data });
        throw res.data;
      }
    }

    let onError = function (res) {
      if (res.status >= 500) {
        console.log("Server Error!");
        throw res;
      } else if (res.status >= 400 && res.status < 500) {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      } else {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      }
    }

    API.getDevices({ token: token, onSuccess: onSuccess, onError: onError });
  }
}



export const getDevice = (id) => {

  return (dispatch, getState) => {
    dispatch({ type: 'GET_DEVICE', device: { id: id } });
  }
}

export const getCategory = () => {

  return (dispatch, getState) => {
    dispatch({ type: 'GET_CATEGORY', category: { id: 1 } });
  }
}


export const updateDevice = (device, history) => {

  return (dispatch, getState) => {

    //dispatch({type: "PRACTICE_LOADING"});

    const token = getState().auth.token;

    let onSuccess = function (res, history) {
      if (res.status === 200) {
        dispatch({ type: "SET_MESSAGE", msgType: 'info', message: "Updated." });
        dispatch({ type: "SET_DEVICES", devices: undefined });
        history.push('/devices');
      } else {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      }
    }

    let onError = function (res) {
      if (res.status >= 500) {
        console.log("Server Error!");
        dispatch({ type: "SET_MESSAGE", msgType: 'error', message: res.data });
        throw res;
      } else if (res.status >= 400 && res.status < 500) {
        dispatch({ type: "SET_MESSAGE", msgType: 'error', message: res.data });
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      } else {
        dispatch({ type: "SET_MESSAGE", msgType: 'error', message: res.data });
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      }
    }

    API.putDevice({ token: token, device: device, onSuccess: onSuccess, onError: onError, history: history });
  }
}


export const getDevicesInfo = (devices) => {

  return (dispatch, getState) => {

    const token = getState().auth.token;

    let onSuccess = function (res, devices) {
      if (res.status === 200) {
        let rets = res.data.devices;
        for (let i in devices) {
          for (let ret of rets) {
            let id = Object.keys(ret)[0];
            if (id == devices[i].id) {
              devices[i].lastGPS = ret[id].lastGPS;
              devices[i].lastAccess = ret[id].lastAccess;
              devices[i].percentage = ret[id].battery ? ret[id].battery.percentage : 0
              devices[i].voltage = ret[id].battery ? ret[id].battery.voltage : 0
            }
          }
        }
        dispatch({ type: "GET_DEVICES_INFO", devices: devices });
      } else {
        console.log("GET_DEVICES_INFO ERROR");
        console.log(res.data);
        //dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        //throw res.data;
      }
    }

    let onError = function (res) {
      if (res.status >= 500) {
        console.log("Server Error!");
        dispatch({ type: "SET_MESSAGE", msgType: 'danger', message: res.data });
        throw res;
      } else if (res.status >= 400 && res.status < 500) {
        dispatch({ type: "SET_MESSAGE", msgType: 'danger', message: res.data });
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      } else {
        //dispatch({ type: "SET_MESSAGE", msgType: 'danger', message: res.data });
        //dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        //throw res.data;
        console.log("GET_DEVICES_INFO ERROR");
        console.log(res.data);
      }
    }

    API.getDevicesInfo({ token: token, onSuccess: onSuccess, onError: onError, devices: devices });
  }
}


