import React, { Component } from 'react'
import L from 'leaflet'
import { Polyline } from 'react-leaflet'


export default class WindLine extends Component {

  // pos2---------pos-------------pos1

  render() {

    if(!this.props.pos)
      return;

    //i$BEY$"$?$j$N5wN%(B(km$B$J$N$G(B1000$B$G%a!<%H%k$K(B)
    const L = 6371 * 2 * Math.PI / 360 * 1000;
    const subColor = '#a9ceec';

    let x = this.props.pos.lng;
    let y = this.props.pos.lat;

    //$B86E@<~$j(B
    let x1 = x + (this.props.lineLength / L) - x;
    let x2 = x - (this.props.lineLength / L) - x;


    let deg = -1 * this.props.windDir * (Math.PI / 180)

    //$B:BI82sE>(B
    let pos1 = {lng: x1*Math.cos(deg)-0*Math.sin(deg),
                lat: 0*Math.cos(deg) +x1*Math.sin(deg)};
    pos1.lng = pos1.lng + x;
    pos1.lat = pos1.lat + y;

    let pos2 = {lng: x2*Math.cos(deg)-0*Math.sin(deg),
                lat: 0*Math.cos(deg) +x2*Math.sin(deg)};
    pos2.lng = pos2.lng + x;
    pos2.lat = pos2.lat + y;

    let positions = [];

    positions.push(pos2);
    positions.push(pos1);
    
    return (
      <Polyline 
        positions={ positions } 
        opacity={0.6}
      />
    );
   }

}
