import React, { Component } from 'react'
import L from 'leaflet'
import { Polyline } from 'react-leaflet'

export default class CalcLine extends Component {

  render() {
		const color = '#3cb371';
    return (
      <span>
        <Polyline
          positions={this.props.calcLine}
          opacity={0.9}
          color={color}
        />
      </span>
    );
  }

}
