import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Tree, { TreeNode } from 'rc-tree';
import CogActive from './cog_active.svg';
import CogNegative from './cog_negative.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConfigModal from './ConfigModal/ConfigModal';
import { connect } from "react-redux";

import 'rc-tree/assets/index.css';

const isLegKey = (key) => {
  return key.includes('leg');
};

const isPracticeKey = (key) => {
  return !isLegKey(key);
};

class PracticeTree extends Component {
  static propTypes = {
    user: PropTypes.object,
    target: PropTypes.object,
    practice: PropTypes.object,
    handleOpenExternalDataModal: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      openExternalDataModal: false,
    };
    // ExternalData
    this.handleOpenExternalDataModal = this.handleOpenExternalDataModal.bind(this);
    this.handleCloseExternalDataModal = this.handleCloseExternalDataModal.bind(this);
  }

  handleOpenExternalDataModal(practice) {
    practice.load({ onSuccess: () => this.setState({ openExternalDataModal: true }) });
    this.setState({ practiceToUploadExternalData: practice });
  }

  handleCloseExternalDataModal() {
    this.setState({ openExternalDataModal: false, practiceToUploadExternalData: null });
  }


  handleTreeNodeSelect = (prevSelectedKeys) => {
    if (prevSelectedKeys.length === 0){
      return;
    }
    // prevSelectedKeys is a array of selected key.
    // Because last element is most recent, we check last element and decide detail bahaivior
    let selectedKeys = null;
    let target = null;
    let mostRecentKey = _.last(prevSelectedKeys);
    if (isLegKey(mostRecentKey)){
      // If leg key is selected, we only show one leg without considering previous keys
      selectedKeys = [mostRecentKey];
      // Find target leg
      target = this.props.practice.findLegByUUID(mostRecentKey);
    }else if (isPracticeKey(mostRecentKey)){
      // If practice is selected, we filter-out leg from previsous keys
      // let selectedPracticeKeys = prevSelectedKeys.filter((key) => isPracticeKey(key));
      // selectedKeys = selectedPracticeKeys;
      // target = this.props.practices.filter((practice) => selectedKeys.includes(practice.uuid));

      // FIXME we need to aggregate multiple practices
      selectedKeys = [mostRecentKey];
      target = this.props.practices.findByUUID(mostRecentKey);
    }
    this.setState({selectedKeys: selectedKeys});
    this.props.handleSelectTargetPracticesOrLeg(target);
  }

  shouldComponentUpdate() {
    return true;
  }

  practiceTitle(practice, index){
    const icon = practice.hasAllExternalData ? CogActive : CogNegative;
    let download = null;
    if (practice.excel_url){
      download = <FontAwesomeIcon icon="download" onClick={() => {window.open(practice.excel_url);}}/>;
    }else{
      download = null;
    }
    let configButton = null;
    //if (practice.user.id === this.props.user.id){
      configButton = <img className={'cog-button'} src={icon} onClick={() => this.handleOpenExternalDataModal(practice)}/>;
    //}
    return (
      <span className={'practice'}>
        {practice.treeTitle(index+1)}
        <br />
        <span className={'config-button'}>
          {configButton}
        </span>
      </span>
    );
  }

  treeNodes() {

    //ツリーに表示される順序を昇順に
    this.props.practice._practices.sort(
      function(a,b){
        return (a.id > b.id ? 1 : -1);
      });

    let treeNodes = this.props.practice.map((practice, i) => {
      if (practice.isEmpty()){
        let legNodes = <TreeNode key={practice.uuid} title={'Empty'}></TreeNode>;
        return <TreeNode key={practice.uuid} title={this.practiceTitle(practice, i)} className={'practice-node'}>{legNodes}</TreeNode>;
      }
      else{
        let legNodes = practice.legs.map((leg,index) => (<TreeNode icon={<span></span>} isLeaf={true} key={leg.uuid} title={leg.treeTitle(index+1)}></TreeNode>));
        return <TreeNode key={practice.uuid} title={this.practiceTitle(practice, i)} className={'practice-node'}>{legNodes}</TreeNode>;
      }
    });
    return treeNodes;
  }

  render() {
    if (this.props.practices == null){
      return <div>この月のデータはありません</div>;
    }
    else if (this.props.practices._practices.length == 0){
      return <div>この月のデータはありません</div>;
    }
    else if (this.props.target == undefined || this.props.practice == undefined){
      return <div>データを選択してください</div>;
    }
    return (
      <div>
      <Tree
        multiple defaultExpandAll showLine selectable
        onSelect={this.handleTreeNodeSelect}
        selectedKeys={this.props.target.uuids}
        className={'practice-tree'}
      >
        {this.treeNodes()}
      </Tree>

      <ConfigModal
        open={this.state.openExternalDataModal}
        practice={this.state.practiceToUploadExternalData}
        handleCloseExternalDataModal={this.handleCloseExternalDataModal}
        user={this.props.targetUser}
      />
      </div>
    );
  }
}

PracticeTree.propTypes = {
  practices: PropTypes.any,
  target: PropTypes.object,
  handleSelectTargetPracticesOrLeg: PropTypes.func,
};


const mapStateToProps = state => {
  return {
    targetUser: state.PlayerSelector.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PracticeTree);
