import React, { Component } from "react";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";
import { main } from "../../actions";
import { moment } from "moment";

class CardContent extends Component {

  constructor(props) {
    super(props);
    this.state = { time: null };
    this.loadTarget = this.loadTarget.bind(this);
    this.finalizeLoadTarget = this.finalizeLoadTarget.bind(this)
  }
  componentDidMount() {
    this.setState({
      practices: this.props.practices,
    });
  }

  loadTarget(target, hoge) {
    this.props.loading();

    if (target != undefined) {
      target.themeColor1 = '#ff5100';
      target.themeColor2 = '#3a5a98';
      target.load({ onSuccess: this.finalizeLoadTarget, props: this.props });

      let time = target.started_at.clone();
      this.props.setCalendarDay(time);
      this.setState({ time: time.clone() });

      //カレンダーをオレンジにする
      this.props.setSelectedDate(time);
      let from = time.format('YYYY-MM-01');
      let to = time.endOf('month').format('YYYY-MM-DD');
      this.props.getPractices({ from, to, target: target, targetUser: this.props.targetUser, authUser: this.props.authUser });
    }

  }

  //コールバック関数
  finalizeLoadTarget(target, props) {
    if (props.compare) {
      props.updateCompare({ target: target });
      return;
    }
    else {
      props.updatePolar({ target: target });
    }
    props.updateDispData({ target: target, user: props.targetUser });
    props.loaded();
  }


  render() {
    const practice = this.props.practice;
    if (!practice)
      return ("No data.");

    if (this.props.param == 'max_vmg_up') {
      // console.log(practice.id, " = ", practice[this.props.param])
      // console.log(practice)
    }


    if (this.state.time) {
      const practicesOfDay = this.props.practices.filterByDay(this.state.time);
      this.props.setPracticesOfDay({ practicesOfDay: practicesOfDay });
    }



    let spd = practice[this.props.param]
    if (this.props.unit == 'knot')
      spd = (spd / 1.852).toFixed(2)

    return (
      <Card.Body key={practice.id} onClick={() => this.loadTarget(practice)}>
        {practice.started_at.format("YYYY-MM-DD HH:mm ")}
        練習{practice.id}<span>　</span>
        {spd}{this.props.unit}
      </Card.Body>
    );
  }
}

const mapStateToProps = state => {
  return {
    targetUser: state.PlayerSelector.user,
    practices: state.Main.practices,
    authUser: state.auth.user,
    practices: state.Main.practices,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    updateCompare: ({ target }) => dispatch(main.updateCompare({ target })),
    updatePolar: ({ target }) => dispatch(main.updatePolar({ target })),
    loading: () => dispatch(main.loading()),
    loaded: () => dispatch(main.loaded()),
    setCalendarDay: (calendarDay) => dispatch(main.setCalendarDay(calendarDay)),
    setSelectedDate: (date) => dispatch(main.setSelectedDate(date)),
    getPractices: ({ from, to, targetUser, authUser }) => dispatch(main.getPractices({ from, to, targetUser, authUser })),
    setPracticesOfDay: ({ practicesOfDay }) => dispatch(main.setPracticesOfDay({ practicesOfDay: practicesOfDay })),
    updateDispData: ({ target, members_practices, user }) => dispatch(main.updateDispData({ target, members_practices, user })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CardContent);
