const initialState = {
   members: null,
   practices: null,
   errors: {},
   isAuthenticated: false,
   isLoading: null,
   target: null,
};


export default function Main(state = initialState, action) {

   switch (action.type) {


      case 'GET_MEMBERS':
         return { ...state, members: action.members, msgType: '', message: '', };


      case 'GET_MEMBERS_PRACTICES':
         return { ...state, practices: action.practices, msgType: '', message: '' };


      case 'SET_MEMBERS_PRACTICES':
         return { ...state, practices: action.practices, members: action.members, msgType: '', message: '', };


      case 'SET_MEMBER_ACTIVE':
         return { ...state, members: action.members, msgType: '', message: '', };


      case 'GET_MEMBERS_ERROR':
         return { ...state, members: [], msgType: '', message: '', };

      case 'UPDATE_DISP_DATA':
         return {
            ...state, isLoading: false, upd_time: action.upd_time, practices: action.practices, target: action.target,
            gpsLog: action.gpsLog, motionLog: action.motionLog, windLog: action.windLog, buoyLog: action.buoyLog, msgType: '', message: '', max_up_distance: action.max_up_distance
         };

      default:
         return state;
   }
}
