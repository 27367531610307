import React, {Component} from "react";
import Moment from 'moment';
import { Badge } from 'react-bootstrap';
import 'bootstrap/scss/bootstrap.scss';
import 'font-awesome/css/font-awesome.min.css';
import './CustomizeField.css';


export const StatusField = ({ columnKey, row, onColumnClick }) => {

  let status = <span className="offline">OFFLINE</span>;
;
  if(row.lastAccess){
    status = <span className="online">ONLINE</span>;
  }

  return (
    <span>{status}</span>
  );
}

export const GNSSField = ({ columnKey, row, onColumnClick }) => {


  let fix;
  if(row.lastGPS){
    if (Moment(row.lastAccess).format("HH:mm:ss (Z)") == Moment(row.lastGPS).format("HH:mm:ss (Z)")){
      fix = (<Badge pill variant="success" className="fix">Fix</Badge>)
    }
  }
  let last = (
    <span className="last">
      <div>access:</div>
      <div>GNSS:</div>
    </span>
  );

  if(row.lastAccess){
    last = (
      <span className="last">
        <div>access:{Moment(row.lastAccess).format("HH:mm:ss (Z)")} </div>
        <div>GNSS:{Moment(row.lastGPS).format("HH:mm:ss (Z)")} {fix}</div>
      </span>
    );
  }


  return (
    last
  );
}

export const BatteryField = ({ columnKey, row, onColumnClick }) => {

  let batt = (
    <span className="last">
      <div>battery: -</div>
      <div>volt: -</div>
    </span>
  );

  if(row.percentage){
    batt = (
      <span className="battery">
        <div> battery:{row.percentage} % </div>
        <div> volt:{row.voltage} mV </div>
      </span>
    );
  }


  return (
    batt
  );
}

