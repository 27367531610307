import React, {Component} from "react";
import PagiList from "../list/PagiList";
import {Link, Redirect} from "react-router-dom";
import {connect} from "react-redux";
import { ListGroup, Table, Form } from 'react-bootstrap'
import { Card, CardHeader, CardBody } from 'reactstrap';
import API from '../API';

const columuns = [
  {dataField:'last_name',text:'姓'},
  {dataField:'first_name',text:'名'},
  {dataField:'email',text:'登録メールアドレス'},
]; 


class Agreements extends Component {

  constructor(props) {
    super(props);
    this.state = {
      agreements: null,
    }
  }

  componentDidMount() {
    //契約情報を取得
    let onSuccess = (response) => {
      console.log(response.data);
      this.setState({
        agreements: response.data
      });
    };

    let onError = (error) => {
      console.log(error);
      alert("サーバーとの通信エラー");
    };

    API.getAgreements({ token: this.props.loginUser.token, onSuccess: onSuccess, onError: onError });
  }



  render() {

    const {agreements} = this.state;

    if(!this.state.agreements){
      return <p>Loading...</p>;
    }

    return (
      <div className="container">
        {agreements.map((key, index) => (
          <Card>
            <CardHeader>
                <b> 契約情報 契約ID: {agreements[index].id} </b>
            </CardHeader>
            <CardBody>
                <Form.Label>契約者名</Form.Label> 
                <Form.Control id="fullname" type="text" value={ agreements[index].last_name +' '+ agreements[index].first_name } disabled/>
                <Form.Label>メールアドレス</Form.Label> 
                <Form.Control id="email" type="text" value={ agreements[index].email } disabled/>
                <Form.Label>郵便番号</Form.Label> 
                <Form.Control id="postal_code" type="text" value={ agreements[index].postal_code } disabled/>
                <Form.Label>住所</Form.Label> 
                <Form.Control id="address" type="text" value={ agreements[index].address } disabled/>
                <Form.Label>支払い情報</Form.Label> 
                <Form.Control id="payment" type="text" value={ agreements[index].payment_info } disabled/>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>サブスクリプション名</th>
                      <th>ご利用サービス名</th>
                      <th>サービス単価</th>
                      <th>サービス数量</th>
                      <th>合計金額</th>
                    </tr>
                  </thead>
                  <tbody>
                    {agreements[index].subscriptions.map((k, i) => (
                    <tr>
                      <td>{ agreements[index].subscriptions[i].name }</td>
                      <td>{ agreements[index].subscriptions[i].service_name }</td>
                      <td>{ agreements[index].subscriptions[i].service_price.toLocaleString()+'円' }</td>
                      <td>{ agreements[index].subscriptions[i].quantity }</td>
                      <td>{ agreements[index].subscriptions[i].total_amount }</td>
                    </tr>
                    ))}
                  </tbody>
                </Table>
            </CardBody>
          </Card>
        ))}
      </div>

    );
  }
}

const mapStateToProps = state => {
  return {
    loginUser: state.auth.user,
  };
}

const mapDispatchToProps = dispatch => {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Agreements);
