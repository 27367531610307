import { combineReducers } from 'redux';

import auth from "./auth";
import Main from "./Main";
import TeamList from "./TeamList";
import PlayerSelector from "./PlayerSelector";
import Device from "./Device";
import User from "./User";

const  StsApp = combineReducers({
   auth, Main, TeamList, PlayerSelector, Device, User
})

export default StsApp;
