/* eslint no-unused-expressions: "off" */
import Moment from 'moment';
import Legs from './Legs';
import axios from 'axios';
import {calc_distance, calc_velocity} from '../components/helpers';

/**
 * API `Practice/Practice Collection/List Practice` の返却結果（response）と
 * API `Practice/PRACTICE URL/PRACTICE URL` で返却される legs から
 * 取得するデータを格納する
 */
export default class Practice {
  constructor(
    id, started_at, ended_at, duration, separate_at,
    location, distance, url, excel_url, user, max_speed,
    max_vmg_up, max_vmg_down, has_rudder, has_wind,
    wind_direction, legs, calc_mode, calc_parm, coordinate,
    polar, max_vmg, unit, wind_unit, practice_times, wind_speed,
    stroke, ave_storke_per_minutes, pace100m_seconds, stroke100m,
    pace500m_seconds, stroke500m, pace1000m_seconds, stroke1000m, duration_seconds,
    setting,
  ) {

    this.id = id;
    this.started_at = started_at;
    this.ended_at = ended_at;
    this.separate_at = separate_at;
    this.location = location;
    this.distance = distance;
    this.url = url;
    this.excel_url = excel_url;
    this.user = user;
    this.max_speed = max_speed;
    this.max_2s_spd = 0.0;
    this.max_10s_spd = 0.0;
    this.max_vmg_up = max_vmg_up;
    this.max_vmg_down = max_vmg_down;
    this.ave_speed = 0.0;
    this.ave_vmg_up = 0.0;
    this.ave_vmg_down = 0.0;
    this.has_rudder = has_rudder;
    this.has_wind = has_wind;
    this.wind_direction = wind_direction;
    this.wind_speed = wind_speed;
    this.legs = legs;
    this.load = this.load.bind(this);

    this.gpsAllLine = [];
    this.gpsLine = [];
    this.gpsLog = [];
    this.motionLog = [];
    this.windLog = [];
    this.buoy_distance = [];
    this.max_up_distance = [];

    this.currentLeg = null;
    this.isLoaded = false;

    this.calc_mode = calc_mode;
    this.calc_parm = calc_parm;
    this.buoy_coordinate = coordinate;

    this.polar = polar;
    this.max_vmg = max_vmg;

    this.isMember = false;
    this.first_Lat = null;
    this.first_Lng = null;

    this.unit = unit;
    this.wind_unit = wind_unit;
    this.practice_times = practice_times;

    this.stroke = stroke;
    this.ave_storke_per_minutes = ave_storke_per_minutes;
    this.pace100m_seconds = pace100m_seconds;
    this.stroke100m = stroke100m;
    this.pace500m_seconds = pace500m_seconds;
    this.stroke500m = stroke500m;
    this.pace1000m_seconds = pace1000m_seconds;
    this.stroke1000m = stroke1000m;
    this.duration_seconds = duration_seconds;

    this.setting = setting;

    this.ave_pitch = 0.0;
    this.ave_roll = 0.0;

    this.st = "";
    this.et = "";
  }

  static fromJSON(practiceJSON, unit, wind_unit) {
    //ブイ位置がなかったら、nullにしておく。後ほど距離計算のところで、最初のGPS座標がブイ位置になる
    let buoy_coordinate = null;
    if (practiceJSON.buoy_coordinate)
      buoy_coordinate = JSON.parse("[" + practiceJSON.buoy_coordinate + "]");

    let practice = new Practice(
      practiceJSON.id,
      Moment(practiceJSON.started_at),
      Moment(practiceJSON.ended_at),
      practiceJSON.duration,
      practiceJSON.seprate_at,
      practiceJSON.location,
      practiceJSON.distance,
      practiceJSON.url,
      practiceJSON.excel_url,
      practiceJSON.user,

      practiceJSON.max_speed,
      practiceJSON.max_vmg_up,
      practiceJSON.max_vmg_down,

      practiceJSON.has_rudder,
      practiceJSON.has_wind,
      practiceJSON.wind_direction,
      null,
      practiceJSON.calc_mode,
      practiceJSON.calc_parm,
      buoy_coordinate,

      practiceJSON.polar,
      practiceJSON.max_vmg,

      unit,
      wind_unit,
      practiceJSON.practice_times,
      practiceJSON.wind_speed,

      practiceJSON.stroke,
      practiceJSON.ave_storke_per_minutes,
      practiceJSON.pace100m_seconds,
      practiceJSON.stroke100m,
      practiceJSON.pace500m_seconds,
      practiceJSON.stroke500m,
      practiceJSON.pace1000m_seconds,
      practiceJSON.stroke1000m,
      practiceJSON.duration_seconds,

      practiceJSON.setting,

    );

    // Set legs to practice
    let legs = Legs.fromJSON(practiceJSON.legs, practice);
    practice.legs = legs;
    return practice;
  }

  get uuid() {
    return `practice${this.id}`;
  }

  get uuids() {
    return [this.uuid];
  }

  treeTitle(index) {
    //return `練習${index} ${this.duration}`;
    return `練習${this.id} ${this.duration}`;
  }

  get duration() {
    return `${this.started_at.format('HH:mm')}-${this.ended_at.format('HH:mm')}`;
  }

  get gpses() {
    return this.legs.gpses;
  }

  get startedAt() {
    return this.legs.startedAt;
  }

  get endedAt() {
    return this.legs.endedAt;
  }

  get windDirection() {
    if (this.wind_direction) {
      return this.wind_direction;
    } else {
      return 0;
    }
  }

  get hasRudder() {
    return this.has_rudder;
  }

  get hasWind() {
    return this.has_wind;
  }

  get hasAllExternalData() {
    return this.hasRudder && this.hasWind;
  }


  //--- setter -------------------
  //区間選択で再計算される項目
  set_max_speed(v) {
    this.max_speed = v;
  }
  set_ave_speed(v) {
    this.ave_speed = v;
  }
  set_max_2s_speed(v) {
    this.max_2s_speed = v;
  }
  set_max_10s_speed(v) {
    this.max_10s_speed = v;
  }
  set_max_vmg_up(v) {
    this.max_vmg_up = v;
  }
  set_max_vmg_down(v) {
    this.max_vmg_down = v;
  }
  set_ave_vmg_up(v) {
    this.ave_vmg_up = v;
  }
  set_ave_vmg_down(v) {
    this.ave_vmg_down = v;
  }
  set_max_2s_spd(v) {
    this.max_2s_spd = v;
  }
  set_max_10s_spd(v) {
    this.max_10s_spd = v;
  }
  set_ave_roll(v) {
    this.ave_roll = v;
  }
  set_ave_pitch(v) {
    this.ave_pitch = v;
  }
  set_st(v) {//計測開始時刻
    this.st = v;
  }
  set_et(v) {//計測終了時刻
    this.et = v;
  }





  // Load gps data from URL
  load({ onSuccess, onError, props, fromLeg, leg, target }) {

    if (this.legs.isEmpty()) {
      return;
    }

    axios.get(this.url)
      .then((response) => {
        response.data.legs.forEach((legData, index) => {
          this.legs.get(index).loadGPS(legData.data);
        });

        if (onSuccess !== undefined) {

          this.currentLeg = null;
          this.isLoaded = true;

          //Legからの初回ロード指示
          if (fromLeg !== undefined) {
            onSuccess({ props: props, onSuccess: fromLeg, leg: leg });
            return;
          } else {
            if (target) {
              this.isMember = true;
              this.first_Lat = target.gpses[0].lat
              this.first_Lng = target.gpses[0].lon
              this.wind_direction = target.gpses[0].wind_direction
            }
            //convert unit
            if (this.polar) {
              this.convertPolarUnit();
              this.convertMaxVmgUnit();
            }

            this.createGraphData(this.legs.gpses);
            onSuccess(this, props);
          }

        }
      })
      .catch((error) => {
        if (onError !== undefined) {
          this.isLoaded = false;
          onError(error);
        } else {
          this.isLoaded = false;
          alert(error);
        }
      });
  }

  //Polarデータの値をunitに合わせて表示
  convertPolarUnit() {
    let polar = this.polar;
    for (let i in polar) {
      if (this.unit == 'knot') {
        polar[i] = parseFloat((polar[i] / 1.852).toFixed(2))
        this.unit = 'knot'
      }
    }
    this.polar = polar;
  }

  convertMaxVmgUnit() {
    let max_vmg = this.max_vmg;
    for (let k of Object.keys(max_vmg)) {
      if (this.unit == 'knot') {
        max_vmg[k].spd = parseFloat((max_vmg[k].spd / 1.852).toFixed(2))
        this.unit = 'knot'
      }
    }
    this.max_vmg = max_vmg;
  }



  //Graph Data
  createGpsAllLine(gpses) {
    let gpsAllLine = [];

    //選択されたPracticd or Legのデータ
    gpses.forEach(function (gps, index, array) {

      //Map表示用GPS配列
      gpsAllLine.push([gps.lat, gps.lon]);

    });

    this.gpsAllLine = gpsAllLine;

  }


  //Graph Data
  createGraphData(gpses) {

    let gpsLine = [];
    let gpsLog = [];
    let motionLog = [];
    let windLog = [];
    let max_up_distance = [];
    var buoy_distance = [];

    var max_spd = 0.0;
    var ave_spd = 0.0;
    var max_2s_spd = 0.0;
    var max_10s_spd = 0.0;
    var max_up_vmg = 0.0;
    var max_down_vmg = 0.0;
    var ave_up_vmg = 0.0;
    var ave_down_vmg = 0.0;

    var ave_pitch = 0.0;
    var ave_roll = 0.0;

    var count_up_vmg = 0;
    var count_down_vmg = 0;
    var count_2s = 0;
    var count_10s = 0;

    var m2s = [];
    var m10s = [];

    var first_Lat = this.first_Lat;
    var first_Lng = this.first_Lng;
    var first_dist = gpses[0].distance;
    var isMember = this.isMember;
    var unit = this.unit;
    var wind_unit = this.wind_unit;
    var wind_direction = this.wind_direction;
    //ブイ位置がなかったら、nullにしておく。後ほど距離計算のところで、最初のGPS座標がブイ位置になる
    if (!this.buoy_coordinate) {
      this.buoy_coordinate = [gpses[0].lon, gpses[0].lat];
    }

    var buoy_coordinate = this.buoy_coordinate;
    //選択されたPracticd or Legのデータ

    gpses.forEach(function (gps, index, array) {

      if (unit == "knot") {
        gps._speed = Math.round((gps._speed / 1.852) * 10) / 10
        gps._vmg = Math.round((gps._vmg / 1.852) * 10) / 10
      }

      if (wind_unit == "knot") {
        gps._wind_speed = Math.round((gps._wind_speed / 1.943) * 10) / 10
      }

      //統計算出
      if (max_spd < gps._speed)
        max_spd = gps._speed;
      ave_spd = ave_spd + gps._speed;
      if (max_up_vmg < gps._vmg)
        max_up_vmg = gps._vmg;
      if (max_down_vmg > gps._vmg)
        max_down_vmg = gps._vmg;
      if (gps._vmg > 0.0) {
        ave_up_vmg = ave_up_vmg + gps._vmg,
          count_up_vmg = count_up_vmg + 1;
      } else {
        ave_down_vmg = ave_down_vmg + gps._vmg,
          count_down_vmg = count_down_vmg + 1;
      }

      if (m2s.length < 2)
        m2s.push(gps._speed);
      else {
        let tmp_2s = 0.0;
        m2s.forEach((g) => {
          tmp_2s = tmp_2s + g;
        });
        tmp_2s = tmp_2s / 2;
        if (max_2s_spd < tmp_2s)
          max_2s_spd = tmp_2s
        m2s.shift();//古いものを捨てる
        m2s.push(gps._speed);//refleth
      }
      if (m10s.length < 10)
        m10s.push(gps._speed);
      else {
        let tmp_10s = 0.0;
        m10s.forEach((g) => {
          tmp_10s = tmp_10s + g;
        });
        tmp_10s = tmp_10s / 10;
        if (max_10s_spd < tmp_10s)
          max_10s_spd = tmp_10s
        m10s.shift();//古いものを捨てる
        m10s.push(gps._speed);//refleth
      }


      //Map表示用GPS配列
      gpsLine.push([gps.lat, gps.lon]);

      //let gpsTime = gps.time.format("HH:mm:ss");//20241205 ミリ秒表示
      let gpsTime = gps.time.toISOString().slice(11, 21) + gps.time.toISOString().slice(22, 23);


      //Port and Starboard
      let offset = gps.wind_direction - gps.direction;//  Wind - Head
      let isStb = true;
      if (offset > 180) {
        offset = offset - 360;
      } else if (offset < -180) {
        offset = offset + 360;
      }

      if (offset < 0) {
        isStb = false;
      } else {
        isStb = true;
      }

      let g;
      //GPS
      if (isStb) {
        g = {
          time: gpsTime,
          spd: gps._speed,
          vmg: gps._vmg,
          ang: Math.abs(offset),
          hed: gps.direction,
          dist: gps.distance - first_dist,

          spd_s: gps._speed,
          vmg_s: gps._vmg,
          ang_s: Math.abs(offset),
          hed_s: gps.direction,
          dist_s: gps.distance - first_dist,

          spd_p: null,
          vmg_p: null,
          ang_p: null,
          hed_p: null,
          dist_p: null,
        }
      } else {
        g = {
          time: gpsTime,
          spd: gps._speed,
          vmg: gps._vmg,
          ang: Math.abs(offset),
          hed: gps.direction,
          dist: gps.distance - first_dist,
          unit: gps.unit,
          wind_unit: gps.wind_unit,

          spd_s: null,
          vmg_s: null,
          ang_s: null,
          hed_s: null,
          dist_s: null,

          spd_p: gps._speed,
          vmg_p: gps._vmg,
          ang_p: Math.abs(offset),
          hed_p: gps.direction,
          dist_p: gps.distance - first_dist,
        }
      }
      gpsLog.push(g);


      //Motion
      let m;
      var pitch, roll;
      !gps.pitch ? pitch = 0 : pitch = gps.pitch
      !gps.roll ? roll = 0 : roll = gps.roll

			ave_pitch = ave_pitch + pitch
			ave_roll =  ave_roll  + roll

      if (isStb) {
        m = {
          time: gpsTime,
          pit: pitch,
          rol: roll,

          pit_s: pitch,
          rol_s: roll,

          pit_p: null,
          rol_p: null,
        };
      } else {
        m = {
          time: gpsTime,
          pit: pitch,
          rol: roll,

          pit_s: null,
          rol_s: null,

          pit_p: pitch,
          rol_p: roll,
        };
      }
      motionLog.push(m);


      //Wind
      let w;
      if (isStb) {
        w = {
          time: gpsTime,
          wds: gps.wind_speed,
          wdd: gps.wind_direction,

          wds_s: gps.wind_speed,
          wdd_s: gps.wind_direction,

          wds_p: null,
          wdd_p: null,
        };
      } else {
        w = {
          time: gpsTime,
          wds: gps.wind_speed,
          wdd: gps.wind_direction,

          wds_s: null,
          wdd_s: null,

          wds_p: gps.wind_speed,
          wdd_p: gps.wind_direction,
        };
      }
      windLog.push(w);

      //Max up distance
      let up;
      let mudp;
      let current_lat = gps.lat
      let current_lng = gps.lon
      let d, x, y, a, b, wdir, X, Y;

      if (!first_Lat) {
        if (!isMember) {
          first_Lat = current_lat;
          first_Lng = current_lng;
          wind_direction = gps.wind_direction;
        }

        if (isStb) {
          up = {
            time: gpsTime,
            mupd: 0,
            mupd_s: 0,
            mupd_p: null,
          };
        } else {
          up = {
            time: gpsTime,
            mupd: mudp,
            mupd_s: null,
            mupd_p: 0,
          };
        }
        max_up_distance.push(up);
      } else {
        wdir = wind_direction * Math.PI / 180.0; // Deg to Rad

        //原点周りの回転座標
        x = current_lng - first_Lng;
        y = current_lat - first_Lat;

        X = x * Math.cos(wdir) - y * Math.sin(wdir);
        Y = y * Math.cos(wdir) + x * Math.sin(wdir);

        d = calc_distance(0, 0, Math.abs(Y), 0);

        //符号の計算
        d = Math.abs(d) * (Y / Math.abs(Y));

        mudp = Math.round(d);

        if (isStb) {
          up = {
            time: gpsTime,
            mupd: mudp,
            mupd_s: mudp,
            mupd_p: null,
          };
        } else {
          up = {
            time: gpsTime,
            mupd: mudp,
            mupd_s: null,
            mupd_p: mudp,
          };
        }

        max_up_distance.push(up);
      }

      //マークブイとの距離を算出
      //ただし、ブイ設定があるときのみ
      if (buoy_coordinate) {
        let d;
        let dis = calc_distance(gps.lat, gps.lon, buoy_coordinate[1], buoy_coordinate[0]);
        let len = buoy_distance.length;
        let prev_dis = 0;
        let prev_time = 0;
        if (len > 0) {
          prev_dis = buoy_distance[buoy_distance.length - 1].dis;
          prev_time = buoy_distance[buoy_distance.length - 1].time; }

        if (isStb) {
          d = {
            time: gpsTime,
            dis: dis,
            vel: calc_velocity(dis, prev_dis, gpsTime, prev_time),

            dis_s: dis,
            vel_s: calc_velocity(dis, prev_dis, gpsTime, prev_time),

            dis_p: null,
            vel_p: null,
          };
        } else {
          d = {
            time: gpsTime,
            dis: dis,
            vel: calc_velocity(dis, prev_dis, gpsTime, prev_time),

            dis_s: null,
            vel_s: null,

            dis_p: dis,
            vel_p: calc_velocity(dis, prev_dis, gpsTime, prev_time),
          };
        }
        buoy_distance.push(d);
      }

    });//end gpses Loop

    this.gpsLine = this.reduceData(gpsLine);
    this.gpsLog = this.reduceData(gpsLog);
    this.motionLog = this.reduceData(motionLog);
    this.windLog = this.reduceData(windLog);
    this.max_up_distance = this.reduceData(max_up_distance);
    //マークブイとの距離
    this.buoy_distance = this.reduceData(buoy_distance);

    this.createGpsAllLine(this.gpses);

    //統計の格納
    this.max_speed = max_spd;
    let tmp = (ave_spd / gpses.length) * 10;
    this.ave_speed = Math.round(tmp) / 10;
    this.max_vmg_up = max_up_vmg;
    this.max_vmg_down = max_down_vmg;
    tmp = (ave_up_vmg / count_up_vmg) * 10;
    this.ave_vmg_up = Math.round(tmp) / 10;
    tmp = (ave_down_vmg / count_down_vmg) * 10;
    this.ave_vmg_down = Math.round(tmp) / 10;
    this.max_2s_spd = Math.round(max_2s_spd * 10) / 10;
    this.max_10s_spd = Math.round(max_10s_spd * 10) / 10;
    ave_pitch = (ave_pitch/gpses.length) * 10;
    this.ave_pitch = Math.round(ave_pitch) / 10;
    ave_roll = (ave_roll/gpses.length) * 10;
    this.ave_roll = Math.round(ave_roll) / 10;

  }

  //性能対策（表示点数の抑制）
  reduceData( ary ){
    let size = 60*60;
    let disp_ary = [];
    if (ary.length > size) {
      let d = parseInt(ary.length / size);
      for (let i = 0; i < ary.length; i += d) {
        disp_ary.push(ary[i]);
      }
    }
    else
      disp_ary = ary;
    return disp_ary;
  }




  //Graph Data
  mergeMemberData(practice) {
    //Member's gps merge
    let first = true;
    let first_dist = 0;
    practice.gpsLog.forEach((m) => {
      this.gpsLog.forEach((ori) => {
        if (ori.time == m.time) {
          if(first){
            first_dist = m.dist;
            first = false;
          }
          ori.m_spd = m.spd;
          ori.m_vmg = m.vmg;
          ori.m_ang = m.ang;
          ori.m_hed = m.hed;
          ori.m_dist = m.dist - first_dist;
        }
      });
    });
    //Member's motion merge
    practice.motionLog.forEach((m) => {
      this.motionLog.forEach((ori) => {
        if (ori.time == m.time) {
          ori.m_pitch = m.pitch;
          ori.m_roll = m.roll;
        }
      });
    });
    //Member's Distance merge
    practice.buoy_distance.forEach((m) => {
      this.buoy_distance.forEach((ori) => {
        if (ori.time == m.time) {
          ori.m_dis = m.dis;
          ori.m_vel = m.vel;
        }
      });
    });
    //Member's UpwindDistance merge
    practice.max_up_distance.forEach((m) => {
      this.max_up_distance.forEach((ori) => {
        if (ori.time == m.time) {
          ori.m_mupd = m.mupd;
        }
      });
    });


  }


  isEmpty() {
    return this.legs.isEmpty();
  }

  getSeries(dataType) {
    return this.legs.getSeries(dataType);
  }

  getMapCenter() {
    return this.legs.getMapCenter();
  }

  isBetween(from, to) {
    return Moment(this.started_at).isBetween(from, to);
  }

  setSmoothingRound(dataType) {
    this.legs.setSmoothingRound(dataType);
  }

  setSmoothingAverage(dataType, step) {
    this.legs.setSmoothingAverage(dataType, step);
  }

  setVisibility(dataType, min, max) {
    this.legs.forEach((leg) => leg.setVisibility(dataType, min, max));
  }

  setVisibilityTimeSeries(min, max) {
    this.legs.setVisibilityTimeSeries(min, max);
  }

  resetVisibility() {
    this.legs.resetVisibility();
  }
}
