import React, {Component} from "react";
import {connect} from "react-redux";
import { organization } from "../../actions";
import API from '../API';
import { Map, ScaleControl, Marker } from 'react-leaflet'
import { BingLayer } from 'react-leaflet-bing'
import {transform} from 'ol/proj';

const bing_key = "AlXkcsmK9XFWxo7VIH9QD1-aXpw4mGNILvxacbsvdfM-z8VvhNLBThCiEVbwdh3p"
class Organization extends Component {

  constructor(props) {
    super(props);

    let center = this.props.loginUser.organization.location.split(',')
    this.state = {
      zoom: 15,
      ori_center: center,
      center: center,
    }

    this.handleClickOnMap = this.handleClickOnMap.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }


  handleClickOnMap(e){
      var coordinate = e.latlng;
      this.setState({
        center: [coordinate.lat, coordinate.lng]
      });
  }
  

  handleSubmit(){
    this.setState({ loading: true });
    let center = this.state.center[0] + ',' + this.state.center[1];
    this.props.updateLocation(this.props.loginUser.organization.id, center, this.props.history);
  }



  componentDidMount() {
  }

  render() {
    return (
      <div>
        <p>{'拠点の位置を選択してください。'}</p>
        <hr className='settings-hr' />

        <div className='map-view'>
          <Map ref='map' center={this.state.ori_center} zoom={this.state.zoom} doubleClickZoom={true} attributionControl={false} style={{ height: '80vh' }} onclick={this.handleClickOnMap} >
            <BingLayer bingkey={bing_key} />
            <ScaleControl className="" position="bottomleft" imperial={false} />
              handleClickOnMap={this.handleClickOnMap}
            <Marker position={this.state.center} />
          </Map>
        </div>

        <input
          value={this.state.center[0]}
          readOnly={true}
        />
        <input
          value={this.state.center[1]}
          readOnly={true}
        />

        <button className='settings-regist-btn' onClick={this.handleSubmit} >更新</button>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loginUser: state.auth.user,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    updateLocation: (org_id, org_location, history) => dispatch(organization.updateLocation(org_id, org_location, history)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Organization);
