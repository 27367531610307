/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Moment from 'moment';
import { connect } from "react-redux";
import { main } from "../../../actions";

class CustomCalendar extends Component {

  static propTypes = {
    practices: PropTypes.object,
    practicesOfDay: PropTypes.object,
    carendarDay: PropTypes.object,
    handleChangeCarendar: PropTypes.func,
    handleChangeMonth: PropTypes.func,
    handleSelectDay: PropTypes.func
  };

  constructor(props) {
    super(props);
    var year = this.props.carendarDay.year();
    var month = this.props.carendarDay.month() + 1;

    this.handlePrevClick = this.handlePrevClick.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handleDateClick = this.handleDateClick.bind(this);

  }

  // 前月を取得
  getPrevMonth() {
    let prevMonth = Moment([this.props.carendarDay.year(), (this.props.carendarDay.month()), 1]);
    prevMonth.add(-1, 'months');
    this.props.handleChangeCarendar(prevMonth);
    return prevMonth;
  }

  // 翌月を取得
  getNextMonth() {
    let nextMonth = Moment([this.props.carendarDay.year(), (this.props.carendarDay.month()), 1]);
    nextMonth.add(1, 'months');
    this.props.handleChangeCarendar(nextMonth);

    return nextMonth;
  }

  // 前月ボタン押下時イベントハンドラ
  handlePrevClick() {
    let time = this.getPrevMonth();
    this.props.setCalendarDay(time)
    this.props.handleChangeMonth(time);
  }

  // 翌月ボタン押下時イベントハンドラ
  handleNextClick() {
    let time = this.getNextMonth();
    this.props.setCalendarDay(time)
    this.props.handleChangeMonth(time);
  }

  // Practice日選択時イベントハンドラ
  handleDateClick(e) {
    if (e.currentTarget.className === 'practice') {
      this.props.handleSelectDay(Moment.utc([this.props.carendarDay.year(), (this.props.carendarDay.month()), Number(e.currentTarget.textContent)]));
    }
  }

  // カレンダーテーブルヘッダ部JSXを生成
  createTableHeaderContents(weekdayData) {
    let contentsData = [];
    for (var i = 0; i < weekdayData.length; i++) {
      contentsData.push(<th key={Math.random()}>{weekdayData[i]}</th>);
    }
    return contentsData;
  }


  isPracticeDate(practices, date) {
    if (practices == null) {
      return false;
    } else {
      for (let i = 0; i < practices.dates.length; i++) {
        if (practices.dates[i].year() === this.props.carendarDay.year() &&
          practices.dates[i].month() + 1 === this.props.carendarDay.month() + 1 &&
          practices.dates[i].date() === date.day) {
          // console.log("!", date.day)
          return true;
        }

      }
      return false;
    }
  }

  setPracticeDate(selected_date, date) {
    if (selected_date == null)
      return false;

    if (Number(selected_date.split('-')[0]) === this.props.carendarDay.year() &&
      Number(selected_date.split('-')[1]) === this.props.carendarDay.month() + 1 &&
      Number(selected_date.split('-')[2]) === date.day) {
      return true;
    }
  }



  // カレンダーの各行のJSXを生成
  createCalendarLineGroup(calendarData) {

    let lineGroup = [];
    let line = [];
    for (var i = 0; i < calendarData.length; i++) {

      if (this.props.practicesOfDay && this.isPracticeDate(this.props.practicesOfDay, calendarData[i]) ||
        (this.setPracticeDate(this.props.selectedDate, calendarData[i]))) {
        // If this is selected day
        line.push(<td key={Math.random()} className={'selected'} onClick={this.handleDateClick}>{calendarData[i].day}</td>);
      } else {
        // If this is day in practice list
        let practiceClass = classNames({
          'practice': this.isPracticeDate(this.props.practices, calendarData[i])
        });
        line.push(<td key={Math.random()} className={practiceClass} onClick={this.handleDateClick}>{calendarData[i].day}</td>);
      }

      if (calendarData[i].weekday === 6) {
        lineGroup.push(line);
        line = [];
      }
    }

    return lineGroup;
  }

  // カレンダーテーブルボディ部JSXを生成
  createTableBodyContents(calendarData) {
    let lineGroup = this.createCalendarLineGroup(calendarData);

    let contentsData = [];
    for (var i = 0; i < lineGroup.length; i++) {
      contentsData.push(<tr key={Math.random()}>{lineGroup[i]}</tr>);
    }

    return contentsData;
  }

  /**
  * 指定した年月のカレンダー情報を返す
  * @param {number} year  - 年の指定
  * @param {number} month - 月の指定
  */
  getCalendarData(year, month) {
    var firstDate = new Date(year, (month - 1), 1); // 指定した年月の初日の情報
    var lastDay = new Date(year, (firstDate.getMonth() + 1), 0).getDate(); // 指定した年月の末日
    var weekday = firstDate.getDay(); // 指定した年月の初日の曜日

    var calendarData = []; // カレンダーの情報を格納
    var weekdayCount = weekday; // 曜日のカウント用
    for (var i = 0; i < lastDay; i++) {
      calendarData[i] = {
        day: i + 1,
        weekday: weekdayCount
      };
      // 曜日のカウントが6(土曜日)まできたら0(日曜日)に戻す
      if (weekdayCount >= 6) {
        weekdayCount = 0;
      } else {
        weekdayCount++;
      }
    }
    return calendarData;
  }

  shouldComponentUpdate(nextProps, nextState) {
    /* 練習日付リスト、選択年・月に変更が変更が認められない場合は再レンダリングを抑止する。 */
    // TODO 日付選択が実装されたら、選択日情報が変わったかどうかの判定を追加する必要があると想定する
    if (nextProps.practices === this.props.practices
      && nextProps.practicesOfDay === this.props.practicesOfDay
    ) {
      return false;
    } else {
      return true;
    }
  }


  render() {
    let year = this.props.carendarDay.year();
    let month = this.props.carendarDay.month() + 1;


    var weekdayData = ['日', '月', '火', '水', '木', '金', '土'];
    // カレンダーの情報を取得
    var calendarData = this.getCalendarData(year, month);

    var i = calendarData[0]['weekday']; // 初日の曜日を取得
    // カレンダー上の初日より前を埋める
    while (i > 0) {
      i--;
      calendarData.unshift({
        day: '',
        weekday: i
      });
    }

    i = calendarData[calendarData.length - 1]['weekday']; // 末日の曜日を取得
    // カレンダー上の末日より後を埋める
    while (i < 6) {
      i++;
      calendarData.push({
        day: '',
        weekday: i
      });
    }

    return (
      <div id="calendar">
        <div className="calendar-header">
          <div className="calendar-header__title">
            <span>{year + '年' + month + '月'}</span>
            <div className="button-area">
              <button className="calendar-header__prev"
                onClick={this.handlePrevClick}>
                &lt;
              </button>
              <button className="calendar-header__next"
                onClick={this.handleNextClick}>
                &gt;
              </button>
            </div>
          </div>
        </div>
        <table className="calendar-table">
          <thead>
            <tr>
              {this.createTableHeaderContents(weekdayData)}
            </tr>
          </thead>
          <tbody>
            {this.createTableBodyContents(calendarData)}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    carendarDay: state.Main.calendarDay,
    selectedDate: state.Main.selectedDate,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCalendarDay: (calendarDay) => dispatch(main.setCalendarDay(calendarDay)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomCalendar);
