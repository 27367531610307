import React from 'react';
import PropTypes from 'prop-types';
import API from './API';
import { connect } from "react-redux";
import { Col } from 'react-bootstrap';
import { Button, ButtonGroup } from 'react-bootstrap';
import { Form,FormGroup,FormControl} from 'react-bootstrap';

class Passchange extends React.Component {
  static propTypes = {
    user: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.state = {
      password: '',
      passwordConfirm: '',
      success: false,
      error: false,
    };

    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleChangePasswordConfirm = this.handleChangePasswordConfirm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.validatePasswordConfirm = this.validatePasswordConfirm.bind(this);
  }

  handleChangePassword(event) {
    this.setState({ password: event.target.value });
  }

  handleChangePasswordConfirm(event) {
    this.setState({ passwordConfirm: event.target.value });
  }

  validatePassword(){
    if (this.state.password.length < 8){
      return 'error';
    }else {
      return 'success';
    }
  }

  validatePasswordConfirm(){
    if (this.state.password === this.state.passwordConfirm){
      return 'success';
    }else{
      return 'error';
    }
  }

  handleSubmit() {
    if (this.validatePassword() === 'success' && this.validatePasswordConfirm() === 'success'){
      API.putUser({
        user: this.props.user,
        password: this.state.password,
        onSuccess: () => {
          this.setState({ success: true });
        },
        onError: (errorMessage) => {
          console.error(errorMessage);
          this.setState({ error: true });
        },
      });
    }else {
      alert('入力内容を確認してください。');
    }
  }

  render() {
    if (this.state.success){
      return <div>パスワードの変更が正常に完了しました。</div>;
    }
    if (this.state.error){
      return (
        <div>
          パスワードの更新に失敗しました。
        </div>
      );
    }

    return (
      <div className="container">
        <Form>
          <h2>アカウント情報</h2>

          <FormGroup>
            <label>組織情報</label>
            <FormControl
              type="text"
              defaultValue={this.props.user.organization.name}
              disabled={true}
            />
          </FormGroup>
          <FormGroup>
           <label>姓</label>
            <FormControl
              type="text"
              defaultValue={this.props.user.last_name}
              disabled={true}
            />
            <label>名</label>
            <FormControl
              type="text"
              defaultValue={this.props.user.first_name}
              disabled={true}
            />
          </FormGroup>

          <FormGroup
            controlId="password"
            validationState={this.validatePassword()}
          >
            <label>パスワード</label>
            <FormControl
              type="password"
              value={this.state.password}
              onChange={this.handleChangePassword}
              autoComplete="nope"
            />
            <label>パスワードは8文字以上である必要があります。</label>
          </FormGroup>

          <FormGroup
            controlId="passwordConfirm"
            validationState={this.validatePasswordConfirm()}
          >
            <label>確認用パスワード</label>
            <FormControl
              type="password"
              value={this.state.passwordConfirm}
              onChange={this.handleChangePasswordConfirm}
            />
          </FormGroup>

          <FormGroup>
            <ButtonGroup>
              <Button variant="primary" onClick={this.handleSubmit}>変更を保存する</Button>
            </ButtonGroup>
          </FormGroup>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Passchange);
