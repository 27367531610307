import React, { Component } from 'react';
import { teamList } from "../../../actions";
import { connect } from "react-redux";
import { ListGroup } from 'react-bootstrap'

class TeamList extends Component {


  componentDidMount() {

    //APIを発行してチームメンバーリストを取得する
    if (this.props.target) {
      this.props.getMembers();
    }
    else
      this.props.getMembers();

  }


  onClickMember = (member, e) => {
    this.props.getMembersPractices({ target: this.props.target, from: this.props.target.started_at, to: this.props.target.ended_at, member: member, user: this.props.targetUser });

    //changeActive
    this.props.setActive(this.props.members, member);
  }


  Members = (members) => {

    if (members != undefined) {
      let tmp = '';
      members.forEach((member) => {
        tmp = tmp + <li>{member.email}</li>;
      });
      return tmp;
    } else
      return;
  }


  render() {

    var members_view = [];
    if (this.props.members != undefined) {
      this.props.members.forEach((member, index) => {

        if (this.props.target == undefined)
          members_view.push(<ListGroup.Item key={index} action disabled
            onClick={this.onClickMember.bind(this, member)}>
            {member.last_name} {member.first_name}</ListGroup.Item>);
        else {
          if (member.isActive)
            members_view.push(<ListGroup.Item key={index} action active
              onClick={this.onClickMember.bind(this, member)}>
              {member.last_name} {member.first_name}</ListGroup.Item>);
          else
            members_view.push(<ListGroup.Item key={index} action
              onClick={this.onClickMember.bind(this, member)}>
              {member.last_name} {member.first_name}</ListGroup.Item>);
        }
      })
    }

    return (
      <div>
        <label>Select member</label>
        <ListGroup>
          {members_view}
        </ListGroup>
      </div>
    );//return
  }

}


const mapStateToProps = state => {
  return {
    members: state.TeamList.members,
    targetUser: state.PlayerSelector.user,
  }
}


const mapDispatchToProps = dispatch => {
  return {
    getMembers: () => dispatch(teamList.getMembers()),
    getMembersPractices: ({ target, from, to, member, user }) => dispatch(teamList.getMembersPractices({ target, from, to, member, user })),
    setActive: (members, member) => dispatch(teamList.setActive(members, member)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamList);
