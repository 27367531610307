import React, { Component } from 'react'
import { RadarChart, PolarGrid, PolarAngleAxis, Radar, Tooltip } from 'recharts';
import { Container, Row, Col, Button, ButtonGroup, ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

export default class Motion extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }

 

  render() {
    if (!this.props.angle)
      return <p>No motion data.</p>;

    const angle = this.props.value;
    let polar = [];
    for (let i = 0; i < 360; i++) {
      let val = 0;
      let ang = angle;
      if( angle < 0 )
        ang = 360 + angle;
      if( i == parseInt(ang) )
        val = 1;
      let data = {
        angle: i,
        value: val,
      };
      polar.push(data);
    }


    return (
        <Container>
          <Row>
            {this.props.title}{"　"} {this.props.angle}
          </Row>
          <Row>
            <Col xs={7}>
              <RadarChart // レーダーチャートのサイズや位置、データを指定
                height={150} //レーダーチャートの全体の高さを指定
                width={150} //レーダーチャートの全体の幅を指定
                cx="40%" //要素の左を基準に全体の50%移動
                cy="40%" //要素の上を基準に全体の50%移動
                data={polar} //ここにArray型のデータを指定
              >
                <PolarGrid /> // レーダーのグリッド線を表示
                <Radar //レーダーの色や各パラメーターのタイトルを指定
                  name={this.props.title}  //hoverした時に表示される名前を指定
                  dataKey="value" //Array型のデータのパラメータータイトルを指定
                  stroke="#ff5c33"  //レーダーの線の色を指定
                  fill="#777777" //レーダーの中身の色を指定
                  fillOpacity={0.8} //レーダーの中身の色の薄さを指定
                />
                <Tooltip /> //hoverすると各パラメーターの値が表示される
              </RadarChart>
            </Col>
         </Row>
        </Container>
    );
  }
}
