import React, {Component} from "react";
import {connect} from "react-redux";

import {Link, Redirect} from "react-router-dom";
import {auth, main} from "../actions";

class Practices extends Component {

  constructor(props) {
    super(props);

    this.state = {
      device_id:     '9999',
      url:           'http://',
    };

   //Radio イベント用メソッド
    this.handleChangeDeviceId = this.handleChangeDeviceId.bind(this);
    this.handleChangeUrl = this.handleChangeUrl.bind(this);
  }

  handleChangeDeviceId(event) {
    this.setState( {device_id: event.target.value} );
  }
  handleChangeUrl(event) {
    this.setState( {url: event.target.value} );
  }


  onSubmit = e => {
    e.preventDefault();
    this.props.test(this.state.device_id, this.state.url);
  }


  render() {
   return (
    <div>
      隠し画面 <br />

      <span className='settings-lbl'>
        <span className='settings-lbl-txt'>デバイスID</span>
      </span>
      <input type='number' id='number'
        onChange={this.handleChangeDeviceId}
        defaultValue={this.state.device_id}
        min={0}
        max={9999}
      />

      <span className='settings-lbl'>
        <span className='settings-lbl-txt'>Data URL</span>
      </span>
      <input 
        onChange={this.handleChangeUrl}
        defaultValue={this.state.url}
      />

      <form onSubmit={this.onSubmit}>
          <p>
            <button type="submit">Hook</button>
          </p>
      </form>

    </div>

    )
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated
  };
}

const mapDispatchToProps = dispatch => {
  return {
    test: (device_id, url) => dispatch(main.test(device_id, url)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Practices);
