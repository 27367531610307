import axios from 'axios';
import { HOST, ENV } from '../Config';
import Moment from 'moment';

console.log("RunMODE:", ENV);
console.log(HOST);

const VERSION = 'v1';
const ENDPOINT = `${HOST}/${VERSION}`;
const TKN_HEAD= 'Bearer';//'token';

export default class API {

  //Get loginned User Information
  static auth(token, onSuccess, onError) {
    let tkn = '';
    if (token) {
      tkn = token
    }

    axios.get(`${ENDPOINT}/auth`, { headers: { Authorization: `${TKN_HEAD} ${tkn}`, } })
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }

  //Login
  static login(email, password, onSuccess, onError) {

    axios.post(`${ENDPOINT}/login`, { email: email, password: password })
      .then((response) => {
        console.log("login success");
        onSuccess(response);
      })
      .catch((error) => {
        console.log("login error");
        onError(error.response);
      });
  }

  //Logout
  static logout(token, onSuccess, onError) {
    let tkn = '';
    if (token) {
      tkn = token
    }
    axios.get(`${ENDPOINT}/logout`, { headers: { Authorization: `${TKN_HEAD} ${tkn}`, } })
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }


  //TEST for HOOK API
  static test({ token, url, device_id, onSuccess, onError }) {

    console.log(device_id);
    console.log(url);
    let tkn = '';
    //let ENDPOINT2 = "https://api-server-for-training.herokuapp.com/v1";
    //let url = 'https://device1production.blob.core.windows.net/production-rawdata/4646_2019-12-28T03-37-42.csv';
    if (token) {
      tkn = token
    }
    axios.post(`${ENDPOINT}/hook`,
      {
        url: url,
        device_id: device_id, type: 'flush'
      },
      { headers: { Authorization: `${TKN_HEAD} ${tkn}` } }
    )
      .then((response) => {
        console.log("flush success");
        onSuccess(response);
      })
      .catch((error) => {
        console.log("flush error");
        onError(error.response);
      });
  }


  //User所有のPractice一覧を取得
  static getPractices({ token, from, to, onSuccess, onError, targetUser, authUser }) {
    let tkn = '';
    if (token) {
      tkn = token
    }
    let listPracticesEndpoint = `${ENDPOINT}/practices?limit=100`;
    if (from !== undefined && to !== undefined) {

      //Timezone convert localtime => UTC
      let n = new Date().getTimezoneOffset();

      //GMT+ならOffsetがマイナスなら日付を1日前にしてあげる
      if (n < 0) {
        const oneDay = Moment.duration(1, 'days');
        from = Moment(from).subtract(oneDay).format('YYYY-MM-DD');
      }

      listPracticesEndpoint = listPracticesEndpoint + `&started_at[from]=${from}&started_at[to]=${to}`;
    }
    axios.get(listPracticesEndpoint, { headers: { Authorization: `${TKN_HEAD} ${tkn}` }, params: { target: targetUser.id } })
      .then((response) => {
        onSuccess(response, authUser);
      })
      .catch((error) => {
        onError(error);
      });
  }


  static putPractice({ user, practiceId, windDirection, windSpeed, windSpeedMin, calcMode, calcParm, buoy_coordinate, onSuccess, onError, practice_times, event_id, setting }) {
    let tkn = '';
    if (user) {
      tkn = user.token;
    }
    axios.put(`${ENDPOINT}/practices/${practiceId}`, {
      wind_direction: windDirection, calc_mode: calcMode,
      calc_parm: calcParm, buoy_coordinate: String(buoy_coordinate),
      practice_times: practice_times, wind_speed: windSpeed, wind_speed_min: windSpeedMin, event_id: event_id, setting: setting,
    },
      { headers: { Authorization: `${TKN_HEAD} ${tkn}`, } })
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }


  static recalculatePractice({ user, practiceId, onSuccess, onError }) {
    let tkn = '';
    if (user) {
      tkn = user.token;
    }
    axios.post(`${ENDPOINT}/practices/${practiceId}/recalculate`, {}, { headers: { Authorization: `${TKN_HEAD} ${tkn}` } })
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }

  static deletePractice({ user, practiceId, onSuccess, onError }) {
    let tkn = '';
    if (user) {
      tkn = user.token;
    }
    axios.delete(`${ENDPOINT}/practices/${practiceId}` ,{ headers: { Authorization: `${TKN_HEAD} ${tkn}` } })
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }


  static postExternalFileToPractice({ user, practiceId, files, onSuccess, onError }) {
    let tkn = '';
    if (user) {
      tkn = user.token;
    }
    const headers = { headers: { 'content-type': 'multipart/form-data', 'Authorization': `${TKN_HEAD} ${tkn}` } };
    axios.post(`${ENDPOINT}/practices/${practiceId}/externaldata`, files, headers)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }


  static getMembers({ token, onSuccess, onError, from, to }) {
    let tkn = '';
    if (token) {
      tkn = token;
    }

    let url = `${ENDPOINT}/users`;

    axios.get(url, { headers: { 'Authorization': `${TKN_HEAD} ${tkn}` }, data: {} })
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }


  static getMembersPractices({ token, onSuccess, onError, from, to, target }) {
    let tkn = '';
    if (token) {
      tkn = token;
    }

    let url = `${ENDPOINT}/practices/members`;
    if ((from !== undefined && to !== undefined) && (from !== null && to !== null)) {

      from = Moment(from).utc().format('YYYY-MM-DD HH:mm:ss');
      to = Moment(to).utc().format('YYYY-MM-DD HH:mm:ss');

      url = url + `?started_at[from]=${from}&ended_at[to]=${to}`;
    }

    axios.get(url, { headers: { 'Authorization': `${TKN_HEAD} ${tkn}` }, params: { user_id: target.user.id } })
      .then((response) => {
        onSuccess(response, target);
      })
      .catch((error) => {
        onError(error);
      });
  }


  static getUser({ token, user_id, onSuccess, onError }) {
    axios.get(`${ENDPOINT}/users/${user_id}`, { headers: { Authorization: `${TKN_HEAD} ${token}`, } })
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }


  static putUser({ token, user, notification_id, password, onSuccess, onError, history }) {
    let tkn = '';
    var payload = {};
    if (!token) {//パスワード変更から遷移の場合
      tkn = user.token;
    } else
      tkn = token;
    if (notification_id) {
      payload['notification_id'] = notification_id;
    }
    if (password) {
      payload['password'] = password;
    }

    if (user) {//主にユーザ一覧の編集から遷移の場合
      payload['email'] = user.email;
      payload['first_name'] = user.first_name;
      payload['last_name'] = user.last_name;
    }
    axios.put(`${ENDPOINT}/users/${user.id}`, payload, { headers: { authorization: `${TKN_HEAD} ${tkn}`, } })
      .then((response) => {
        onSuccess({ res: response, history: history });
      })
      .catch((error) => {
        onError(error);
      });
  }


  static addUser({ token, new_user, password, onSuccess, onError, history }) {
    var payload = {};

    if (new_user) {
      payload = new_user;
    }
    axios.post(`${ENDPOINT}/users`, payload, { headers: { authorization: `${TKN_HEAD} ${token}`, } })
      .then((response) => {
        onSuccess({ res: response, history: history });
      })
      .catch((error) => {
        onError(error);
      });
  }


  static deleteUser({ token, user_id, onSuccess, onError, history }) {
    axios.delete(`${ENDPOINT}/users/${user_id}`, { headers: { authorization: `${TKN_HEAD} ${token}`, } })
      .then((response) => {
        onSuccess({ res: response, history: history });
      })
      .catch((error) => {
        onError(error);
      });
  }



  static getDevices({ token, onSuccess, onError }) {
    let tkn = '';
    if (token) {
      tkn = token;
    }
    let url = `${ENDPOINT}/devices`;
    axios.get(url, { headers: { 'Authorization': `${TKN_HEAD} ${tkn}` }, data: {} })
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }


  //Practice分割パターンを取得
  static getCalcPattern({ token, onSuccess, onError }) {
    let tkn = '';
    if (token) {
      tkn = token;
    }
    let url = `${ENDPOINT}/calcpatterns`;
    axios.get(url, { headers: { 'Authorization': `${TKN_HEAD} ${tkn}` }, data: {} })
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }


  //Eventsパターンを取得
  static getEvents({ token, onSuccess, onError }) {
    let tkn = '';
    if (token) {
      tkn = token;
    }
    let url = `${ENDPOINT}/events`;
    axios.get(url, { headers: { 'Authorization': `${TKN_HEAD} ${tkn}` }, data: {} })
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }


  static putDevice({ device, token, onSuccess, onError, history }) {
    var payload = {};
    //payload['device_id'] = notification_id;
    axios.put(`${ENDPOINT}/devices/${device.id}`, device, { headers: { Authorization: `${TKN_HEAD} ${token}`, } })
      .then((response) => {
        onSuccess(response, history);
      })
      .catch((error) => {
        onError(error);
      });
  }


  static getDevicesInfo({ token, onSuccess, onError, devices }) {
    let tkn = '';
    if (token) {
      tkn = token;
    }
    let url = `${ENDPOINT}/devices_info`;
    axios.get(url, { headers: { 'Authorization': `${TKN_HEAD} ${tkn}` }, data: {} })
      .then((response) => {
        onSuccess(response, devices);
      })
      .catch((error) => {
        onError(error);
      });
  }

  // 契約一覧
  static getAgreements({ token, onSuccess, onError }) {
    let url = `${ENDPOINT}/agreements`;
    axios.get(url, { headers: { 'Authorization': `${TKN_HEAD} ${token}` }, data: {} })
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }


  //User所有のPracticeランキング一覧を取得
  static getRankingPractices({ token, from, to, onSuccess, onError, targetUser, windRange }) {
    let tkn = '';
    if (token) {
      tkn = token
    }
    let listPracticesEndpoint = `${ENDPOINT}/ranking_practices?limit=10`;
    if (from !== undefined && to !== undefined) {

      //Timezone convert localtime => UTC
      let n = new Date().getTimezoneOffset();

      //GMT+ならOffsetがマイナスなら日付を1日前にしてあげる
      if (n < 0) {
        const oneDay = Moment.duration(1, 'days');
        from = Moment(from).subtract(oneDay).format('YYYY-MM-DD');
      }

      listPracticesEndpoint = listPracticesEndpoint + `&started_at[from]=${from}&started_at[to]=${to}`;
    }
    axios.get(listPracticesEndpoint, {
      headers: { Authorization: `${TKN_HEAD} ${tkn}` },
      params: { target: targetUser.id, wind_range: windRange }
    })
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }


  static putOrganization({ token, org_id, org_location, onSuccess, onError, history }) {
    var payload = {};
    if (org_location) {
      payload['location'] = org_location;
      payload['organization_id'] = org_id;
    }
    axios.put(`${ENDPOINT}/organizations/${org_id}/update_location`, payload, { headers: { authorization: `${TKN_HEAD} ${token}`, } })
      .then((response) => {
        onSuccess(response, history);
      })
      .catch((error) => {
        onError(error);
      });
  }


  //セッティングをロード 
  static loadLastSetting({ token, onSuccess, onError, cbfunc, state }) {
    let url = `${ENDPOINT}/setting`;
    axios.get(url, { headers: { 'Authorization': `${TKN_HEAD} ${token}` }, data: {} })
      .then((response) => {
        onSuccess(response, cbfunc, state);
      })
      .catch((error) => {
        onError(error);
      });
  }



  //ここから上が修正済みAPI
  //---------------------------------------------------------------------------------------------------------------
  //ここから下が未修正API


  static listExports({ user, onSuccess, onError }) {
    const listExportsEndpoint = `${ENDPOINT}/organizations/${user.organization.id}/export`;
    axios.get(listExportsEndpoint, { headers: { 'Authorization': user.token }, data: {} })
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }

  static postExport({ user, filename, x, y, timeRanges, onSuccess, onError }) {
    const postExportsEndpoint = `${ENDPOINT}/organizations/${user.organization.id}/export`;
    var requestBody = {
      filename: filename,
      x: x,
      y: y,
      time_ranges: timeRanges,
    };
    axios.post(postExportsEndpoint, requestBody, { headers: { 'Authorization': user.token } })
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }

  static listNotifications({ user, onSuccess, onError }) {
    axios.get(`${ENDPOINT}/organizations/${user.organization.id}/users/${user.id}/notifications`, { headers: { 'Authorization': user.token }, data: {} })
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }

}
