import React, {Component} from "react";
import {connect} from "react-redux";
import { main } from "../../actions";
import {Accordion, Card, Button} from "react-bootstrap";
import Slider from "../slider/Slider";

class SearchForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
       selectionRange: {
          min: 0, 
          max: 30 },
       dataRange : {
          min: 0,
          max: 30,
          step: 0.1 },
    }
    this.setSlider = this.setSlider.bind(this)
    this.submit = this.submit.bind(this)
  }

  componentDidMount() {
  }

  submit(){
    this.props.getRankingPractices({ from: null, to: null, targetUser: this.props.authUser, windRange: this.state.selectionRange, authUser: this.props.authUser });
  }



  setSlider(points){
    this.setState(state => ({
      selectionRange: {min: points[0], 
                       max: points[1]},
    }));
  }




  render() {

    const {selectionRange} = this.state;

    return (
      <div>
        <p>WIND Range
          <span>　[{selectionRange.min}〜{selectionRange.max}]</span> m/s
        </p>
        <Slider 
          dataRange={this.state.dataRange} 
          selectionRange={this.state.selectionRange} 
          handleSelectDataRange={this.handleSelectDataRange}
          //customTipFormatter={elapsedTimeToTimestamp}
          setSlider={this.setSlider}
        />
        <br />
        <Button id="submit" type="submit"  onClick={event => this.submit(event)} >
        Search  
        </Button>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    targetUser: state.PlayerSelector.user,
    authUser: state.auth.user,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getRankingPractices: ({ from, to, targetUser, authUser,  windRange }) => dispatch(main.getRankingPractices({ from, to, targetUser, authUser, windRange })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchForm);
